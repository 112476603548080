var CountryData = /** @class */ (function () {
    function CountryData(name, englishName, languageDataList, countryCode) {
        this._name = name;
        this._englishName = englishName;
        this._languages = languageDataList;
        this._countryCode = countryCode;
    }
    Object.defineProperty(CountryData.prototype, "languages", {
        get: function () {
            return this._languages;
        },
        set: function (value) {
            this._languages = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CountryData.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (value) {
            this._name = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CountryData.prototype, "englishName", {
        get: function () {
            return this._englishName;
        },
        set: function (value) {
            this._englishName = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CountryData.prototype, "countryCode", {
        get: function () {
            return this._countryCode;
        },
        set: function (value) {
            this._countryCode = value;
        },
        enumerable: false,
        configurable: true
    });
    return CountryData;
}());
export { CountryData };
