import { FilterValue } from "./filter-value";
var FilterGroup = /** @class */ (function () {
    function FilterGroup(filterGroup) {
        this.$filterGroup = filterGroup;
        this.code = filterGroup.getAttribute('data-filter-code');
        this.filterValues = this.createFacetValues();
    }
    FilterGroup.prototype.createFacetValues = function () {
        var facetValues = new Array();
        var filterValueArray = Array.prototype.slice.call(this.$filterGroup.querySelectorAll('.m-filter__container__option, .m-filter__container__option--withImage'));
        filterValueArray.forEach(function (value) {
            facetValues.push(new FilterValue(value));
        });
        return facetValues;
    };
    FilterGroup.prototype.getCurrentQuery = function () {
        var _this = this;
        var query = "";
        this.filterValues.forEach(function (value) {
            if (value.isSelected()) {
                query += ":" + _this.code + ":" + value.getValue();
            }
        });
        return query;
    };
    FilterGroup.prototype.updateGroup = function (facets) {
        var _this = this;
        var facet = this.extractCurrentFacet(facets);
        if (facet !== undefined) {
            this.filterValues.forEach(function (filterValue) {
                var value = _this.extractCurrentValue(facet, filterValue);
                if (value !== undefined && value.count > 0) {
                    filterValue.enable();
                }
                else {
                    filterValue.disable();
                }
            });
        }
        else {
            this.filterValues.forEach(function (value) {
                value.disable();
            });
        }
    };
    FilterGroup.prototype.extractCurrentValue = function (facet, filterValue) {
        var value;
        facet.values.forEach(function (_value) {
            if (filterValue.getValue() === _value.code) {
                value = _value;
            }
        });
        return value;
    };
    FilterGroup.prototype.extractCurrentFacet = function (facets) {
        var _this = this;
        var facet;
        facets.forEach(function (_facet) {
            if (_this.code === _facet.code) {
                facet = _facet;
            }
        });
        return facet;
    };
    FilterGroup.prototype.getFilterValues = function () {
        return this.filterValues;
    };
    FilterGroup.prototype.getCode = function () {
        return this.code;
    };
    return FilterGroup;
}());
export { FilterGroup };
