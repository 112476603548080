var ServiceRequests = /** @class */ (function () {
    function ServiceRequests() {
        this.CONTACT_US_MODAL_ID = 'service-request-contact-us-modal';
        this.$page = document.querySelector('.m-serviceRequests');
        this.$contactUsModalBtnArr = this.$page.querySelectorAll("[target-modal=\"".concat(this.CONTACT_US_MODAL_ID, "\"]"));
        this.$contactUsModal = this.$page.querySelector("#".concat(this.CONTACT_US_MODAL_ID));
        this.$caseNumberInput = this.$contactUsModal.querySelector('input[name="caseNumber"]');
        this.init();
    }
    ServiceRequests.prototype.setContactUsModalData = function (event) {
        var $btn = event.target;
        this.$caseNumberInput.value = $btn.getAttribute('data-case-number');
    };
    ServiceRequests.prototype.init = function () {
        var _this = this;
        this.$contactUsModalBtnArr.forEach(function (btn) { return btn.addEventListener('click', function (event) { return _this.setContactUsModalData(event); }); });
    };
    return ServiceRequests;
}());
export { ServiceRequests };
