import axios from 'axios';
var AxiosSettings = /** @class */ (function () {
    function AxiosSettings() {
        this.init();
    }
    AxiosSettings.prototype.init = function () {
        if (document.body.classList.contains('internet-explorer')) {
            axios.defaults.headers.common['Cache-Control'] = 'no-cache';
            axios.defaults.headers.common['Pragma'] = 'no-cache';
            axios.defaults.headers.common['Expires'] = '0';
        }
    };
    return AxiosSettings;
}());
export { AxiosSettings };
