import { AlertFactory } from '../notification/alert-factory';
import alertObject from '../notification/global-alerts';
var PreferredDealersPage = /** @class */ (function () {
    function PreferredDealersPage() {
        this.preferredDealersMaxNumber = 4;
        this.$preferredDealersPage = document.querySelector('.m-preferredDealers');
        this.successInfo = this.$preferredDealersPage.getAttribute('data-success');
        this.errorInfo = this.$preferredDealersPage.getAttribute('data-error');
        this.warningInfo = this.$preferredDealersPage.getAttribute('data-warning');
        this.$dealerArr = this.$preferredDealersPage.querySelectorAll('.m-preferredDealers__list__item');
        this.init();
    }
    PreferredDealersPage.prototype.checkDealersNumber = function () {
        var preferredDealersNumber = this.$preferredDealersPage.querySelectorAll('.a-checkbox__input:checked').length;
        var $notPreferredDealerArr = this.$preferredDealersPage.querySelectorAll('.a-checkbox__input:not(:checked)');
        if (preferredDealersNumber >= this.preferredDealersMaxNumber) {
            if (!alertObject.isAlertVisible()) {
                var alertFactoryObject = new AlertFactory();
                var element = alertFactoryObject.createAlert(this.warningInfo, window.ACC.status.warning, 'warning', { 'animated': true, 'closable': true });
                alertObject.showAlert(element);
            }
            $notPreferredDealerArr.forEach(function (item) { return item.setAttribute('disabled', 'disabled'); });
        }
        else {
            $notPreferredDealerArr.forEach(function (item) { return item.removeAttribute('disabled'); });
        }
    };
    PreferredDealersPage.prototype.init = function () {
        var _this = this;
        this.checkDealersNumber();
        this.$dealerArr.forEach(function (item) {
            item.addEventListener('change', function () { return _this.checkDealersNumber(); });
        });
    };
    return PreferredDealersPage;
}());
export { PreferredDealersPage };
