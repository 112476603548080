import * as app from "../../main";
var Devices = /** @class */ (function () {
    function Devices() {
        this.$page = document.querySelector('.m-machines');
        this.$modalBtnArr = this.$page.querySelectorAll('[data-toggle="modal"]');
        this.init();
    }
    Devices.prototype.setModalData = function (ev) {
        var $btn = ev.target;
        var modalId = $btn.getAttribute('target-modal');
        var $modal = this.$page.querySelector('#' + modalId);
        var $deviceTypeInput = $modal.querySelector('input[name="deviceType"]');
        var $machineItem = app.default.getSnippets().closest($btn, '.m-machineItem');
        var serialNumber = $machineItem.getAttribute('data-serial-number');
        var deviceType = $machineItem.getAttribute('data-device-type');
        var fieldName;
        if ($modal.getAttribute('id') === 'service-request-modal') {
            $deviceTypeInput.value = deviceType;
            fieldName = 'serialNumber';
        }
        else {
            fieldName = 'machineSerial';
        }
        var $serialNumberInput = $modal.querySelector('input[name="' + fieldName + '"]');
        $serialNumberInput.value = serialNumber;
    };
    Devices.prototype.init = function () {
        var _this = this;
        this.$modalBtnArr.forEach(function (btn) { return btn.addEventListener('click', function (event) { return _this.setModalData(event); }); });
    };
    return Devices;
}());
export { Devices };
