import axios from 'axios';
var CartItemsIndicator = /** @class */ (function () {
    function CartItemsIndicator() {
        this.productInCartQuantityClass = 'm-mainMenu__actions__basket__link__quantity--productInCart';
        this.productInCartQuantityOneDigitClass = 'm-mainMenu__actions__basket__link__quantity--oneDigit';
        this.productInCartQuantityTwoDigitClass = 'm-mainMenu__actions__basket__link__quantity--twoDigit';
        this.productInCartQuantityThreeDigitClass = 'm-mainMenu__actions__basket__link__quantity--threeDigit';
        this.productInCartQuantityFourDigitClass = 'm-mainMenu__actions__basket__link__quantity--fourDigit';
        this.productInCartBasketClass = 'm-mainMenu__actions__basket--visible';
        this.logoSmallClass = 'm-mainMenu__links__logo__link--small';
        this.$cartQuantity = document.querySelector('.-logged-in .m-mainMenu__actions__basket__link__quantity');
        this.$cartBasket = document.querySelector('.-logged-in .m-mainMenu__actions__basket');
        this.$logoIcon = document.querySelector('.m-mainMenu__links__logo__link');
        this.init();
    }
    CartItemsIndicator.prototype.changeCartQuantity = function () {
        var _this = this;
        axios.get('/cart/minicart/summary').then(function (response) {
            var items = response.data.items;
            var dealers = response.data.dealers;
            if (items) {
                _this.setQuantity(items);
            }
            if (dealers) {
                _this.setQuantity(dealers);
            }
        }).catch(function (error) {
            console.log(error);
        });
    };
    CartItemsIndicator.prototype.setQuantity = function (items) {
        var value = 0;
        items.forEach(function (item) {
            value += item.quantity;
        });
        if (value > 0) {
            this.$cartQuantity.classList.add(this.productInCartQuantityClass);
            this.$cartBasket.classList.add(this.productInCartBasketClass);
            this.$logoIcon.classList.add(this.logoSmallClass);
            if (value <= 999) {
                this.$cartQuantity.innerHTML = value.toString();
                if (value <= 9) {
                    this.$cartQuantity.classList.add(this.productInCartQuantityOneDigitClass);
                }
                else if (value <= 99) {
                    this.$cartQuantity.classList.add(this.productInCartQuantityTwoDigitClass);
                }
                else {
                    this.$cartQuantity.classList.add(this.productInCartQuantityThreeDigitClass);
                }
            }
            else {
                this.$cartQuantity.innerHTML = '999+';
                this.$cartQuantity.classList.add(this.productInCartQuantityFourDigitClass);
            }
        }
        else {
            this.$cartQuantity.classList.remove(this.productInCartQuantityClass);
            this.$cartBasket.classList.remove(this.productInCartBasketClass);
            this.$logoIcon.classList.remove(this.logoSmallClass);
        }
    };
    CartItemsIndicator.prototype.init = function () {
        this.changeCartQuantity();
    };
    return CartItemsIndicator;
}());
export { CartItemsIndicator };
