var DataRange = /** @class */ (function () {
    function DataRange() {
        this.$dataRangeComponent = document.querySelector('.m-dateRange');
        this.$dateTo = this.$dataRangeComponent.querySelector('#dateTo');
        this.$dateFrom = this.$dataRangeComponent.querySelector('#dateFrom');
        this.init();
    }
    DataRange.prototype.limitDateTo = function () {
        var dateFromValue = this.$dateFrom.value;
        if (dateFromValue) {
            var dateFrom = this.parseDate(dateFromValue);
            var dateTo = this.addDays(dateFrom, 1);
            this.$dateTo.min = this.formatDate(dateTo);
            this.fixDateTo(dateFromValue, this.$dateTo.value);
        }
    };
    DataRange.prototype.fixDateTo = function (dateFromValue, dateToValue) {
        if (dateToValue) {
            var dateTo = this.parseDate(dateToValue);
            var dateFrom = this.parseDate(dateFromValue);
            if (this.getDiffInDays(dateTo, this.parseDate(dateFromValue)) <= 0) {
                this.$dateTo.value = this.formatDate(this.addDays(dateFrom, 30));
            }
        }
    };
    DataRange.prototype.parseDate = function (dateValue) {
        return new Date(Date.parse(dateValue));
    };
    DataRange.prototype.addDays = function (date, days) {
        date.setDate(date.getDate() + days);
        return date;
    };
    DataRange.prototype.getDiffInDays = function (date1, date2) {
        var diff = date1.getTime() - date2.getTime();
        return Math.ceil(diff / (1000 * 3600 * 24));
    };
    DataRange.prototype.formatDate = function (date) {
        return date.toISOString().substring(0, 10);
    };
    DataRange.prototype.init = function () {
        var _this = this;
        this.limitDateTo();
        this.$dateFrom.addEventListener('change', function () { return _this.limitDateTo(); });
    };
    return DataRange;
}());
export { DataRange };
