var ScrollToTop = /** @class */ (function () {
    function ScrollToTop() {
        this.$scrollButton = document.createElement("button");
        this.init();
    }
    ScrollToTop.prototype.scrollWindow = function () {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    ;
    ScrollToTop.prototype.displayScrollButton = function () {
        this.$scrollButton.classList.add("show");
    };
    ;
    ScrollToTop.prototype.hideScrollButton = function () {
        this.$scrollButton.classList.remove("show");
    };
    ;
    ScrollToTop.prototype.checkScrollConditions = function () {
        var heightCondition = window.innerHeight / 2;
        window.scrollY > heightCondition
            ? this.displayScrollButton()
            : this.$scrollButton.classList.remove("show");
    };
    ;
    ScrollToTop.prototype.createScrollButton = function () {
        var scrollBtnArrow = document.createElement("div");
        var scrollBtnText = document.createElement("div");
        scrollBtnArrow.classList.add('a-scrollBtn--arrow');
        scrollBtnText.innerHTML = "TOP";
        scrollBtnText.classList.add('a-scrollBtn--text');
        this.$scrollButton.appendChild(scrollBtnArrow);
        this.$scrollButton.appendChild(scrollBtnText);
        this.$scrollButton.setAttribute("id", "scrollBtn");
        document.body.appendChild(this.$scrollButton);
    };
    ;
    ScrollToTop.prototype.init = function () {
        var _this = this;
        this.createScrollButton();
        this.$scrollButton.addEventListener("click", function () { return _this.scrollWindow(); });
        var timer = null;
        window.addEventListener('scroll', function () {
            _this.checkScrollConditions();
            if (timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                _this.hideScrollButton();
            }, 3000);
        }, false);
    };
    return ScrollToTop;
}());
export { ScrollToTop };
