var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import SimpleBar from 'simplebar';
var MainMenu = /** @class */ (function () {
    function MainMenu() {
        this.level = 0;
        this.choosenLevel = 0;
        this.$menu = document.querySelector('.o-header');
        this.level = 0;
        this.$header = document.querySelector('.o-header');
        this.$menuContainer = document.querySelector('.m-mainMenu__links__list');
        this.$mobileMenu = document.querySelector('.m-mainMenu__links__list__menu--mobile');
        this.$pageMenu = document.querySelector('.m-pageMenu');
        this.$menuItems = this.$menu.querySelectorAll('.m-pageMenu__item__text');
        this.$menuLinksList = document.querySelector('.m-mainMenu__links__list');
        this.$closeMenuBtns = this.$menuLinksList.querySelectorAll('.m-pageSubmenu__closeBtn');
        this.$hamburger = document.querySelector('.a-hamburger');
        this.$prevBtn = this.$menuLinksList.querySelector('.m-mainMenu__links__list__prev');
        this.$prevBtnArgument = this.$prevBtn.querySelector('.m-mainMenu__links__list__prev__argument');
        this.$languageSelector = document.querySelector('.m-languageSelector');
        this.selectedItems = this.$mobileMenu.querySelectorAll('.m-pageMenu__item--active > span');
        this.menuItemClass = 'm-pageMenu__item';
        this.menuItemActiveClass = 'm-pageMenu__item--active';
        this.menuItemBreadcrumbClass = 'm-pageMenu__item--current';
        this.submenuItemClass = 'm-pageSubmenu__list__item';
        this.submenuItemActiveClass = 'm-pageSubmenu__list__item--active';
        this.submenuItemBreadcrumbClass = 'm-pageSubmenu__list__item--current';
        this.itemActiveClasses = [this.menuItemActiveClass, this.submenuItemActiveClass];
        this.prevBtnActiveClass = 'm-mainMenu__links__list__prev--active';
        this.init();
    }
    MainMenu.prototype.prevCategory = function (event) {
        event.stopPropagation();
        this.selectedItems = this.$mobileMenu.querySelectorAll('.m-pageMenu__item--active > span, .m-pageSubmenu__list__item--active > span');
        var prevEl = this.selectedItems[this.selectedItems.length - 1];
        var beforePrevEl = this.selectedItems[this.selectedItems.length - 2];
        if (this.selectedItems && this.selectedItems.length) {
            var elemClass = (prevEl.getAttribute('data-level') === '1') ? this.menuItemClass : this.submenuItemClass;
            prevEl.closest(".".concat(elemClass)).classList.remove("".concat(elemClass, "--active"));
        }
        this.changeLevel();
        if (beforePrevEl) {
            this.$prevBtnArgument.innerHTML = beforePrevEl.getAttribute('data-grandfather-title');
        }
        else {
            this.$prevBtn.classList.remove(this.prevBtnActiveClass);
        }
    };
    MainMenu.prototype.toggleHamburger = function () {
        var _this = this;
        //clear highlighted positions
        var selectedItems = this.$mobileMenu.querySelectorAll(".".concat(this.menuItemActiveClass, ", .").concat(this.submenuItemActiveClass));
        //if we close menu
        selectedItems.forEach(function (selected) {
            var _a;
            (_a = selected.classList).remove.apply(_a, _this.itemActiveClasses);
            _this.$prevBtn.classList.remove(_this.prevBtnActiveClass);
            _this.deactivateBreadcrumbsFirstPosition();
        });
        // select current query items if present
        this.handleCurrentQueryItems().then(function (res) { return _this.deactivateBreadcrumbsFirstPosition(); });
        if (!this.$hamburger.classList.contains('a-hamburger--active')) {
            document.body.classList.add('shadow');
        }
        this.$hamburger.classList.toggle('a-hamburger--active');
        this.$menuLinksList.classList.toggle('m-mainMenu__links__list--active');
    };
    MainMenu.prototype.handleCurrentQueryItems = function () {
        return __awaiter(this, void 0, void 0, function () {
            var currentItems, currentItemsArr, parent_1;
            return __generator(this, function (_a) {
                currentItems = this.$menu.querySelectorAll('.m-pageMenu__item--current, .m-pageSubmenu__list__item--current');
                if (currentItems.length) {
                    currentItemsArr = [].slice.call(currentItems);
                    this.selectedItems = currentItems;
                    parent_1 = currentItemsArr[currentItemsArr.length - 1].querySelector('span');
                    this.$prevBtn.classList.add(this.prevBtnActiveClass);
                    this.$prevBtnArgument.innerHTML = parent_1.getAttribute('data-grandfather-title');
                    this.choosenLevel = +parent_1.getAttribute('data-level');
                    this.changeLevel();
                    currentItems.forEach(function (current) {
                        current.classList.replace('m-pageSubmenu__list__item--current', 'm-pageSubmenu__list__item--active');
                        if (current.classList.contains('m-pageMenu__item--current')) {
                            current.classList.add('m-pageMenu__item--active');
                        }
                        var sub = current.querySelector('.m-pageSubmenu .scrollContainer');
                        if (sub && sub.getAttribute('data-simplebar') !== 'init') {
                            new SimpleBar(sub);
                        }
                    });
                }
                return [2 /*return*/, currentItems];
            });
        });
    };
    MainMenu.prototype.deactivateBreadcrumbsFirstPosition = function () {
        var _this = this;
        var breadcrumbsFirstPosition = this.$menu.querySelectorAll(".".concat(this.menuItemBreadcrumbClass));
        if (breadcrumbsFirstPosition.length) {
            breadcrumbsFirstPosition.forEach(function (breadcrumb) { return breadcrumb.classList.remove(_this.menuItemBreadcrumbClass); });
        }
        var currentItem = this.$menu.querySelector(".".concat(this.submenuItemBreadcrumbClass));
        currentItem === null || currentItem === void 0 ? void 0 : currentItem.classList.remove(this.submenuItemBreadcrumbClass);
    };
    MainMenu.prototype.closeMenu = function () {
        var _this = this;
        this.deactivateBreadcrumbsFirstPosition();
        var selectedItems = this.$menu.querySelectorAll(".".concat(this.menuItemActiveClass, ", .").concat(this.submenuItemActiveClass));
        if (selectedItems.length) {
            selectedItems.forEach(function (selected) {
                var _a;
                (_a = selected.classList).remove.apply(_a, _this.itemActiveClasses);
            });
        }
        if (this.$hamburger.classList.contains('a-hamburger--active')) {
            this.$hamburger.classList.remove('a-hamburger--active');
            document.body.classList.remove('shadow');
        }
        this.$menuLinksList.classList.remove('m-mainMenu__links__list--active');
        this.$prevBtn.classList.remove(this.prevBtnActiveClass);
    };
    MainMenu.prototype.triggerMenu = function (event) {
        var _this = this;
        var item = event.target;
        if (item.parentElement.classList.contains(this.menuItemBreadcrumbClass)) {
            //if on desktop view user click on item from breadcrumbs, we show breadcrumbs in menu
            this.handleCurrentQueryItems().then(function () { return _this.deactivateBreadcrumbsFirstPosition(); });
        }
        else {
            var prevButtonTxt = item.getAttribute('data-grandfather-title');
            event.preventDefault();
            this.deactivateBreadcrumbsFirstPosition();
            if (this.$hamburger.classList.contains('a-hamburger--active')) {
                event.stopPropagation();
                document.body.classList.add('shadow');
            }
            this.choosenLevel = +item.getAttribute('data-level');
            this.selectedItems = this.$menu.querySelectorAll('.m-pageMenu__item--active > span, .m-pageSubmenu__list__item--active > span');
            this.changeLevel();
            var activeClass = item.parentElement.classList.contains('m-pageMenu__item') ? this.menuItemActiveClass : this.submenuItemActiveClass;
            item.parentElement.classList.add(activeClass);
            this.$prevBtn.classList.add(this.prevBtnActiveClass);
            this.$prevBtnArgument.innerHTML = prevButtonTxt;
            var sub = item.parentElement.querySelector('.m-pageSubmenu .scrollContainer');
            if (sub && sub.getAttribute('data-simplebar') !== 'init') {
                new SimpleBar(sub);
            }
        }
    };
    MainMenu.prototype.changeLevel = function () {
        var _this = this;
        if (this.selectedItems && this.selectedItems.length) {
            this.selectedItems.forEach(function (selected) {
                var _a;
                if (+selected.getAttribute('data-level') >= _this.choosenLevel) {
                    (_a = selected.parentElement.classList).remove.apply(_a, _this.itemActiveClasses);
                }
            });
        }
        this.$closeMenuBtns.forEach(function (btn) {
            if (btn.classList.contains("m-pageSubmenu__closeBtn--level".concat(_this.choosenLevel - 1))) {
                btn.classList.add('m-pageSubmenu__closeBtn--active');
            }
            else {
                btn.classList.remove('m-pageSubmenu__closeBtn--active');
            }
        });
    };
    MainMenu.prototype.changeCrossPosition = function (closeMenuBtn) {
        closeMenuBtn.classList.forEach(function (className) {
            if (className.startsWith('level')) {
                closeMenuBtn.classList.remove(className);
            }
        });
        closeMenuBtn.classList.add("level-".concat(this.choosenLevel - 1));
    };
    MainMenu.prototype.setHeaderHeightVariable = function () {
        var headerRect = this.$menu.getBoundingClientRect();
        var headerHeight = headerRect.height;
        var bodyEl = document.querySelector('body');
        bodyEl.setAttribute('style', "--header-height: ".concat(headerHeight, "px"));
    };
    MainMenu.prototype.observeMutations = function () {
        var _this = this;
        // Select the node that will be observed for mutations
        var targetNode = this.$menu;
        // Options for the observer (which mutations to observe)
        var config = { attributes: true, childList: true, subtree: true };
        // Callback function to execute when mutations are observed
        var callback = function (mutationList, observer) {
            for (var _i = 0, mutationList_1 = mutationList; _i < mutationList_1.length; _i++) {
                var mutation = mutationList_1[_i];
                _this.setHeaderHeightVariable();
            }
        };
        // Create an observer instance linked to the callback function
        var observer = new MutationObserver(callback);
        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    };
    MainMenu.prototype.init = function () {
        var _this = this;
        window.onresize = function () {
            _this.closeMenu();
            _this.setHeaderHeightVariable();
        };
        this.setHeaderHeightVariable();
        this.observeMutations();
        this.$prevBtn.addEventListener('click', function (event) { return _this.prevCategory(event); });
        this.$hamburger.addEventListener('click', function () { return _this.toggleHamburger(); });
        this.$closeMenuBtns.forEach(function (closeMenuBtn) {
            closeMenuBtn.addEventListener('click', function () { return _this.closeMenu(); });
        });
        document.addEventListener('keydown', function (event) {
            if (event.keyCode === 27 && document.body.classList.contains('shadow')) {
                _this.closeMenu();
            }
        });
        document.addEventListener('click', function (event) {
            var element = event.target;
            if (!_this.$menuContainer.contains(element) && !_this.$hamburger.contains(element) && !_this.$prevBtn.contains(element)) {
                _this.closeMenu();
            }
        });
        this.$menuItems.forEach(function (item) {
            item.addEventListener('click', function (event) { return _this.triggerMenu(event); });
        });
    };
    return MainMenu;
}());
export { MainMenu };
