var rater = require('rater-js');
var DisplayRater = /** @class */ (function () {
    function DisplayRater(rater) {
        this.$rater = rater;
        this.raterVal = this.$rater.getAttribute('data-rate');
        this.setRater();
    }
    DisplayRater.prototype.setRater = function () {
        var rating = rater({
            element: this.$rater,
            showToolTip: false,
            starSize: 24,
            step: 0.5,
            max: 5,
            readOnly: true
        });
        rating.setRating(parseFloat(this.raterVal));
    };
    return DisplayRater;
}());
export { DisplayRater };
