var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnElementReady } from "./on-element-ready";
import axios from 'axios';
import 'svg-classlist-polyfill';
import * as app from "../main";
var Tooltip = /** @class */ (function () {
    function Tooltip(element, index) {
        this.touchTopClass = 'a-tooltip--touchTop';
        this.touchRightClass = 'a-tooltip--touchRight';
        this.activeClass = 'a-tooltip--active';
        this.$baseElement = element;
        this.index = index;
        this.headerHeight = document.querySelector('.o-header__menu ').getBoundingClientRect().height;
        this.tooltipClass = 'a-tooltip--' + this.index;
        this.init();
    }
    Tooltip.prototype.setTooltip = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.textToDisplay()];
                    case 1:
                        _a.sent();
                        this.setTooltipPosition();
                        this.$tooltip.classList.add(this.activeClass);
                        document.onscroll = function () {
                            if (_this.$tooltip.classList.contains(_this.activeClass)) {
                                _this.setTooltipPosition();
                            }
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    Tooltip.prototype.unsetTooltip = function () {
        this.$tooltip.classList.remove(this.activeClass);
        this.$tooltip.style.cssText = 'left: -100000px;';
        document.onscroll = function () { return false; };
    };
    Tooltip.prototype.getTooltip = function () {
        var _this = this;
        var tooltipText = this.$baseElement.getAttribute('tooltip-text');
        var $text = document.createElement('div');
        $text.classList.add('a-tooltip__text');
        $text.innerHTML = tooltipText;
        var $arrow = document.createElement('div');
        $arrow.classList.add('a-tooltip__arrow');
        var $tooltip = document.createElement('div');
        $tooltip.classList.add('a-tooltip');
        $tooltip.classList.add('a-tooltip--' + this.index);
        $tooltip.appendChild($text);
        $tooltip.appendChild($arrow);
        document.querySelector('body').appendChild($tooltip);
        var elementReady = OnElementReady.prototype.onElementReady(document.querySelector('.' + this.tooltipClass));
        elementReady.then(function () {
            _this.$tooltip = document.querySelector('.' + _this.tooltipClass);
            _this.$tooltipText = _this.$tooltip.querySelector('.a-tooltip__text');
            _this.$tooltipArrow = _this.$tooltip.querySelector('.a-tooltip__arrow');
        });
    };
    Tooltip.prototype.textToDisplay = function () {
        return __awaiter(this, void 0, void 0, function () {
            var $stockInfo, productCode, quantity, requestUrl;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.$baseElement.classList.contains('m-productStockInfo__text--outOfStock')) return [3 /*break*/, 2];
                        $stockInfo = app.default.getSnippets().closest(this.$baseElement, '.m-productStockInfo');
                        productCode = $stockInfo.getAttribute('data-product-code');
                        quantity = +$stockInfo.getAttribute('data-request-quantity');
                        requestUrl = "/stock/".concat(productCode, "/").concat(quantity);
                        return [4 /*yield*/, axios.get(requestUrl)
                                .then(function (res) {
                                _this.$tooltip.setAttribute('tooltip-text', res.data);
                                _this.$tooltip.querySelector('.a-tooltip__text').innerHTML = res.data;
                            })
                                .catch(function (error) { return console.log(error); })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Tooltip.prototype.setTooltipPosition = function () {
        this.bodyReference = document.body.getBoundingClientRect();
        var bodyWidth = this.bodyReference.width;
        var baseElementReference = this.$baseElement.getBoundingClientRect();
        var elementTop = baseElementReference.top;
        var elementLeft = baseElementReference.left;
        var elementRight = baseElementReference.right;
        var elementBottom = baseElementReference.bottom;
        var elementWidth = baseElementReference.width;
        var arrowPosition = elementWidth / 2;
        var tooltipReference = this.$tooltip.getBoundingClientRect();
        var tooltipHeight = tooltipReference.height;
        var tooltipTop = elementTop - tooltipHeight - 10;
        var tooltipRight = tooltipReference.width + elementLeft;
        if (tooltipRight >= bodyWidth) {
            this.$tooltip.classList.add(this.touchRightClass);
            var tooltipRight_1 = bodyWidth - elementRight;
            this.$tooltip.style.cssText = 'right: ' + tooltipRight_1 + 'px;';
            this.$tooltipArrow.style.cssText = 'right: ' + arrowPosition + 'px;';
        }
        else {
            this.$tooltip.style.cssText = 'left: ' + elementLeft + 'px;';
            this.$tooltipArrow.style.cssText = 'left: ' + arrowPosition + 'px;';
        }
        if (tooltipTop >= this.headerHeight) {
            this.$tooltip.style.cssText += 'top: ' + tooltipTop + 'px;';
            this.$tooltip.classList.remove(this.touchTopClass);
        }
        else {
            tooltipTop = elementBottom + 10;
            this.$tooltip.style.cssText += 'top: ' + tooltipTop + 'px;';
            this.$tooltip.classList.add(this.touchTopClass);
        }
    };
    //for tooltips with stock to close even if they open with delay
    Tooltip.prototype.closeTooltip = function (event) {
        var $mouseTarget = event.target;
        if (!this.$baseElement.contains($mouseTarget) && this.$tooltip.classList.contains(this.activeClass)) {
            this.unsetTooltip();
        }
    };
    Tooltip.prototype.init = function () {
        var _this = this;
        this.getTooltip();
        this.$baseElement.addEventListener('mouseenter', function () { return _this.setTooltip(); });
        this.$baseElement.addEventListener('mouseleave', function () { return _this.unsetTooltip(); });
        if (this.$baseElement.classList.contains('m-productStockInfo__text')) {
            window.addEventListener('mousemove', function (event) { return _this.closeTooltip(event); });
        }
        window.addEventListener('resize', function (event) { return _this.unsetTooltip(); });
    };
    return Tooltip;
}());
export { Tooltip };
