var Alert = /** @class */ (function () {
    function Alert(alert) {
        this.TIME_TO_DISPOSE = 3000;
        this.ANIMATION_TIME = 600;
        this.$alert = alert;
        this.$closeButton = alert.querySelector('.m-alert__button');
        this.init();
    }
    Alert.prototype.bindCloseEvent = function (event) {
        event.stopPropagation();
        this.closeAlert();
    };
    Alert.prototype.autoDispose = function () {
        if (this.isAutoDisposableAlert()) {
            this.dispatchHandler = this.createAutocloseDispatcher();
        }
    };
    Alert.prototype.createAutocloseDispatcher = function () {
        var _this = this;
        return window.setTimeout(function () {
            _this.closeAlert();
        }, this.TIME_TO_DISPOSE);
    };
    Alert.prototype.closeAlert = function () {
        var _this = this;
        this.$alert.className = 'm-alert m-alert--delete';
        window.setTimeout(function () {
            if (_this.$alert.parentElement) {
                _this.$alert.parentElement.removeChild(_this.$alert);
            }
        }, this.ANIMATION_TIME);
    };
    Alert.prototype.isAutoDisposableAlert = function () {
        return this.$alert.getAttribute('isAutoDisposableAlert') === 'true';
    };
    Alert.prototype.init = function () {
        var _this = this;
        var _a;
        this.autoDispose();
        (_a = this.$closeButton) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function (event) { return _this.bindCloseEvent(event); });
    };
    return Alert;
}());
export { Alert };
