var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Alert } from "./alert";
var CartAlert = /** @class */ (function (_super) {
    __extends(CartAlert, _super);
    function CartAlert(alertContainer) {
        var _this_1 = _super.call(this, alertContainer) || this;
        _this_1.bindHover();
        return _this_1;
    }
    CartAlert.prototype.bindHover = function () {
        var _this = this;
        this.$alert.addEventListener('mouseenter', function (event) {
            if (_this.dispatchHandler != undefined) {
                window.clearTimeout(_this.dispatchHandler);
            }
        });
        this.$alert.addEventListener('mouseleave', function (event) {
            _this.autoDispose();
        });
    };
    CartAlert.prototype.createAutocloseDispatcher = function () {
        var _this = this;
        var disposalTime = this.TIME_TO_DISPOSE;
        if (document.body.classList.contains('mobile-device')) {
            disposalTime = _this.getMobileAlertDisposalTime();
        }
        return window.setTimeout(function () {
            _this.closeAlert();
        }, disposalTime);
    };
    CartAlert.prototype.getMobileAlertDisposalTime = function () {
        return 5000;
    };
    return CartAlert;
}(Alert));
export { CartAlert };
