import { ScrollPageHandler } from "../scroll-page";
import { Modal } from "../modals/modal";
var CartPathSelector = /** @class */ (function () {
    function CartPathSelector() {
        var _this = this;
        this.$selectPathComponent = document.querySelector('.m-cartPathSelection');
        this.$selectionOptionElements = document.querySelectorAll('.m-cartPathSelection__item');
        var checkoutButtons = document.querySelectorAll('.a-greenBlueBtn');
        var selectPathCheckoutButtons = this.$selectPathComponent.querySelectorAll('.a-greenBlueBtn');
        this.$ivoclarCartTabHeader = document.querySelector('.m-tabPanel__header__content__tabs__name[data-tab="ivoclarvivadent"]');
        this.$ivoclarCartTab = document.querySelector('.m-tabPanel__content__tab[data-tab="ivoclarvivadent"]');
        this.$dealerTabs = document.querySelectorAll('.m-cartHeader .m-tabPanel__header__content__tabs__name');
        this.$checkoutBtns = [];
        this.selectedPathInput = document.querySelector('m-cartPathSelection__item');
        this.isSelectedPathDirect = false;
        this.removeIndirect = false;
        if (this.$dealerTabs.length > 0) {
            if (this.$ivoclarCartTab) {
                var cartTabCheckoutButtons = this.$ivoclarCartTab.querySelectorAll('.a-greenBlueBtn');
                cartTabCheckoutButtons.forEach(function (button) { return _this.$checkoutBtns.push(button); });
                selectPathCheckoutButtons.forEach(function (button) { return _this.$checkoutBtns.push(button); });
            }
        }
        else {
            checkoutButtons.forEach(function (btn) { return _this.$checkoutBtns.push(btn); });
        }
        this.$spo = new ScrollPageHandler();
        this.init();
    }
    CartPathSelector.prototype.onSelect = function (ev) {
        var clickedInput = ev.target;
        var selectionItem = clickedInput.closest('.m-cartPathSelection__item');
        if (selectionItem && !selectionItem.classList.contains('m-cartPathSelection__item--selected')) {
            var $summaryActions = document.querySelector('.m-cartSummary__actions');
            this.removeIndirect = JSON.parse($summaryActions.getAttribute('data-contains-indirect-in-direct-cart'));
            this.selectedPathInput = selectionItem.querySelector('input');
            this.$selectionOptionElements.forEach(function (element) {
                element.classList.remove("m-cartPathSelection__item--selected");
                element.classList.remove("m-cartPathSelection__item--validated");
            });
            selectionItem.classList.add("m-cartPathSelection__item--selected");
            this.selectedPathInput.checked = true;
            this.isSelectedPathDirect = this.selectedPathInput.value === 'order_direct';
            this.$checkoutBtns.forEach(function (btn) {
                btn.setAttribute('href', selectionItem.getAttribute('data-next-url'));
                btn.classList.remove('a-greenBlueBtn--clickableDisabled');
            });
        }
    };
    CartPathSelector.prototype.addModal = function (ev) {
        ev.preventDefault();
        if (this.removeIndirect) {
            var modal = new Modal(document.querySelector('.m-modal#removeIndirectProductsModal'));
            modal.showModal();
        }
    };
    CartPathSelector.prototype.isPathSelected = function () {
        var pathSelected = false;
        this.$selectionOptionElements.forEach(function (element) {
            var radio = element.querySelector('input');
            if (radio.checked) {
                pathSelected = true;
            }
        });
        return pathSelected;
    };
    CartPathSelector.prototype.initCheckoutButtons = function () {
        this.$checkoutBtns.forEach(function (btn) {
            btn.classList.remove('a-greenBlueBtn--disabled');
            btn.classList.add('a-greenBlueBtn--clickableDisabled');
            btn.setAttribute('href', '');
        });
    };
    CartPathSelector.prototype.nextButtonClickHandler = function (ev) {
        if (!this.isPathSelected()) {
            this.$selectionOptionElements.forEach(function (element) { return element.classList.add("m-cartPathSelection__item--validated"); });
            this.$spo.scrollPage('.m-cartPathSelection');
            ev.preventDefault();
        }
        else {
            if (this.isSelectedPathDirect && this.removeIndirect) {
                this.addModal(ev);
            }
            this.$selectionOptionElements.forEach(function (element) {
                var radio = element.querySelector('input');
                radio.checked = false;
                element.classList.remove('m-cartPathSelection__item--selected');
            });
        }
    };
    CartPathSelector.prototype.hidePathSelectionComponent = function () {
        this.$selectPathComponent.classList.add('m-cartPathSelection--hidden');
    };
    CartPathSelector.prototype.showPathSelectionComponent = function () {
        this.$selectPathComponent.classList.remove('m-cartPathSelection--hidden');
    };
    CartPathSelector.prototype.selectedTabChangeHandler = function () {
        if (this.$ivoclarCartTabHeader.classList.contains('m-tabPanel__header__content__tabs__name--active')) {
            this.showPathSelectionComponent();
        }
        else {
            this.hidePathSelectionComponent();
        }
    };
    CartPathSelector.prototype.init = function () {
        var _this = this;
        this.$selectionOptionElements.forEach(function (element) {
            element.addEventListener('click', function (ev) { return _this.onSelect(ev); });
        });
        this.initCheckoutButtons();
        this.$checkoutBtns.forEach(function (btn) { return btn.addEventListener('click', function (ev) { return _this.nextButtonClickHandler(ev); }); });
        if (this.$dealerTabs.length == 0 || this.$ivoclarCartTabHeader.classList.contains('m-tabPanel__header__content__tabs__name--active')) {
            this.showPathSelectionComponent();
        }
    };
    return CartPathSelector;
}());
export { CartPathSelector };
