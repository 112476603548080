var TeaserComponent = /** @class */ (function () {
    function TeaserComponent() {
        this.$teaser = document.querySelector('.m-plpGrid__teaser');
        this.$teaserData = document.querySelector('.m-teaser__data');
        this.$teaserImg = document.querySelector('.m-teaser__img');
        this.init();
    }
    TeaserComponent.prototype.setPosition = function () {
        var columnStart = this.$teaser.getAttribute('columnStart');
        var columnEnd = this.$teaser.getAttribute('columnEnd');
        var rowNumber = this.$teaser.getAttribute('rowNumber');
        var rowEnd = Number(rowNumber) + 1;
        var realColumnEnd = Number(columnEnd) + 1;
        var bannerWidth = Number(realColumnEnd) - Number(columnStart);
        var mobileAndTabletRow = Number(rowNumber) + 1;
        this.$teaser.style.removeProperty('grid-area');
        this.$teaserData.classList.remove('m-teaser__data__anycolumn');
        this.$teaserImg.classList.remove('m-teaser__img__twocolumns');
        this.$teaserImg.classList.remove('m-teaser__img__threecolumns');
        if (window.innerWidth >= 1152) {
            this.$teaser.style.cssText = "grid-area: ".concat(rowNumber, " / ").concat(columnStart, " / ").concat(rowEnd, " / ").concat(realColumnEnd);
            this.$teaserData.classList.add('m-teaser__data__anycolumn');
        }
        else if (window.innerWidth >= 768) {
            this.$teaser.style.cssText = "grid-area: ".concat(mobileAndTabletRow, "/1/3/3");
        }
        else {
            this.$teaser.style.cssText = "grid-area: ".concat(mobileAndTabletRow, "/1/3/2");
        }
        switch (bannerWidth) {
            case 2:
                this.$teaserImg.classList.add('m-teaser__img__twocolumns');
                break;
            case 3:
                this.$teaserImg.classList.add('m-teaser__img__threecolumns');
                break;
        }
    };
    TeaserComponent.prototype.init = function () {
        var _this = this;
        window.addEventListener('resize', function () {
            _this.setPosition();
        });
        this.setPosition();
    };
    return TeaserComponent;
}());
export { TeaserComponent };
