import axios from 'axios';
import * as app from "../main";
import { CartAlert } from "./notification/cart-alert";
import { CartItemsIndicator } from "./cart-items-indicator/cart-items-indicator";
var serialize = require('form-serialize');
var AddToCart = /** @class */ (function () {
    function AddToCart(element) {
        this.$button = element;
        this.$form = element.form;
        this.$qtySelector = this.$form.querySelector('.a-counter__input');
        this.$dealerDropdown = this.$form.querySelector('.a-customSelect--dealer');
        this.init();
    }
    AddToCart.prototype.submitForm = function (event) {
        event.preventDefault();
        var requestUrl = this.$form.action;
        axios.post(requestUrl, serialize(this.$form))
            .then(function (res) {
            var alertContainer = document.querySelector('.o-global-alerts');
            alertContainer.innerHTML = res.data;
            new CartAlert(document.querySelector('.m-alert--addToCart'));
            new CartItemsIndicator();
        })
            .catch(function (error) { return console.log(error); });
        var shouldResetQty = this.$qtySelector.getAttribute("resetCounter") == 'true';
        if (shouldResetQty) {
            this.resetCounter();
        }
        if (this.$dealerDropdown) {
            this.resetDealer();
        }
    };
    AddToCart.prototype.resetCounter = function () {
        this.$qtySelector.value = '1';
    };
    AddToCart.prototype.resetDealer = function () {
        var _this = this;
        var selectArr = app.default.getSelectArr();
        var selectObj = selectArr.find(function (select) { return select.element == _this.$dealerDropdown; });
        selectObj.obj.unselectOption();
    };
    AddToCart.prototype.init = function () {
        var _this = this;
        if (this.$form) {
            this.$button.removeAttribute("disabled");
            this.$button.addEventListener('click', function (event) { return _this.submitForm(event); });
        }
    };
    return AddToCart;
}());
export { AddToCart };
