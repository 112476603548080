import * as app from "../../main";
var AgentSearchProducts = /** @class */ (function () {
    function AgentSearchProducts(btn) {
        this.searchBtn = btn;
        this.init();
    }
    AgentSearchProducts.prototype.openSearch = function () {
        app.default.getSearch().activeSearch();
    };
    AgentSearchProducts.prototype.init = function () {
        var _this = this;
        this.searchBtn.addEventListener('click', function () { return _this.openSearch(); });
    };
    return AgentSearchProducts;
}());
export { AgentSearchProducts };
