var Toolbar = /** @class */ (function () {
    function Toolbar() {
        this.$pureChatButton = document.querySelector('.a-chatButton');
        this.$footer = document.querySelector('.m-footer');
        this.timeout = 10000;
        this.interval = 500;
        this.init();
    }
    Toolbar.prototype.openPureChat = function () {
        window.purechatApi.set('chatbox.expanded', true);
        window.purechatApi.set('chatbox.visible', true);
    };
    Toolbar.prototype.initPureChat = function () {
        var _this = this;
        this.$pureChatButton.addEventListener('click', function () { return _this.openPureChat(); });
        this.$pureChatButton.classList.remove('a-chatButton--hidden');
    };
    Toolbar.prototype.pureChatWatch = function () {
        this.$pureChatComponent = document.querySelector('#PureChatWidget');
        if (this.$pureChatComponent) {
            this.initPureChat();
            clearInterval(this.pureChatWatchTimer);
        }
    };
    Toolbar.prototype.removeWatches = function () {
        clearInterval(this.pureChatWatchTimer);
    };
    Toolbar.prototype.setElementHeightVariable = function () {
        var footerRect = this.$footer.getBoundingClientRect();
        var toolbarElement = document.querySelector('.m-toolbar');
        var plpFilterElement = document.querySelector('.m-plpFilter');
        var paginationElement = document.querySelector('.m-pagination__nav');
        var checkoutNavigatorElement = document.querySelector('.m-checkoutNavigator');
        var pdpOrderPossibilityElement = document.querySelector('.m-productOrderPossibility');
        var accountBtnElement = document.querySelector('.m-myAccountMenuBtnContainer__btn');
        var searchPaginationElement = document.querySelector('.m-pagination');
        var quickOrderSummaryElement = document.querySelector('.m-quickOrderSummary');
        var myAccountButton = accountBtnElement === null || accountBtnElement === void 0 ? void 0 : accountBtnElement.getBoundingClientRect();
        var searchPagination = searchPaginationElement === null || searchPaginationElement === void 0 ? void 0 : searchPaginationElement.getBoundingClientRect();
        var footerHeight = footerRect.height;
        var plpFilterHeight = plpFilterElement === null || plpFilterElement === void 0 ? void 0 : plpFilterElement.getBoundingClientRect().height;
        var checkoutNavigatorHeight = checkoutNavigatorElement === null || checkoutNavigatorElement === void 0 ? void 0 : checkoutNavigatorElement.getBoundingClientRect().height;
        var pdpOrderPossibilityHeight = pdpOrderPossibilityElement === null || pdpOrderPossibilityElement === void 0 ? void 0 : pdpOrderPossibilityElement.getBoundingClientRect().height;
        var quickOrderSummaryHeight = quickOrderSummaryElement === null || quickOrderSummaryElement === void 0 ? void 0 : quickOrderSummaryElement.getBoundingClientRect().height;
        var percentVisible = Math.max(0, Math.min(1, (window.innerHeight - footerRect.top) / footerRect.height));
        var footerVisiblePart = footerHeight * percentVisible;
        var myAccountButtonVisiblePart = window.innerHeight - (myAccountButton === null || myAccountButton === void 0 ? void 0 : myAccountButton.top);
        var myAccountMenuBtnContainerHeight = myAccountButtonVisiblePart - footerVisiblePart;
        var searchPaginationVisiblePart = window.innerHeight - (searchPagination === null || searchPagination === void 0 ? void 0 : searchPagination.top);
        var searchPaginationContainerHeight = searchPaginationVisiblePart - footerVisiblePart;
        //myAccount toolbar
        var mobileMyAccountMenuBtnContainerHeight;
        mobileMyAccountMenuBtnContainerHeight = myAccountButtonVisiblePart - footerVisiblePart;
        //plpSearch toolbar
        var plpSearchToolbarPosition;
        if (searchPaginationVisiblePart > 0) {
            plpSearchToolbarPosition = plpFilterHeight + searchPaginationContainerHeight;
            plpSearchToolbarPosition = searchPaginationVisiblePart - footerVisiblePart + plpFilterHeight;
        }
        else {
            plpSearchToolbarPosition = plpFilterHeight;
        }
        //cart Toolbar
        var cartToolbarPosition;
        if (footerVisiblePart < checkoutNavigatorHeight) {
            cartToolbarPosition = checkoutNavigatorHeight;
        }
        else {
            cartToolbarPosition = footerVisiblePart;
        }
        //pdp Toolbar
        var pdpToolbarPosition;
        if (footerVisiblePart < pdpOrderPossibilityHeight) {
            pdpToolbarPosition = pdpOrderPossibilityHeight;
        }
        else {
            pdpToolbarPosition = footerVisiblePart;
        }
        //quick order Toolbar
        var quickOrderToolbarPosition;
        if (footerVisiblePart < quickOrderSummaryHeight && quickOrderSummaryHeight !== 0) {
            quickOrderToolbarPosition = quickOrderSummaryHeight - footerVisiblePart;
        }
        else {
            quickOrderToolbarPosition = 0;
        }
        toolbarElement.setAttribute('style', "--footer-height: ".concat(footerVisiblePart, "px;\n           --plpFilter-height: ").concat(plpFilterHeight, "px;\n           --myAccountMenuBtnContainer-height: ").concat(myAccountMenuBtnContainerHeight, "px;\n           --mobileMyAccountMenuBtnContainer-height: ").concat(mobileMyAccountMenuBtnContainerHeight, "px;\n           --cartToolbarPosition: ").concat(cartToolbarPosition, "px;\n           --plpSearchToolbarPosition: ").concat(plpSearchToolbarPosition, "px;\n           --quickOrderToolbarPosition: ").concat(quickOrderToolbarPosition, "px;\n           --pdpToolbarPosition: ").concat(pdpToolbarPosition, "px"));
    };
    Toolbar.prototype.init = function () {
        var _this = this;
        window.onresize = function () {
            _this.setElementHeightVariable();
        };
        window.onscroll = function () {
            _this.setElementHeightVariable();
        };
        window.onload = function () {
            _this.setElementHeightVariable();
        };
        this.setElementHeightVariable();
        this.pureChatWatchTimer = setInterval(function () { return _this.pureChatWatch(); }, this.interval);
        this.timeoutWatchTimer = setTimeout(function () { return _this.removeWatches(); }, this.timeout);
    };
    return Toolbar;
}());
export { Toolbar };
