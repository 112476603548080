import { CardSelector } from './card-selector';
var PaymentModeForm = /** @class */ (function () {
    function PaymentModeForm() {
        this.$form = document.querySelector('#paymentForm');
        this.$creditCardRadio = this.$form.querySelector('input[id=creditCardRadio]');
        this.$paymentBtns = this.$form.querySelectorAll('input[name=paymentType]');
        var $creditCardSelector = this.$form.querySelector('.m-cardSelector');
        if ($creditCardSelector != null) {
            this.cardSelector = new CardSelector($creditCardSelector);
        }
        this.init();
    }
    PaymentModeForm.prototype.changePaymentMode = function (input) {
        if (this.cardSelector != null && input.checked) {
            if (input.getAttribute('id') === 'creditCardRadio') {
                this.cardSelector.enable();
            }
            else {
                this.cardSelector.disable();
            }
        }
    };
    PaymentModeForm.prototype.init = function () {
        var _this = this;
        if (this.$paymentBtns != null && this.$paymentBtns.length > 0) {
            this.$paymentBtns.forEach(function (input) { return input.addEventListener('change', function (event) {
                return _this.changePaymentMode(input);
            }); });
            this.$paymentBtns.forEach(function (input) {
                if (input.checked && input.getAttribute('id') === 'creditCardRadio') {
                    _this.cardSelector.enable();
                }
            });
        }
    };
    return PaymentModeForm;
}());
export { PaymentModeForm };
