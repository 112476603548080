import { AlertFactory } from '../notification/alert-factory';
import alertObject from '../notification/global-alerts';
import axios from 'axios';
import * as app from "../../main";
var ServiceRequestContactUsModal = /** @class */ (function () {
    function ServiceRequestContactUsModal() {
        this.$modal = document.querySelector('#service-request-contact-us-modal');
        this.$form = this.$modal.querySelector('form');
        this.$submitBtn = this.$form.querySelector('button[type=submit]');
        this.$closeBtn = this.$modal.querySelector('.m-modal__content__close');
        this.$backBtn = this.$modal.querySelector('.m-serviceRequestContactUsModal__action__back');
        this.alertFactoryObject = new AlertFactory();
        this.submitErrorMessage = this.$modal.getAttribute('submit-error-message');
        this.$messageTextarea = this.$modal.querySelector('.m-serviceRequestContactUsModal__part--message .formToValid__field');
        this.init();
    }
    ServiceRequestContactUsModal.prototype.clearModal = function () {
        this.$modal.querySelectorAll('.a-textarea').forEach(function (element) {
            element.classList.remove('a-textarea--error');
            element.classList.remove('a-textarea--success');
        });
        this.$form.querySelectorAll('textarea').forEach(function (element) {
            element.value = '';
        });
        this.disableSubmitBtn();
    };
    ServiceRequestContactUsModal.prototype.handleCloseModal = function (element) {
        alertObject.showAlert(element);
        this.$closeBtn.click();
    };
    ServiceRequestContactUsModal.prototype.disableSubmitBtn = function () {
        this.$submitBtn.disabled = true;
        this.$submitBtn.classList.add('a-darkBtn--disabled');
    };
    ServiceRequestContactUsModal.prototype.enableSubmitBtn = function () {
        this.$submitBtn.disabled = false;
        this.$submitBtn.classList.remove('a-darkBtn--disabled');
    };
    ServiceRequestContactUsModal.prototype.submitForm = function (event) {
        var _this = this;
        event.preventDefault();
        if (!this.$form.checkValidity()) {
            return;
        }
        this.disableSubmitBtn();
        var data = new FormData(this.$form);
        var requestUrl = this.$form.getAttribute('action');
        axios.post(requestUrl, data, { headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            } })
            .then(function (res) {
            var element = _this.alertFactoryObject.createAlert(res.data, 'Success', 'success', { 'animated': true, 'closable': true, 'disposable': true });
            _this.handleCloseModal(element);
        })
            .catch(function () {
            var element = _this.alertFactoryObject.createAlert(_this.submitErrorMessage, 'Error', 'error', { 'animated': true, 'closable': true });
            _this.handleCloseModal(element);
        });
    };
    ServiceRequestContactUsModal.prototype.init = function () {
        var _this = this;
        this.$modal.addEventListener('show', function () { return _this.clearModal(); });
        this.$backBtn.addEventListener('click', function () { return _this.$closeBtn.click(); });
        this.$submitBtn.addEventListener('click', function (event) { return _this.submitForm(event); });
        this.$messageTextarea.addEventListener('input', function () {
            var $textarea = _this.$messageTextarea.querySelector('.a-textarea');
            app.default.getValidation(_this.$form.id).validationResult($textarea, 'a-textarea');
            var hasError = _this.$messageTextarea.querySelector('.a-textarea--error');
            if (!_this.$submitBtn.disabled && hasError) {
                _this.disableSubmitBtn();
            }
            else if (_this.$submitBtn.disabled && !hasError) {
                _this.enableSubmitBtn();
            }
        }, true);
    };
    return ServiceRequestContactUsModal;
}());
export { ServiceRequestContactUsModal };
