var PlpHeader = /** @class */ (function () {
    function PlpHeader() {
        this.$header = document.querySelector('.m-plpHeader');
        this.$form = this.$header.querySelector('.m-plpHeader__panel__action__form');
        this.$sortInput = this.$header.querySelector('[name="sort"]');
        this.$pageSizeInput = this.$header.querySelector('[name="pageSize"]');
        this.init();
    }
    PlpHeader.prototype.sumbitForm = function (event) {
        var targetElement = event.target;
        if (targetElement) {
            this.$form.submit();
        }
    };
    PlpHeader.prototype.init = function () {
        var _this = this;
        if (this.$sortInput) {
            this.$sortInput.addEventListener('customSelectValueChanged', function (event) { return _this.sumbitForm(event); }, false);
        }
        if (this.$pageSizeInput) {
            this.$pageSizeInput.addEventListener('customSelectValueChanged', function (event) { return _this.sumbitForm(event); }, false);
        }
    };
    return PlpHeader;
}());
export { PlpHeader };
