var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as app from '../../main';
import { FilterGroup } from './filter-group';
import { SavedFilters } from './saved-filters';
var FilterPanel = /** @class */ (function () {
    function FilterPanel() {
        this.$filterPanel = document.querySelector('.m-filterPanel');
        this.$loader = this.$filterPanel.querySelector('.a-loader');
        this.$filtersSection = this.$filterPanel.querySelector('.m-filterPanel__content__options .a-scrollable');
        this.$closeFilterButton = this.$filterPanel.querySelector('.m-filterPanel__content__close');
        this.$firstOptionsHeader = this.$filterPanel.querySelectorAll('.m-accordion__header')[1];
        this.$firstOptionsContainer = this.$filterPanel.querySelectorAll('.m-accordion__container')[1];
        this.$actionsPanel = this.$filterPanel.querySelector('.m-filterPanel__actions');
        this.$filterActionsPanel = this.$filterPanel.querySelector('.m-filterPanel__actions__filters');
        this.$resetFilters = this.$filterPanel.querySelector('.m-filterPanel__actions__filters__clear');
        this.$applyFilters = this.$filterPanel.querySelector('.m-filterPanel__actions__showResults');
        this.searchPage = 'true' === this.$filterPanel.getAttribute('data-search-page');
        this.searchText = this.$filterPanel.getAttribute('data-search-text');
        this.filterGroups = this.createFilterGroups();
        this.$saveFilterModal = document.querySelector('#save-filter-modal');
        this.$basicCustomerModal = document.querySelector('#basicCustomerModal');
        this.$welcomeModal = document.querySelector('#welcomeModal');
        this.$filterPanelSaveBtn = this.$filterPanel.querySelector('.m-filterPanel__actions__filters__save');
        this.$confirmModalBtn = this.$saveFilterModal.querySelector('.m-modal__content__actions__confirmBtn');
        this.$modalInput = this.$saveFilterModal.querySelector('input[name="name"]');
        this.pageCategory = this.$confirmModalBtn.getAttribute('data-category');
        this.savedFiltersInstance = new SavedFilters();
        this.init();
    }
    FilterPanel.prototype.openFilters = function () {
        return __awaiter(this, void 0, void 0, function () {
            var $savedFilters;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.$filterPanel.classList.add('m-filterPanel--active');
                        document.body.classList.add('shadow');
                        if (!!this.searchPage) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.savedFiltersInstance.refreshFilterList()];
                    case 1:
                        _a.sent();
                        $savedFilters = this.$filterPanel.querySelector('.m-filter--hidden');
                        if ($savedFilters) {
                            this.$firstOptionsHeader.classList.add('m-accordion__header--active');
                            this.$firstOptionsContainer.classList.remove('m-accordion__container--collapsed');
                        }
                        this.checkPossibilityToSaveFilterOption();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    FilterPanel.prototype.checkPossibilityToSaveFilterOption = function () {
        var urlString = window.location.href;
        var url = new URL(urlString);
        var hasSelectedOptions = url.searchParams.get('q') !== null;
        var hasSelectedFilter = this.$filterPanel.querySelector('.m-filter__container__savedFilter--selected') !== null;
        if (hasSelectedOptions && !hasSelectedFilter) {
            this.activeSaveFilterOption();
        }
    };
    FilterPanel.prototype.activeSaveFilterOption = function () {
        this.$filterActionsPanel.classList.add('m-filterPanel__actions__filters--activeSave');
    };
    FilterPanel.prototype.setUrl = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, location, query, requestUrl, $options;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        location = window.location;
                        if (this.searchPage) {
                            url = new URL("".concat(location.origin).concat(window.ACC.config.encodedContextPath, "/search/facets"));
                        }
                        else {
                            url = new URL("".concat(location.origin).concat(location.pathname, "/facets"));
                        }
                        query = this.collectCurrentQuery();
                        url.searchParams.append('q', query);
                        requestUrl = url.href;
                        return [4 /*yield*/, this.updateFilters(requestUrl)];
                    case 1:
                        _a.sent();
                        this.savedFiltersInstance.removeSavedFiltersSelections();
                        $options = this.$filterPanel.querySelectorAll('.m-filter__container__option--selected');
                        if ($options.length && !this.searchPage) {
                            this.$filterActionsPanel.classList.add('m-filterPanel__actions__filters--activeSave');
                        }
                        else {
                            this.$filterActionsPanel.classList.remove('m-filterPanel__actions__filters--activeSave');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    FilterPanel.prototype.updateFilters = function (requestUrl) {
        return __awaiter(this, void 0, void 0, function () {
            var res, response_1, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.$loader.classList.add('a-loader--active');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, fetch(requestUrl)];
                    case 2:
                        res = _a.sent();
                        return [4 /*yield*/, res.json()];
                    case 3:
                        response_1 = _a.sent();
                        this.filterGroups.forEach(function (group) {
                            group.updateGroup(response_1.facets);
                        });
                        this.updateSearchResultsCount(response_1.count);
                        this.$loader.classList.remove('a-loader--active');
                        this.updateFiltersCount(response_1.breadcrumbs.length);
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.log('Fetch error: ', error_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    FilterPanel.prototype.collectCurrentQuery = function () {
        var query = this.searchPage ? this.searchText : '';
        query += ':mostViewed';
        this.filterGroups.forEach(function (group) {
            query += group.getCurrentQuery();
        });
        return query;
    };
    FilterPanel.prototype.closeFilterPanel = function () {
        this.$filterPanel.classList.remove('m-filterPanel--active');
    };
    FilterPanel.prototype.createFilterGroups = function () {
        var facets = new Array();
        var filterGroups = Array.prototype.slice.call(this.$filtersSection.querySelectorAll('.m-filter'));
        filterGroups.forEach(function (facet) {
            facets.push(new FilterGroup(facet));
        });
        return facets;
    };
    FilterPanel.prototype.updateFiltersCount = function (filtersAmount) {
        var url = this.getSearchRefinementUrl();
        if (this.searchPage) {
            url.searchParams.append('q', this.searchText);
        }
        var message = this.$resetFilters.getAttribute('title');
        var selectedFiltersNumber = 0;
        var selectedFilters = [];
        this.filterGroups.forEach(function (group) {
            group.getFilterValues().forEach(function (value) {
                if (value.isSelected()) {
                    selectedFiltersNumber++;
                    selectedFilters.push({ 'category': group.getCode(), 'option': value.getValue() });
                }
            });
        });
        this.savedFiltersInstance.setOptions(selectedFilters);
        this.$resetFilters.text = filtersAmount !== undefined ? "".concat(message, " (").concat(filtersAmount, ")") : "".concat(message, " (").concat(selectedFiltersNumber, ")");
        this.$resetFilters.setAttribute('href', url.href);
    };
    FilterPanel.prototype.updateSearchResultsCount = function (count) {
        var message = this.$applyFilters.getAttribute('title');
        this.$applyFilters.innerText = "".concat(message, " (").concat(count, ")");
    };
    FilterPanel.prototype.getSearchRefinementUrl = function () {
        var url = new URL(window.document.location.origin + window.document.location.pathname);
        var searchParams = new URLSearchParams(window.document.location.search);
        if (searchParams.get('pageSize')) {
            url.searchParams.append('pageSize', searchParams.get('pageSize'));
        }
        if (searchParams.get('sort')) {
            url.searchParams.append('sort', searchParams.get('sort'));
        }
        return url;
    };
    FilterPanel.prototype.prepareDefaultView = function () {
        this.$modalInput.value = '';
    };
    FilterPanel.prototype.saveFilter = function () {
        var filterName = this.$modalInput.value;
        var categoryCode = this.pageCategory;
        this.savedFiltersInstance.saveFilter(filterName, categoryCode);
    };
    FilterPanel.prototype.checkActiveOptionsTrigger = function () {
        var _this = this;
        app.default.sessionStorageData().useSessionValues('triggerFilterPanel', function (sessionData) { return __awaiter(_this, void 0, void 0, function () {
            var options, facets, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        options = sessionData.data.code;
                        facets = this.savedFiltersInstance.selectActiveFacet(options);
                        url = this.savedFiltersInstance.updateFacetsInQuery(facets);
                        return [4 /*yield*/, this.updateFilters(url.toString())];
                    case 1:
                        _a.sent();
                        this.savedFiltersInstance.setOptions(options);
                        this.activeSaveFilterOption();
                        return [4 /*yield*/, this.openFilters()];
                    case 2:
                        _a.sent();
                        this.$filterPanelSaveBtn.click();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    FilterPanel.prototype.init = function () {
        var _this = this;
        this.updateFiltersCount();
        window.addEventListener('resize', function () { return _this.closeFilterPanel(); });
        document.addEventListener('click', function (event) {
            var _a;
            // checking if the panel is open at all before the next operations -> saves memory
            if (_this.$filterPanel.classList.contains('m-filterPanel--active')) {
                var element = event.target;
                var gigyaLoginModal = app.default.getSnippets().closest(element, '.gigya-screen-dialog');
                if (!_this.$filterPanel.contains(element) && !_this.$saveFilterModal.contains(element)
                    && !_this.$basicCustomerModal.contains(element) && !((_a = _this.$welcomeModal) === null || _a === void 0 ? void 0 : _a.contains(element))
                    && !gigyaLoginModal) {
                    _this.closeFilterPanel();
                }
            }
        });
        this.$applyFilters.addEventListener('click', function () {
            var url = _this.getSearchRefinementUrl();
            var query = _this.collectCurrentQuery();
            url.searchParams.append('q', query);
            var $activeFilter = _this.$filterPanel.querySelector('.m-filter__container__savedFilter--selected');
            if ($activeFilter) {
                var filterName = $activeFilter.querySelector('.m-filter__container__savedFilter__text').innerHTML;
                url.searchParams.append('filter', encodeURIComponent(filterName));
            }
            window.location.href = url.href;
        });
        this.$closeFilterButton.addEventListener('click', function (event) {
            event.stopPropagation();
            _this.closeFilterPanel();
            document.body.classList.remove('shadow');
        });
        this.$filterPanel.addEventListener('click', function (event) { return event.stopPropagation(); });
        var $editBtn = this.savedFiltersInstance.getEditBtn();
        if ($editBtn) {
            $editBtn.addEventListener('click', function (event) { return _this.savedFiltersInstance.toggleSavedFiltersEditMode(event); });
            this.savedFiltersInstance.removeSavedFilterHandler();
        }
        this.$saveFilterModal.addEventListener('show', function () { return _this.prepareDefaultView(); });
        this.$confirmModalBtn.addEventListener('click', function () { return _this.saveFilter(); });
        this.checkActiveOptionsTrigger();
    };
    return FilterPanel;
}());
export { FilterPanel };
