import * as app from '../main';
var RemoveShadow = /** @class */ (function () {
    function RemoveShadow() {
        this.$search = document.querySelector('.m-search');
        this.$searchContainer = document.querySelector('.m-search__results');
        this.$pageMenu = document.querySelector('.m-mainMenu__links__list__menu--mobile .m-pageMenu');
        this.$prevBtn = document.querySelector('.m-mainMenu__links__list__prev');
        this.$filterPanel = document.querySelector('.m-filterPanel');
        this.$filterPanelModal = document.querySelector('#save-filter-modal');
        this.$basicCustomerModal = document.querySelector('#basicCustomerModal');
        this.$welcomeModal = document.querySelector('#welcomeModal');
        this.$agentSearchBtn = document.querySelector('.m-agentLandingPageContent__searchBtn');
        this.init();
    }
    RemoveShadow.prototype.init = function () {
        var _this = this;
        window.addEventListener('orientationchange', function () { return document.body.classList.remove('shadow'); });
        window.addEventListener('resize', function () { return document.body.classList.remove('shadow'); });
        document.addEventListener('click', function (event) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            // checking if shadow is active at all before the next operations -> saves memory
            if (document.body.classList.contains('shadow')) {
                var element = event.target;
                var $hamburger = document.querySelector('.a-hamburger--active');
                var gigyaLoginModal = app.default.getSnippets().closest(event.target, '.gigya-screen-dialog');
                if (!((_a = _this.$search) === null || _a === void 0 ? void 0 : _a.contains(element)) && !((_b = _this.$searchContainer) === null || _b === void 0 ? void 0 : _b.contains(element))
                    && !((_c = _this.$pageMenu) === null || _c === void 0 ? void 0 : _c.contains(element)) && !($hamburger === null || $hamburger === void 0 ? void 0 : $hamburger.contains(element)) && !((_d = _this.$prevBtn) === null || _d === void 0 ? void 0 : _d.contains(element))
                    && !((_e = _this.$filterPanel) === null || _e === void 0 ? void 0 : _e.contains(element)) && !((_f = _this.$filterPanelModal) === null || _f === void 0 ? void 0 : _f.contains(element))
                    && !_this.$basicCustomerModal.contains(element) && !((_g = _this.$welcomeModal) === null || _g === void 0 ? void 0 : _g.contains(element))
                    && !((_h = _this.$agentSearchBtn) === null || _h === void 0 ? void 0 : _h.contains(element)) && !gigyaLoginModal) {
                    document.body.classList.remove('shadow');
                }
            }
        });
    };
    return RemoveShadow;
}());
export { RemoveShadow };
