import * as app from '../main';
var CustomSelect = /** @class */ (function () {
    function CustomSelect(element) {
        this.dropdownActiveClass = 'a-customSelect__dropdown--active';
        this.$select = element;
        this.$selectDataField = this.$select.querySelector('.a-customSelect__field');
        this.$selectInputVisible = this.$select.querySelector('.a-input__field__input__text');
        this.$selectInputHidden = this.$select.querySelector('.a-customSelect__field__input--hidden');
        this.$selectDropdown = this.$select.querySelector('.a-customSelect__dropdown');
        this.$selectOptionArr = this.$select.querySelectorAll('.a-customSelect__dropdown__option__input');
        this.$selectDropdownInput = this.$select.querySelector('.a-customSelect__dropdown__input');
        this.init();
    }
    CustomSelect.prototype.validateField = function ($validationField) {
        if ($validationField) {
            var $field = $validationField.querySelector('.a-input');
            var $form = app.default.getSnippets().closest($field, '.formToValid');
            app.default.getValidation($form.id).validationResult($field, 'a-input');
        }
    };
    CustomSelect.prototype.closeDropdown = function () {
        var $activeDropdown = this.$select.querySelector(".".concat(this.dropdownActiveClass));
        if ($activeDropdown) {
            var $validationField = app.default.getSnippets().closest($activeDropdown, '.formToValid__field');
            this.$selectDropdown.classList.remove(this.dropdownActiveClass);
            this.validateField($validationField);
        }
    };
    CustomSelect.prototype.activeDropdown = function () {
        this.$selectDropdown.classList.add(this.dropdownActiveClass);
        if (this.$selectDropdownInput) {
            this.$selectDropdownInput.focus();
        }
    };
    CustomSelect.prototype.updateInputValue = function (value, text, dispatchEvent) {
        if (dispatchEvent === void 0) { dispatchEvent = true; }
        this.$selectInputHidden.value = value;
        this.$selectInputVisible.innerText = text;
        if (dispatchEvent) {
            var customEvent = new CustomEvent('customSelectValueChanged');
            this.$selectInputHidden.dispatchEvent(customEvent);
        }
    };
    CustomSelect.prototype.selectOption = function (option, dispatchEvent) {
        if (dispatchEvent === void 0) { dispatchEvent = true; }
        var value = option.value;
        var text = option.getAttribute('data-text') || value;
        this.updateInputValue(value, text, dispatchEvent);
    };
    CustomSelect.prototype.unselectOption = function () {
        var selectedOption = this.$select.querySelector(".a-customSelect__dropdown__option input:checked");
        if (selectedOption) {
            this.updateInputValue('', '', false);
            selectedOption.checked = false;
        }
    };
    CustomSelect.prototype.chosenOption = function (event) {
        var $option = event.target;
        this.selectOption($option);
        this.closeDropdown();
    };
    CustomSelect.prototype.selectInitialOption = function () {
        var _this = this;
        var initialOption = (Array.from(this.$selectOptionArr)).find(function (select) {
            return select.defaultValue === _this.$selectInputHidden.getAttribute('data-code');
        });
        if (initialOption) {
            this.unselectOption();
            this.selectOption(initialOption);
            initialOption.checked = true;
        }
    };
    CustomSelect.prototype.propagateInputValue = function () {
        this.selectOption(this.$selectDropdownInput);
    };
    CustomSelect.prototype.selectInitValue = function () {
        var $selectedInitInput = this.$select.querySelector('[checked="checked"]');
        if ($selectedInitInput) {
            this.selectOption($selectedInitInput, false);
        }
    };
    CustomSelect.prototype.init = function () {
        var _this = this;
        this.selectInitValue();
        this.$selectDataField.addEventListener('click', function () { return _this.activeDropdown(); });
        this.$selectDataField.addEventListener('change', function () {
            // Security so that the user does not change the value himself
            var $selectedInitInput = _this.$select.querySelector('input:checked');
            $selectedInitInput ? _this.selectOption($selectedInitInput) : _this.updateInputValue('', '');
        });
        this.$select.addEventListener('click', function (event) {
            var targetElement = event.target;
            while (targetElement != null) {
                var classList = targetElement.classList;
                if (targetElement instanceof HTMLInputElement && classList.contains('a-customSelect__dropdown__option__input')) {
                    _this.chosenOption(event);
                    break;
                }
                else if (classList.contains('a-customSelect__dropdown__label')) {
                    _this.closeDropdown();
                    break;
                }
                targetElement = targetElement.parentElement;
            }
        });
        this.$selectOptionArr.forEach(function (option) {
            option.addEventListener('click', function (event) {
                event.stopPropagation();
                _this.chosenOption(event);
            });
        });
        document.addEventListener('click', function (event) {
            var node = event.target;
            if (!_this.$selectDropdown.contains(node) && !_this.$selectDataField.contains(node)) {
                _this.closeDropdown();
            }
        });
        document.addEventListener('scroll', function () { return _this.closeDropdown(); });
        if (this.$selectDropdownInput) {
            this.$selectDropdownInput.addEventListener('change', function () { return _this.propagateInputValue(); });
        }
        this.selectInitialOption();
    };
    return CustomSelect;
}());
export { CustomSelect };
