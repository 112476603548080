var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LanguageData } from "./modals/data/language-data";
import { CountryData } from "./modals/country-data";
import { LanguageSelector } from "./modals/language-selector";
var GatewayCountrySelector = /** @class */ (function (_super) {
    __extends(GatewayCountrySelector, _super);
    function GatewayCountrySelector(gatewayCountrySelector) {
        var _this = _super.call(this, gatewayCountrySelector) || this;
        _this.$gatewayCountrySelector = gatewayCountrySelector;
        _this.$countryCodeField = _this.$gatewayCountrySelector.querySelector('#countryCode');
        _this.$languageCodeField = _this.$gatewayCountrySelector.querySelector('#languageCode');
        _this.$gatewayForm = document.querySelector('#gatewayCountryLanguageSelector');
        _this.initSelector();
        return _this;
    }
    GatewayCountrySelector.prototype.initSelector = function () {
        var countryHasShop = window.countryHasShop;
        var result = [];
        countryHasShop.forEach(function (country) {
            var languagesArray = [];
            var variantUrl = '';
            country.values.name.forEach(function (language, index) {
                variantUrl = country.values.url.replace(country.values.isoCode[0], country.values.isoCode[index]);
                languagesArray.push(new LanguageData(language, undefined, variantUrl, index === 0, country.values.isoCode[index]));
            });
            result.push(new CountryData(country.values.countryName, undefined, languagesArray, country.code));
        });
        this.updateSelector(result);
    };
    GatewayCountrySelector.prototype.updateSelector = function (countryData) {
        var _this = this;
        this.countryDataList = countryData;
        _super.prototype.prepareCountrySelector.call(this, countryData);
        this.countryArray.forEach(function (item) {
            item.addEventListener('click', function () {
                _this.setupLanguageSelector(item);
                _super.prototype.closeCountryDropdown.call(_this);
            });
        });
        this.$languageSelectOptionsContainer.addEventListener('click', function () { return _this.$languageCodeField.value = _this.selectedId; });
    };
    GatewayCountrySelector.prototype.setupLanguageSelector = function (item) {
        var country = item.textContent;
        this.$countryField.value = country;
        this.$countryCodeField.value = item.id;
        var countryData = this.countryDataList.find(function (countryData) { return countryData.name === country; });
        _super.prototype.prepareLanguageSelector.call(this, countryData.languages, this.$gatewayForm, 'action');
        this.$languageCodeField.value = this.$languageSelectInputHidden.value;
        _super.prototype.enableGoButton.call(this);
    };
    return GatewayCountrySelector;
}(LanguageSelector));
export { GatewayCountrySelector };
