import * as app from '../../main';
var Gigya = /** @class */ (function () {
    function Gigya() {
        this.redirectID = 'anonymousRedirect';
        this.$loginLinksArr = document.querySelectorAll('.iv-raas-link');
        this.$logoutLinksArr = document.querySelectorAll('.iv-raas-logout-link');
        this.init();
    }
    Gigya.prototype.login = function (event) {
        event.preventDefault();
        var element = event.currentTarget;
        var screenSet = this.initScreenSet(element);
        if (element.classList.contains('m-favouriteHeart--login')) {
            var productCode = element.getAttribute('data-trigger-code');
            if (productCode) {
                screenSet = this.setSessionDataOnAfterSubmit(screenSet, 'triggerFavourite', {
                    code: productCode
                });
            }
        }
        if (element.classList.contains('m-filterPanel__actions__filters__save')) {
            var filter = app.default.getFilter();
            var options = filter.savedFiltersInstance.getOptions();
            screenSet = this.setSessionDataOnAfterSubmit(screenSet, 'triggerFilterPanel', {
                code: options
            });
        }
        window.gigya.accounts.showScreenSet(screenSet);
    };
    Gigya.prototype.setSessionDataOnAfterSubmit = function (screenSet, name, data) {
        screenSet.onAfterSubmit = function (response) {
            if (response.response.status === 'OK') {
                app.default.sessionStorageData().setItem(name, data);
            }
        };
        return screenSet;
    };
    Gigya.prototype.initScreenSet = function (element) {
        var gigyaId = element.getAttribute('data-gigya-id');
        var gigyaProfileEdit = element.getAttribute('data-profile-edit');
        var gigyaRedirectUrl = element.getAttribute('data-gigya-redirect-url');
        var id = gigyaId ? gigyaId : document.querySelector('a[data-gigya-id]').getAttribute('data-gigya-id');
        var screenSet;
        if (gigyaId == this.redirectID) {
            gigyaHybris.executeRedirect(this.getRedirectionPath(gigyaRedirectUrl));
        }
        screenSet = window.gigyaHybris.raas[id];
        screenSet.lang = window.gigyaHybris.gigyaFunctions.getLang();
        //if not gigyaProfileEdit, overwrite onAfterSubmit() to be sure that any session storage value doesn't be added then is not needed
        screenSet.onAfterSubmit = gigyaProfileEdit ? window.gigyaHybris.gigyaFunctions.raasEditProfile : function () {
        };
        if (gigyaRedirectUrl) {
            window.gigyaHybris.redirectUrl = gigyaRedirectUrl;
        }
        return screenSet;
    };
    Gigya.prototype.getRedirectionPath = function (gigyaRedirectUrl) {
        var slashSeparator = '/';
        var servicesRedirectParam = gigyaRedirectUrl.split(slashSeparator);
        if (servicesRedirectParam.length >= 2) {
            var length_1 = servicesRedirectParam.length - 1;
            var lang = servicesRedirectParam[length_1 - 1];
            var path = servicesRedirectParam[length_1];
            return slashSeparator + lang + slashSeparator + path;
        }
        return gigyaRedirectUrl;
    };
    Gigya.prototype.init = function () {
        var _this = this;
        window.gigyaHybris.gigyaFunctions.gigyaLoginInit();
        this.$loginLinksArr.forEach(function (link) {
            link.addEventListener('click', function (event) { return _this.login(event); });
        });
        this.$logoutLinksArr.forEach(function (link) {
            link.addEventListener('click', function (event) {
                event.preventDefault();
                window.gigya.accounts.logout();
            });
        });
    };
    return Gigya;
}());
export { Gigya };
