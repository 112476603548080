var LanguageData = /** @class */ (function () {
    function LanguageData(name, englishName, url, isDefault, isoCode) {
        this._name = name;
        this._englishName = englishName;
        this._url = url;
        this._defaultLanguage = isDefault;
        this._isoCode = isoCode;
    }
    Object.defineProperty(LanguageData.prototype, "defaultLanguage", {
        get: function () {
            return this._defaultLanguage;
        },
        set: function (value) {
            this._defaultLanguage = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LanguageData.prototype, "url", {
        get: function () {
            return this._url;
        },
        set: function (value) {
            this._url = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LanguageData.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (value) {
            this._name = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LanguageData.prototype, "englishName", {
        get: function () {
            return this._englishName;
        },
        set: function (value) {
            this._englishName = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LanguageData.prototype, "isoCode", {
        get: function () {
            return this._isoCode;
        },
        set: function (value) {
            this._isoCode = value;
        },
        enumerable: false,
        configurable: true
    });
    return LanguageData;
}());
export { LanguageData };
