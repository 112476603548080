import axios from 'axios';
var Search = /** @class */ (function () {
    function Search() {
        this.$search = document.querySelector('.m-search');
        this.$searchBtn = this.$search.querySelector('.m-search__field__btn');
        this.$searchCloseMobile = this.$search.querySelector('.m-search__field--mobile .m-search__field__close');
        this.$searchCloseDesktop = this.$search.querySelector('.m-search__field--desktop .m-search__field__close');
        this.$searchIcon = this.$search.querySelector('.m-search__icon');
        this.$searchInputMobile = this.$search.querySelector('.m-search__field--mobile .m-search__field__input');
        this.$searchInputDesktop = this.$search.querySelector('.m-search__field--desktop .m-search__field__input');
        this.$searchContainer = this.$search.querySelector('.m-search__panel');
        this.$resultsContainer = this.$search.querySelector('.m-search__panel__results');
        this.$resultsContainerArr = this.$search.querySelectorAll('.m-searchResults');
        this.$resultsHeaderArr = this.$search.querySelectorAll('.m-tabPanel__header__content__tabs__name');
        this.$searchShowAllBtn = this.$search.querySelector('.m-foundLinks__btn');
        this.$loader = this.$search.querySelector('.a-loader');
        this.$shopTab = this.$resultsContainer.querySelector(".m-tabPanel__content__tab[data-tab='shop']");
        this.$websiteTab = this.$resultsContainer.querySelector(".m-tabPanel__content__tab[data-tab='website']");
        this.searchAbortController = new AbortController();
        this.$agentSearchBtn = document.querySelector('.m-agentLandingPageContent__searchBtn');
        this.productItemsLength = 0;
        this.inputVal = '';
        this.encodedInputVal = '';
        this.websiteResultsLink = '';
        this.shopResultsLink = '';
        this.productLink = '';
        this.searchInProgress = false;
        this.init();
    }
    Search.prototype.clearSearch = function () {
        this.$search.classList.remove('m-search--active');
        this.$searchContainer.classList.remove('m-search__panel__result--active');
        this.$searchInputMobile.value = '';
        this.$searchInputDesktop.value = '';
        this.$resultsContainer.classList.remove('m-search__panel__results--active');
    };
    Search.prototype.closeSearch = function (ev) {
        this.clearSearch();
        this.$resultsContainer.classList.remove('m-search__panel__results--active');
        ev.stopPropagation();
        document.body.classList.remove('shadow');
    };
    Search.prototype.activeSearch = function () {
        this.$search.classList.add('m-search--active');
        if (!document.body.classList.contains('internet-explorer')) {
            this.$searchInputDesktop.focus();
        }
        document.body.classList.add('shadow');
    };
    Search.prototype.switchPage = function (ev) {
        if (ev.keyCode === 13 && this.inputVal.length >= 3 && !this.searchInProgress) {
            var $activeTab = this.$search.querySelector('.m-tabPanel__header__content__tabs__name--active');
            var activeTabName = $activeTab.getAttribute('data-tab');
            location.href = activeTabName === 'website' ? this.websiteResultsLink : this.shopResultsLink;
        }
    };
    Search.prototype.clearResults = function () {
        this.$resultsContainerArr.forEach(function (container) {
            container.classList.remove('m-searchResults--noItems');
            var items = container.querySelectorAll('.m-searchResults__list__items__item');
            items.forEach(function (item, index) {
                if (index > 0) {
                    item.parentNode.removeChild(item);
                }
            });
        });
        this.$resultsHeaderArr.forEach(function (header) {
            header.classList.remove('m-tabPanel__header__content__tabs__name--noData');
        });
    };
    Search.prototype.noResults = function (tab) {
        var $container = tab.querySelector('.m-searchResults');
        $container.classList.add('m-searchResults--noItems');
        var tabName = tab.getAttribute('data-tab');
        var $tabHeader = this.$search.querySelector(".m-tabPanel__header__content__tabs__name[data-tab=\"".concat(tabName, "\"]"));
        $tabHeader.classList.add('m-tabPanel__header__content__tabs__name--noData');
    };
    Search.prototype.displayShopItems = function (products, tab) {
        var $listItemsContainer = tab.querySelector('.m-searchResults__list__items');
        var $showAllLink = tab.querySelector('.m-searchResults__list__btn');
        var priceGST = this.$search.getAttribute('data-price-gst');
        var contextPath = window.ACC.config.encodedContextPath;
        products.forEach(function (item) {
            var $itemPattern = tab.querySelector('.m-searchResults__list__items__item').cloneNode(true);
            var searchResultsElement = tab.querySelector('.m-searchResults__list__items');
            var msrpMessage = searchResultsElement.getAttribute('data-MSRP-message');
            var itemData = {
                code: item.code,
                name: item.displayName ? item.displayName : (item.name ? item.name : item.code),
                url: contextPath + item.url,
                price: (item.price && item.price.formattedValue) ? msrpMessage + item.price.formattedValue + priceGST : '',
                image: (item.images != null && item.images[0]) ? item.images[0].url : window.ACC.autocompleteBlankImage
            };
            $itemPattern.setAttribute('href', itemData.url);
            $itemPattern.setAttribute('data-code', itemData.code);
            $itemPattern.querySelector('.m-searchResults__list__items__item__text__name').innerHTML = itemData.name;
            $itemPattern.querySelector('.m-searchResults__list__items__item__imgContainer__img').setAttribute('src', itemData.image);
            $itemPattern.querySelector('.m-searchResults__list__items__item__text__price').innerHTML = itemData.price;
            $itemPattern.querySelector('.m-searchResults__list__items__item__price').innerHTML = itemData.price;
            $listItemsContainer.appendChild($itemPattern);
        });
        this.productLink = contextPath + products[0].url;
        this.shopResultsLink = "".concat(contextPath, "/search/products?text=").concat(this.encodedInputVal);
        $showAllLink.setAttribute('href', this.shopResultsLink);
    };
    Search.prototype.displayWebsiteItems = function (products, tab) {
        var $listItemsContainer = tab.querySelector('.m-searchResults__list__items');
        var $showAllLink = tab.querySelector('.m-searchResults__list__btn');
        products.forEach(function (item) {
            var $itemPattern = tab.querySelector('.m-searchResults__list__items__item').cloneNode(true);
            var itemData = {
                id: item.id,
                name: item.title,
                url: item.url,
                image: (item.imageData != null && item.imageData.mobileSizeUrl != null) ? item.imageData.mobileSizeUrl : window.ACC.autocompleteBlankImage
            };
            $itemPattern.setAttribute('href', itemData.url);
            $itemPattern.setAttribute('data-code', itemData.id);
            $itemPattern.querySelector('.m-searchResults__list__items__item__text__name').innerHTML = itemData.name;
            $itemPattern.querySelector('.m-searchResults__list__items__item__imgContainer__img').setAttribute('src', itemData.image);
            $listItemsContainer.appendChild($itemPattern);
        });
        $showAllLink.setAttribute('href', this.websiteResultsLink);
    };
    Search.prototype.displayResults = function (products, tab) {
        if (products.length == 0) {
            this.noResults(tab);
        }
        else {
            var tabName = tab.getAttribute('data-tab');
            if (tabName === 'shop') {
                this.displayShopItems(products, tab);
            }
            else {
                this.displayWebsiteItems(products, tab);
            }
        }
    };
    Search.prototype.searchText = function (ev) {
        var _this = this;
        var $input = ev.target;
        this.inputVal = $input.value;
        if (this.inputVal.length >= 3 && ev.key !== 'Enter') {
            var desktopResultsSize = this.$search.getAttribute('data-result-size');
            var mobileResultsSize = this.$search.getAttribute('data-mobile-result-size');
            var resultsSize = document.body.classList.contains('mobile-device') ? mobileResultsSize : desktopResultsSize;
            this.searchAbortController.abort();
            this.searchAbortController = new AbortController();
            this.$loader.classList.add('a-loader--active');
            this.encodedInputVal = encodeURIComponent(this.inputVal);
            var requestUrl = "/search/autocomplete/SearchBox?term=".concat(this.encodedInputVal, "&resultSize=").concat(resultsSize, "&showCmsResults=true");
            this.searchInProgress = true;
            axios.get(requestUrl, {
                signal: this.searchAbortController.signal
            })
                .then(function (res) {
                var shopProducts = res.data.products;
                var websiteProducts = res.data.websiteData;
                _this.websiteResultsLink = res.data.showAllWebsiteUrl;
                _this.clearResults();
                _this.displayResults(shopProducts, _this.$shopTab);
                _this.displayResults(websiteProducts, _this.$websiteTab);
                _this.productItemsLength = shopProducts.length;
                _this.searchInProgress = false;
            })
                .then(function () {
                _this.$loader.classList.remove('a-loader--active');
                _this.$resultsContainer.classList.add('m-search__panel__results--active');
            })
                .catch(function (error) {
                if (error.message !== 'canceled') {
                    _this.searchInProgress = false;
                    console.error('combined search', error);
                    console.log({ err: true, message: 'Request failed: ' + error.textStatus });
                }
            });
        }
        else {
            this.$resultsContainer.classList.remove('m-search__panel__results--active');
        }
    };
    Search.prototype.init = function () {
        var _this = this;
        this.$searchCloseMobile.addEventListener('click', function (event) { return _this.closeSearch(event); });
        this.$searchCloseDesktop.addEventListener('click', function (event) { return _this.closeSearch(event); });
        window.addEventListener('orientationchange', function () { return _this.clearSearch(); });
        document.addEventListener('click', function (event) {
            var _a;
            var element = event.target;
            // checking first if the search is active at all before the next checks
            if (_this.$search.classList.contains('m-search--active') && !_this.$search.contains(element) && !_this.$searchContainer.contains(element)
                && !((_a = _this.$agentSearchBtn) === null || _a === void 0 ? void 0 : _a.contains(element))) {
                _this.clearSearch();
            }
        });
        this.$searchBtn.addEventListener('click', function () { return _this.activeSearch(); });
        this.$searchIcon.addEventListener('click', function () { return _this.activeSearch(); });
        this.$searchInputMobile.addEventListener('keyup', function (event) { return _this.searchText(event); });
        this.$searchInputDesktop.addEventListener('keyup', function (event) { return _this.searchText(event); });
        this.$searchInputDesktop.addEventListener('keydown', function (event) { return _this.switchPage(event); });
        this.$searchInputMobile.addEventListener('keydown', function (event) { return _this.switchPage(event); });
    };
    return Search;
}());
export { Search };
