var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CountryData } from './country-data';
import { LanguageData } from './data/language-data';
import { LanguageSelector } from "./language-selector";
import axios from 'axios';
var LanguageSelectorModalContent = /** @class */ (function (_super) {
    __extends(LanguageSelectorModalContent, _super);
    function LanguageSelectorModalContent(countrySelector) {
        var _this = _super.call(this, countrySelector) || this;
        _this.initSelector();
        return _this;
    }
    LanguageSelectorModalContent.prototype.retrieveServerData = function () {
        var _this = this;
        var result = [];
        var requestUrl = '/websiteMapping/results';
        axios.get(requestUrl)
            .then(function (res) {
            res.data.forEach(function (country) {
                var languagesArray = [];
                country.languages.forEach(function (language) {
                    languagesArray.push(new LanguageData(language.name, language.englishName, language.url, language.defaultLanguage, ''));
                });
                result.push(new CountryData(country.name, country.englishName, languagesArray, ''));
            });
            _this.updateSelector(result);
        })
            .catch(function (error) {
            console.error(error.jqXHR);
            console.log({ err: true, message: 'Request failed: ' + error.textStatus });
        });
    };
    LanguageSelectorModalContent.prototype.updateSelector = function (countryData) {
        var _this = this;
        this.countryDataList = countryData;
        _super.prototype.prepareCountrySelector.call(this, countryData);
        this.countryArray.forEach(function (item) {
            item.addEventListener('click', function () {
                _this.setupLanguageSelector(item);
                _super.prototype.closeCountryDropdown.call(_this);
            });
        });
    };
    LanguageSelectorModalContent.prototype.setupLanguageSelector = function (item) {
        var country = item.textContent;
        this.$countryField.value = country;
        var countryData = this.countryDataList.find(function (countryData) {
            var name = countryData.name;
            if (countryData.englishName !== undefined) {
                name += " - ".concat(countryData.englishName);
            }
            return name === country;
        });
        _super.prototype.prepareLanguageSelector.call(this, countryData.languages, this.$submitBtn, 'href');
        _super.prototype.enableGoButton.call(this);
    };
    LanguageSelectorModalContent.prototype.initSelector = function () {
        var _this = this;
        this.retrieveServerData();
        this.$languageSelectInputHidden.addEventListener('customSelectValueChanged', function () {
            _this.$submitBtn.setAttribute('href', _super.prototype.findLink.call(_this, _this.$countryField.value, _this.$languageSelectInputHidden.value));
        });
    };
    return LanguageSelectorModalContent;
}(LanguageSelector));
export { LanguageSelectorModalContent };
