var AddMultipleFavoritesRequest = /** @class */ (function () {
    function AddMultipleFavoritesRequest() {
    }
    return AddMultipleFavoritesRequest;
}());
export { AddMultipleFavoritesRequest };
var AddToFavoritesProduct = /** @class */ (function () {
    function AddToFavoritesProduct() {
    }
    return AddToFavoritesProduct;
}());
export { AddToFavoritesProduct };
