import axios from 'axios';
import alertObject from "../notification/global-alerts";
import { CartItemsIndicator } from "../cart-items-indicator/cart-items-indicator";
import { AlertFactory } from "../notification/alert-factory";
import { CartAlert } from "../notification/cart-alert";
var OrderDetails = /** @class */ (function () {
    function OrderDetails() {
        this.summary = 0;
        this.addAllProductsEndpoint = '/my-profile/order/addtocart';
        this.addProductEndpoint = '/cart/add?productCodePost=';
        this.alertFactoryObject = new AlertFactory();
        this.$orderDetailsItemsSection = document.querySelector('.m-orderDetails');
        this.$numberCounterBtn = document.querySelector('.m-reorderProducts__addBtn__text__counter');
        this.$minusBtnArray = this.$orderDetailsItemsSection.querySelectorAll('.a-counter__minus');
        this.$plusBtnArray = this.$orderDetailsItemsSection.querySelectorAll('.a-counter__plus');
        this.$inputArray = this.$orderDetailsItemsSection.querySelectorAll('.a-counter__input');
        this.$addAllBtn = document.querySelector('.m-orderDetails__items__addAllbtn');
        this.$addToCartArr = document.querySelectorAll('.m-orderDetails__items__addSinglebtn');
        this.init();
    }
    OrderDetails.prototype.init = function () {
        var _this = this;
        this.$inputArray.forEach(function (actionInput) {
            actionInput.addEventListener('blur', function (event) { return _this.updateProductQuantityByInput(event); });
            _this.summary += parseInt(actionInput.value);
        });
        this.$numberCounterBtn.innerHTML = this.summary.toString();
        this.$plusBtnArray.forEach(function (actionBtn) {
            actionBtn.addEventListener('click', function (event) { return _this.updateProductQuantityByBtn(event, true); });
        });
        this.$minusBtnArray.forEach(function (actionBtn) {
            actionBtn.addEventListener('click', function (event) { return _this.updateProductQuantityByBtn(event); });
        });
        this.$addAllBtn.addEventListener('click', function (event) { return _this.addAllProducts(event); });
        this.$addToCartArr.forEach(function (actionBtn) {
            actionBtn.addEventListener('click', function (event) { return _this.addProductToCart(event); });
        });
    };
    OrderDetails.prototype.updateProductQuantityByBtn = function (event, addition) {
        var $actionBtn = event.target;
        var number = addition ? 1 : -1;
        this.summary += number;
        this.$numberCounterBtn.innerHTML = this.summary.toString();
        var actionInput = $actionBtn.parentElement.querySelector('.a-counter__input');
        actionInput.setAttribute('old-value', actionInput.value);
    };
    OrderDetails.prototype.updateProductQuantityByInput = function (event) {
        var $element = event.target;
        var actionInputOldVal = parseInt($element.getAttribute('old-value'));
        if (isNaN(actionInputOldVal)) {
            actionInputOldVal = parseInt($element.getAttribute('data-default-qty')) || 0;
        }
        this.summary += parseInt($element.value) - actionInputOldVal;
        this.$numberCounterBtn.innerHTML = this.summary.toString();
        $element.setAttribute('old-value', $element.value);
    };
    OrderDetails.prototype.addProductToCart = function (event) {
        event.preventDefault();
        var $element = event.target;
        var productCode = $element.getAttribute('product-code');
        var productRow = document.getElementById(productCode);
        this.addProduct(productRow);
    };
    OrderDetails.prototype.addProduct = function (element) {
        var quantity = element.querySelector('.a-counter').querySelector('input').value;
        var code = element.querySelector('.a-counter').querySelector('input').getAttribute('data-code');
        axios.post(this.addProductEndpoint + code + '&qty=' + parseInt(quantity)).then(function (res) {
            var alertContainer = document.querySelector('.o-global-alerts');
            alertContainer.innerHTML = res.data;
            new CartAlert(document.querySelector('.m-alert--addToCart'));
            new CartItemsIndicator();
        });
    };
    OrderDetails.prototype.addAllProducts = function (event) {
        event.preventDefault();
        var productRows = document.querySelectorAll('.m-orderDetails__items__row');
        this.createFormData(productRows);
    };
    OrderDetails.prototype.createFormData = function (productRows) {
        var _this = this;
        var orderId = this.$orderDetailsItemsSection.getAttribute('order-code');
        var data = { uid: orderId, products: [] };
        productRows.forEach(function (element) {
            var productAvailable = element.querySelector('.m-orderDetails__items__row__value--counter').getAttribute('data-availability') === 'AVAILABLE';
            if (productAvailable) {
                var quantity = element.querySelector('.a-counter').querySelector('input').value;
                var code = element.querySelector('.a-counter').querySelector('input').getAttribute('data-code');
                var dealerInput = element.querySelector('.a-customSelect--dealer .a-customSelect__field__input--hidden');
                var dealerCode = dealerInput !== null ? dealerInput.value : null;
                var item = {
                    product: code,
                    quantity: parseInt(quantity),
                    dealer: dealerCode
                };
                data.products.push(item);
            }
        });
        var alertType;
        var alertTitle;
        var alertMessage;
        var alertAdditionalClass;
        axios.post(this.addAllProductsEndpoint, data)
            .then(function (res) {
            alertType = 'success';
            alertTitle = '';
            alertMessage = res.data.message;
            alertAdditionalClass = {
                'success': true,
                'disposable': true,
                'closable': true
            };
        })
            .catch(function (error) {
            alertType = 'error';
            alertTitle = error.response.data.title;
            alertMessage = error.response.data.message;
            alertAdditionalClass = {
                'error': true,
                'disposable': true,
                'closable': true
            };
        })
            .then(function () {
            document.querySelector('.m-favoritesList') !== null && document.querySelector('.m-favoritesList')
                .querySelector('.m-favoritesList__loader')
                .classList.remove('a-loader--active');
            var alert = _this.alertFactoryObject.createAlert(alertMessage, alertTitle, alertType, alertAdditionalClass);
            alertObject.showAlert(alert);
            new CartItemsIndicator();
        });
    };
    return OrderDetails;
}());
export { OrderDetails };
