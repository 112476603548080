import * as app from '../../main';
import { ScrollPageHandler } from '../scroll-page';
var IndirectCheckout = /** @class */ (function () {
    function IndirectCheckout() {
        this.SendToDealerTypes = {
            DEALER_FROM_FAVORITES: 'DEALER_FROM_FAVORITES',
            OTHER: 'OTHER',
        };
        this.$pageIndirectCheckout = document.querySelector('.o-indirectCheckout');
        this.onlineDealer = 'true' == this.$pageIndirectCheckout.getAttribute('data-online-dealer');
        this.$checkoutBtns = document.querySelectorAll('.a-greenBlueBtn');
        this.$form = document.querySelector('#indirectCheckoutForm');
        this.showDealerSection = 'true' === this.$pageIndirectCheckout.getAttribute('data-dealer-section');
        this.$dealerRadioButtons = this.$pageIndirectCheckout.querySelectorAll('input[type=radio][name=sendToDealerType]');
        this.$dealerCodeElement = this.$pageIndirectCheckout.querySelector('.o-indirectCheckout__dealer__code');
        this.$dealerCodeSelect = this.$pageIndirectCheckout.querySelector('.o-indirectCheckout__dealer__code .a-customSelect');
        this.$dealerCodeInput = this.$pageIndirectCheckout.querySelector('.o-indirectCheckout__dealer__code .a-customSelect__field__input--hidden');
        this.$dealerEmailElement = this.$pageIndirectCheckout.querySelector('.o-indirectCheckout__dealer__email');
        this.$dealerEmailInput = this.$pageIndirectCheckout.querySelector('.o-indirectCheckout__dealer__email input');
        this.$dealerAgreementInput = this.$pageIndirectCheckout.querySelector('.o-indirectCheckout__dealer__agreement input');
        this.init();
    }
    IndirectCheckout.prototype.submitFormOnClickWhenPossible = function () {
        if (this.$form.checkValidity()) {
            this.$form.submit();
        }
    };
    IndirectCheckout.prototype.submitFormWithDealer = function (event) {
        var $button = event.currentTarget;
        var targetModal = $button.getAttribute('target-modal');
        var dealerModal = document.querySelector(".m-modal[id=\"".concat(targetModal, "\"]"));
        event.preventDefault();
        app.default.getValidation(this.$form.id).validateFields();
        if (!this.$form.checkValidity()) {
            this.scrollToDealerSection();
            return;
        }
        if (this.onlineDealer) {
            var modalArr = app.default.getModalArr();
            var modalObj = modalArr.find(function (modal) { return modal.element == dealerModal; });
            modalObj.obj.showModal();
        }
        else {
            this.$form.submit();
        }
    };
    IndirectCheckout.prototype.toggleDealerOption = function (event) {
        var _this = this;
        var option = event.currentTarget;
        if (option.value === this.SendToDealerTypes.DEALER_FROM_FAVORITES) {
            this.$dealerCodeElement.classList.remove('o-indirectCheckout__dealer__code--hidden');
            this.$dealerCodeInput.required = true;
            this.$dealerEmailElement.classList.add('o-indirectCheckout__dealer__email--hidden');
            this.$dealerEmailInput.required = false;
            this.$dealerEmailInput.value = '';
            var fieldToValidate = this.$dealerEmailElement.querySelector('.a-input');
            app.default.getValidation(this.$form.id).initValidationResult(fieldToValidate, 'a-input');
        }
        else if (option.value === this.SendToDealerTypes.OTHER) {
            this.$dealerCodeElement.classList.add('o-indirectCheckout__dealer__code--hidden');
            this.$dealerCodeInput.required = false;
            this.$dealerEmailElement.classList.remove('o-indirectCheckout__dealer__email--hidden');
            this.$dealerEmailInput.required = true;
            var selectArr = app.default.getSelectArr();
            var selectObj = selectArr.find(function (select) { return select.element == _this.$dealerCodeSelect; });
            selectObj.obj.unselectOption();
            var fieldToValidate = this.$dealerCodeSelect.querySelector('.a-input');
            app.default.getValidation(this.$form.id).initValidationResult(fieldToValidate, 'a-input');
        }
        this.updateSubmitButton();
    };
    IndirectCheckout.prototype.updateSubmitButton = function () {
        if (this.$form.checkValidity()) {
            this.$checkoutBtns.forEach(function (btn) { return btn.classList.remove('a-greenBlueBtn--clickableDisabled'); });
        }
        else {
            this.$checkoutBtns.forEach(function (btn) { return btn.classList.add('a-greenBlueBtn--clickableDisabled'); });
        }
    };
    IndirectCheckout.prototype.scrollToDealerSection = function () {
        var scrollPageObject = new ScrollPageHandler();
        scrollPageObject.scrollPage('.o-indirectCheckout__dealer');
    };
    IndirectCheckout.prototype.init = function () {
        var _this = this;
        if (this.showDealerSection) {
            this.$dealerCodeInput.addEventListener('customSelectValueChanged', function () { return _this.updateSubmitButton(); });
            this.$dealerEmailInput.addEventListener('blur', function () { return _this.updateSubmitButton(); });
            this.$dealerAgreementInput.addEventListener('change', function () { return _this.updateSubmitButton(); });
            this.$checkoutBtns.forEach(function (button) {
                button.addEventListener('click', function (event) { return _this.submitFormWithDealer(event); });
            });
        }
        if (!this.onlineDealer) {
            this.$checkoutBtns.forEach(function (button) {
                button.addEventListener('click', function () { return _this.submitFormOnClickWhenPossible(); });
            });
        }
        this.$dealerRadioButtons.forEach(function (option) {
            option.addEventListener('click', function (event) { return _this.toggleDealerOption(event); });
        });
    };
    return IndirectCheckout;
}());
export { IndirectCheckout };
