var CheckoutIndicator = /** @class */ (function () {
    function CheckoutIndicator() {
        var _a, _b, _c, _d, _e, _f;
        this.$firstCheckoutCircleIndicator = (_a = document.querySelector('.m-checkoutIndicator a:nth-child(1)')) === null || _a === void 0 ? void 0 : _a.querySelector('.m-checkoutIndicator__panel__box__circle');
        this.$secondCheckoutCircleIndicator = (_b = document.querySelector('.m-checkoutIndicator a:nth-child(2)')) === null || _b === void 0 ? void 0 : _b.querySelector('.m-checkoutIndicator__panel__box__circle');
        this.$thirdCheckoutCircleIndicator = (_c = document.querySelector('.m-checkoutIndicator a:nth-child(3)')) === null || _c === void 0 ? void 0 : _c.querySelector('.m-checkoutIndicator__panel__box__circle');
        this.$firstCheckoutName = (_d = document.querySelector('.m-checkoutIndicator a:nth-child(1)')) === null || _d === void 0 ? void 0 : _d.querySelector('.m-checkoutIndicator__panel__box__name');
        this.$secondCheckoutName = (_e = document.querySelector('.m-checkoutIndicator a:nth-child(2)')) === null || _e === void 0 ? void 0 : _e.querySelector('.m-checkoutIndicator__panel__box__name');
        this.$thirdCheckoutName = (_f = document.querySelector('.m-checkoutIndicator a:nth-child(3)')) === null || _f === void 0 ? void 0 : _f.querySelector('.m-checkoutIndicator__panel__box__name');
        this.init();
    }
    CheckoutIndicator.prototype.changeElementStyles = function (eventType, circle, name) {
        if (circle.closest('.m-checkoutIndicator__panel').classList.contains('m-checkoutIndicator__panel--active')) {
            eventType === 'mouseover' ?
                circle.classList.add("m-checkoutIndicator__panel__box__circle--hovered") :
                circle.classList.remove("m-checkoutIndicator__panel__box__circle--hovered");
            eventType === 'mouseover' ?
                name.classList.add("m-checkoutIndicator__panel__box__name--hovered") :
                name.classList.remove("m-checkoutIndicator__panel__box__name--hovered");
        }
    };
    CheckoutIndicator.prototype.init = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        //circle listeners
        (_a = this.$firstCheckoutCircleIndicator) === null || _a === void 0 ? void 0 : _a.addEventListener('mouseover', function () {
            return _this.changeElementStyles('mouseover', _this.$firstCheckoutCircleIndicator, _this.$firstCheckoutName);
        });
        (_b = this.$firstCheckoutCircleIndicator) === null || _b === void 0 ? void 0 : _b.addEventListener('mouseleave', function () {
            return _this.changeElementStyles('mouseleave', _this.$firstCheckoutCircleIndicator, _this.$firstCheckoutName);
        });
        (_c = this.$secondCheckoutCircleIndicator) === null || _c === void 0 ? void 0 : _c.addEventListener('mouseover', function () {
            return _this.changeElementStyles('mouseover', _this.$secondCheckoutCircleIndicator, _this.$secondCheckoutName);
        });
        (_d = this.$secondCheckoutCircleIndicator) === null || _d === void 0 ? void 0 : _d.addEventListener('mouseleave', function () {
            return _this.changeElementStyles('mouseleave', _this.$secondCheckoutCircleIndicator, _this.$secondCheckoutName);
        });
        (_e = this.$thirdCheckoutCircleIndicator) === null || _e === void 0 ? void 0 : _e.addEventListener('mouseover', function () {
            return _this.changeElementStyles('mouseover', _this.$thirdCheckoutCircleIndicator, _this.$thirdCheckoutName);
        });
        (_f = this.$thirdCheckoutCircleIndicator) === null || _f === void 0 ? void 0 : _f.addEventListener('mouseleave', function () {
            return _this.changeElementStyles('mouseleave', _this.$thirdCheckoutCircleIndicator, _this.$thirdCheckoutName);
        });
        //span name listeners
        (_g = this.$firstCheckoutName) === null || _g === void 0 ? void 0 : _g.addEventListener('mouseover', function () {
            return _this.changeElementStyles('mouseover', _this.$firstCheckoutCircleIndicator, _this.$firstCheckoutName);
        });
        (_h = this.$firstCheckoutName) === null || _h === void 0 ? void 0 : _h.addEventListener('mouseleave', function () {
            return _this.changeElementStyles('mouseleave', _this.$firstCheckoutCircleIndicator, _this.$firstCheckoutName);
        });
        (_j = this.$secondCheckoutName) === null || _j === void 0 ? void 0 : _j.addEventListener('mouseover', function () {
            return _this.changeElementStyles('mouseover', _this.$secondCheckoutCircleIndicator, _this.$secondCheckoutName);
        });
        (_k = this.$secondCheckoutName) === null || _k === void 0 ? void 0 : _k.addEventListener('mouseleave', function () {
            return _this.changeElementStyles('mouseleave', _this.$secondCheckoutCircleIndicator, _this.$secondCheckoutName);
        });
        (_l = this.$thirdCheckoutName) === null || _l === void 0 ? void 0 : _l.addEventListener('mouseover', function () {
            return _this.changeElementStyles('mouseover', _this.$thirdCheckoutCircleIndicator, _this.$thirdCheckoutName);
        });
        (_m = this.$thirdCheckoutName) === null || _m === void 0 ? void 0 : _m.addEventListener('mouseleave', function () {
            return _this.changeElementStyles('mouseleave', _this.$thirdCheckoutCircleIndicator, _this.$thirdCheckoutName);
        });
    };
    return CheckoutIndicator;
}());
export { CheckoutIndicator };
