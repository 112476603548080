var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Counter } from "./counter";
var CartCounter = /** @class */ (function (_super) {
    __extends(CartCounter, _super);
    function CartCounter(counter) {
        var _this = _super.call(this, counter) || this;
        _this.submitHandler = null;
        _this.initCartCounter();
        return _this;
    }
    CartCounter.prototype.subtraction = function (stockCounterMinus) {
        // do nothing
    };
    CartCounter.prototype.addition = function (stockCounterPlus) {
        // do nothing
    };
    CartCounter.prototype.updateQuantity = function (val) {
        if (val === 1 || val === -1) {
            this.productRequestQuantity = parseInt(this.$stockCounterInput.value) + val;
            this.$stockCounterInput.value = this.productRequestQuantity.toString();
        }
        else {
            this.productRequestQuantity = parseInt(this.$stockCounterInput.value);
        }
        this.setButtonsAvailable();
    };
    CartCounter.prototype.scheduleFormSubmit = function () {
        if (this.submitHandler != null) {
            this.clearScheduledSubmit();
        }
        var form = this.$stockCounterInput.form;
        this.submitHandler = window.setTimeout(function () {
            form.submit();
        }, 1000);
    };
    CartCounter.prototype.clearScheduledSubmit = function () {
        window.clearTimeout(this.submitHandler);
    };
    CartCounter.prototype.initCartCounter = function () {
        var _this = this;
        this.$stockCounterInput.addEventListener('input', function (event) { return _this.scheduleFormSubmit(); });
        this.$stockCounterMinus.addEventListener('click', function (event) { return _this.scheduleFormSubmit(); });
        this.$stockCounterPlus.addEventListener('click', function (event) { return _this.scheduleFormSubmit(); });
    };
    return CartCounter;
}(Counter));
export { CartCounter };
