var SearchCategories = /** @class */ (function () {
    function SearchCategories() {
        this.$searchCategories = document.querySelector('.m-searchCategories');
        this.$activeCategory = document.querySelector('.m-searchCategories__activeCategory');
        this.$categoryList = this.$searchCategories.querySelector('.m-searchCategories__list');
        this.init();
    }
    SearchCategories.prototype.openDropdown = function () {
        this.$categoryList.classList.remove('m-searchCategories__list--hidden');
    };
    SearchCategories.prototype.closeDropdown = function () {
        this.$categoryList.classList.add('m-searchCategories__list--hidden');
    };
    SearchCategories.prototype.init = function () {
        var _this = this;
        this.$activeCategory.addEventListener('click', function () { return _this.openDropdown(); });
        document.addEventListener('click', function (event) {
            var element = event.target;
            if (!_this.$searchCategories.contains(element)) {
                _this.closeDropdown();
            }
        });
    };
    return SearchCategories;
}());
export { SearchCategories };
