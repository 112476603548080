import { ScrollPageHandler } from '../scroll-page';
var VariantsSection = /** @class */ (function () {
    function VariantsSection() {
        this.summary = 0;
        this.$variantsSection = document.querySelector('.m-pdpVariantSection');
        this.$numberCounterBtn = this.$variantsSection.querySelector('.m-pdpVariantSection__content__addBtn__text__counter');
        this.$minusBtnArray = this.$variantsSection.querySelectorAll('.a-counter__minus');
        this.$plusBtnArray = this.$variantsSection.querySelectorAll('.a-counter__plus');
        this.$inputArray = this.$variantsSection.querySelectorAll('.a-counter__input');
        this.$variantsSectionBtn = document.querySelector('.m-pdpAdditionalData__allVariantsBtn');
        this.$addToFavArr = this.$variantsSection.querySelectorAll('.m-pdpVariantSection__content__variantsList__item__favouriteIcon a');
        this.$dealerSelect = document.querySelector('.a-customSelect--dealer .a-customSelect__field__input--hidden');
        this.$addBtn = document.querySelector('.m-pdpVariantSection__content__addBtn');
        this.init();
    }
    VariantsSection.prototype.updateVariantNumberByBtn = function (event, addition) {
        var $actionBtn = event.target;
        var number = addition ? 1 : -1;
        this.summary += number;
        this.$numberCounterBtn.innerHTML = this.summary.toString();
        var actionInput = $actionBtn.parentElement.querySelector('.a-counter__input');
        actionInput.setAttribute('old-value', actionInput.value);
    };
    VariantsSection.prototype.updateVariantNumberByInput = function (event) {
        var $element = event.target;
        var actionInputOldVal = parseInt($element.getAttribute('old-value')) || 0;
        this.summary += parseInt($element.value) - actionInputOldVal;
        this.$numberCounterBtn.innerHTML = this.summary.toString();
        $element.setAttribute('old-value', $element.value);
    };
    VariantsSection.prototype.updateVariantNumberFavoritesPopup = function (event) {
        var $fav = event.target;
        var code = $fav.getAttribute('data-code');
        var $modal = document.querySelector('#addToFavorites');
        var $productInput = $modal.querySelector('input[name=productCode]');
        $productInput.value = $fav.getAttribute('data-variant');
    };
    VariantsSection.prototype.getDealer = function () {
        if (this.$dealerSelect) {
            var dealerCode = this.$dealerSelect.value;
            var dealerField = document.querySelector('.m-pdpVariantSection__dealer');
            dealerField.value = dealerCode;
        }
    };
    VariantsSection.prototype.init = function () {
        var _this = this;
        this.$inputArray.forEach(function (actionInput) {
            actionInput.addEventListener('blur', function (event) { return _this.updateVariantNumberByInput(event); });
        });
        this.$plusBtnArray.forEach(function (actionBtn) {
            actionBtn.addEventListener('click', function (event) { return _this.updateVariantNumberByBtn(event, true); });
        });
        this.$minusBtnArray.forEach(function (actionBtn) {
            actionBtn.addEventListener('click', function (event) { return _this.updateVariantNumberByBtn(event); });
        });
        this.$variantsSectionBtn.addEventListener('click', function () {
            var scrollPageObject = new ScrollPageHandler();
            scrollPageObject.scrollPage('#onevariant-form');
        });
        this.$addToFavArr.forEach(function (fav) {
            fav.addEventListener('click', function (event) { return _this.updateVariantNumberFavoritesPopup(event); });
        });
        this.$addBtn && this.$addBtn.addEventListener('click', function () { return _this.getDealer(); });
    };
    return VariantsSection;
}());
export { VariantsSection };
