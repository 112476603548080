import axios from 'axios';
import * as app from '../../main';
import alertObject from "../notification/global-alerts";
import { AlertFactory } from "../notification/alert-factory";
import { CartItemsIndicator } from "../cart-items-indicator/cart-items-indicator";
var ListOfProducts = /** @class */ (function () {
    function ListOfProducts() {
        this.alertFactoryObject = new AlertFactory();
    }
    ListOfProducts.prototype.getPricesOfProducts = function (endpoint, listId) {
        var requestUrl = endpoint.concat(listId);
        var $productTilesOnList = this.page.querySelectorAll("[data-list-id=\"".concat(listId, "\"] .m-productTile"));
        axios.get(requestUrl)
            .then(function (res) {
            var productsArr = res.data;
            var _loop_1 = function (productCode) {
                var priceVal = productsArr[productCode].value;
                var priceValFormatted = productsArr[productCode].formattedValue;
                var $tile = Array.from($productTilesOnList).find(function (tile) { return tile.querySelector('form').getAttribute('data-code') === productCode; });
                var $price = $tile.querySelector('.m-productOrderPossibility__addBtn__text');
                var $priceText = $price.querySelector('.m-productOrderPossibility__addBtn__text__value');
                $price.setAttribute('data-price', priceVal);
                $priceText.innerHTML = priceValFormatted;
            };
            for (var productCode in productsArr) {
                _loop_1(productCode);
            }
        })
            .then(function () {
            $productTilesOnList.forEach(function (tile) {
                var $orderPossibility = tile.querySelector('.m-productOrderPossibility');
                $orderPossibility.classList.remove('m-productOrderPossibility--waiting');
            });
        });
    };
    ListOfProducts.prototype.getPricesOfProductsOnList = function (endpoint, ev) {
        var listId = this.getListId(ev);
        this.getPricesOfProducts(endpoint, listId);
    };
    ListOfProducts.prototype.createFormData = function (requestUrl, listId, productTiles) {
        var _this = this;
        var data = { uid: listId, products: [] };
        productTiles.forEach(function (element) {
            var quantity = element.querySelector('.a-counter').querySelector('input').value;
            var code = element.querySelector('.a-counter').querySelector('input').getAttribute('data-code');
            var dealerInput = element.querySelector('.a-customSelect--dealer .a-customSelect__field__input--hidden');
            var productAvailable = element.querySelector('.m-productOrderPossibility__contactLink--active') === null
                && element.querySelector('.m-productOrderPossibility__addBtn__text').getAttribute('data-availability') === 'AVAILABLE';
            var dealerCode = dealerInput !== null ? dealerInput.value : '';
            if (productAvailable) {
                var item = {
                    product: code,
                    quantity: parseInt(quantity),
                    dealer: dealerCode
                };
                data.products.push(item);
            }
        });
        var alertType;
        var alertTitle;
        var alertMessage;
        var alertAdditionalClass;
        axios.post(requestUrl, data)
            .then(function (res) {
            alertType = 'success';
            alertTitle = '';
            alertMessage = res.data.message;
            alertAdditionalClass = {
                'success': true,
                'disposable': true,
                'closable': true
            };
        })
            .catch(function (error) {
            alertType = 'error';
            alertTitle = error.response.data.title;
            alertMessage = error.response.data.message;
            alertAdditionalClass = {
                'error': true,
                'disposable': true,
                'closable': true
            };
        })
            .then(function () {
            document.querySelector('.m-favoritesList') !== null && document.querySelector('.m-favoritesList')
                .querySelector('.m-favoritesList__loader')
                .classList.remove('a-loader--active');
            var alert = _this.alertFactoryObject.createAlert(alertMessage, alertTitle, alertType, alertAdditionalClass);
            alertObject.showAlert(alert);
            new CartItemsIndicator();
        });
    };
    ListOfProducts.prototype.addAll = function (url) {
        var mainItem = app.default.getSnippets().closest(event.currentTarget, '.m-accordion');
        var listId = mainItem.getAttribute('data-list-id');
        var productTiles = mainItem.querySelectorAll('.m-productTile');
        this.createFormData(url, listId, productTiles);
    };
    ListOfProducts.prototype.getListId = function (ev) {
        var accordion = app.default.getSnippets().closest(ev.target, '.m-accordion');
        var listId = accordion.getAttribute('data-list-id');
        return listId;
    };
    ListOfProducts.prototype.setListIdInModal = function (ev, input) {
        input.value = this.getListId(ev);
    };
    ListOfProducts.prototype.removeProductFromList = function (event, elName) {
        event.preventDefault();
        var eventNode = event.target;
        var productCode = eventNode.getAttribute('data-product-code');
        var mainItem = app.default.getSnippets().closest(event.currentTarget, ".".concat(elName));
        var productForm = mainItem.querySelector("form[form-product-code=\"".concat(productCode, "\"]"));
        productForm.submit();
    };
    return ListOfProducts;
}());
export { ListOfProducts };
