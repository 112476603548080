var Modal = /** @class */ (function () {
    function Modal(modal) {
        this.showModeTypes = {
            ON_LOAD: 'ON_LOAD',
            NOT_AUTO: 'NOT_AUTO'
        };
        this.modalClasses = {
            ACTIVE: 'm-modal--active',
            COVERED: 'm-modal--covered'
        };
        this.modal = modal;
        this.modalId = modal.getAttribute('id');
        this.$modalTriggerArr = document.querySelectorAll("[target-modal=\"".concat(this.modalId, "\"]"));
        this.showMode = this.modal.getAttribute('data-show-mode');
        this.noClose = 'true' === this.modal.getAttribute('data-prevent-close');
        this.modalExit = this.modal.querySelector('.m-modal__content__close');
        this.modalCancel = this.modal.querySelector('.m-modal__content__actions__btn--cancel');
        this.modalBack = this.modal.querySelector('.m-modal__content__actions__backBtn');
        this.init();
    }
    Modal.prototype.publishEvent = function (type) {
        var event = document.createEvent('HTMLEvents');
        event.initEvent(type, true, true);
        this.modal.dispatchEvent(event);
    };
    Modal.prototype.showModal = function () {
        this.$onloadModal = document.querySelector(".m-modal--active[data-show-mode=\"".concat(this.showModeTypes.ON_LOAD, "\"]"));
        if (this.$onloadModal) {
            this.modal.classList.toggle(this.modalClasses.COVERED);
        }
        this.modal.classList.add(this.modalClasses.ACTIVE);
        this.publishEvent('show');
    };
    Modal.prototype.closeModal = function () {
        if (this.modal.classList.contains(this.modalClasses.ACTIVE)) {
            this.modal.classList.remove(this.modalClasses.ACTIVE);
            if (this.showMode === this.showModeTypes.ON_LOAD) {
                var secondModal = document.querySelector(".".concat(this.modalClasses.COVERED));
                secondModal === null || secondModal === void 0 ? void 0 : secondModal.classList.remove(this.modalClasses.COVERED);
            }
            this.publishEvent('close');
        }
    };
    Modal.prototype.init = function () {
        var _this = this;
        var _a, _b, _c;
        if (this.showMode === this.showModeTypes.ON_LOAD) {
            this.showModal();
        }
        if (this.$modalTriggerArr.length && this.showMode !== this.showModeTypes.NOT_AUTO) {
            this.$modalTriggerArr.forEach(function (modalTrigger) {
                return modalTrigger.addEventListener('click', function (event) {
                    event.stopPropagation();
                    _this.showModal();
                });
            });
        }
        (_a = this.modalExit) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () { return _this.closeModal(); });
        (_b = this.modalCancel) === null || _b === void 0 ? void 0 : _b.addEventListener('click', function () { return _this.closeModal(); });
        (_c = this.modalBack) === null || _c === void 0 ? void 0 : _c.addEventListener('click', function () { return _this.closeModal(); });
        document.addEventListener('click', function (event) {
            if (event.target == _this.modal && !_this.noClose) {
                _this.closeModal();
            }
        });
    };
    return Modal;
}());
export { Modal };
