var HeroBrands = /** @class */ (function () {
    function HeroBrands() {
        this.sliderHiddenClass = 'm-heroBrands__list__slider--hidden';
        this.$panel = document.querySelector('.m-heroBrands');
        this.$panelTrigger = this.$panel.querySelector('.m-heroBrands__trigger');
        this.$triggerIcon = this.$panelTrigger.querySelector('.m-heroBrands__trigger__icon');
        this.$valuesPanel = this.$panel.querySelector('.m-heroBrands__list');
        this.$selectedValue = this.$valuesPanel.querySelector('.m-heroBrands__list__container__value--selected');
        this.$slider = this.$valuesPanel.querySelector('.m-heroBrands__list__slider');
        this.$values = this.$valuesPanel.querySelectorAll('.m-heroBrands__list__container__value');
        this.lastHovered = 0;
        this.init();
    }
    HeroBrands.prototype.sliderNewPosition = function (element) {
        var rect = element.getBoundingClientRect();
        var left = rect.left;
        var width = rect.width;
        this.$slider.setAttribute('style', "left: ".concat(left, "px; width: ").concat(width, "px"));
    };
    HeroBrands.prototype.initializeSliderPosition = function () {
        if (this.$selectedValue != null) {
            this.$slider.classList.remove(this.sliderHiddenClass);
            this.sliderNewPosition(this.$selectedValue);
        }
        else {
            this.$slider.classList.add(this.sliderHiddenClass);
            this.sliderNewPosition(this.$values[this.lastHovered]);
        }
    };
    HeroBrands.prototype.toggleList = function () {
        this.$triggerIcon.classList.toggle('icon-chevron-down');
        this.$triggerIcon.classList.toggle('icon-chevron-up');
        this.$valuesPanel.classList.toggle('m-heroBrands__list--expanded');
    };
    HeroBrands.prototype.selectOption = function (event) {
        event.preventDefault();
        window.location.href = window.location.href.split('?')[0];
    };
    HeroBrands.prototype.onOptionHover = function (element, index) {
        this.lastHovered = index;
        this.$slider.classList.remove(this.sliderHiddenClass);
        this.sliderNewPosition(element);
    };
    HeroBrands.prototype.init = function () {
        var _this = this;
        this.$panelTrigger.addEventListener('click', function () { return _this.toggleList(); });
        this.$values.forEach(function (element, index) {
            element.addEventListener('mouseenter', function () { return _this.onOptionHover(element, index); });
            element.addEventListener('mouseleave', function () { return _this.initializeSliderPosition(); });
        });
        window.addEventListener('resize', function () { return _this.initializeSliderPosition(); });
        if (this.$selectedValue != null) {
            this.$selectedValue.addEventListener('click', function (event) { return _this.selectOption(event); });
        }
        this.initializeSliderPosition();
    };
    return HeroBrands;
}());
export { HeroBrands };
