import { Accordion } from "../accordion";
import * as app from "../../main";
var TabPanel = /** @class */ (function () {
    function TabPanel(panel) {
        this.activeTabClass = 'm-tabPanel__content__tab--active';
        this.activeHeaderClass = 'm-tabPanel__header__content__tabs__name--active';
        this.isDown = false;
        this.startX = 0;
        this.scrollLeft = 0;
        this.$panel = panel;
        this.$panelHeaderTabs = this.$panel.querySelector('.m-tabPanel__header__content__tabs');
        this.$panelHeaderContent = this.$panel.querySelector('.m-tabPanel__header__content');
        this.$headerSlider = this.$panelHeaderContent.querySelector('.m-tabPanel__header__content__tabs__slider');
        this.$panelContent = this.$panel.querySelector('.m-tabPanel__content');
        this.$tabHeaders = Array.prototype.slice.call(this.$panelHeaderContent.querySelectorAll('.m-tabPanel__header__content__tabs__name'));
        this.$tabContents = Array.prototype.slice.call(this.$panelContent.querySelectorAll('.m-tabPanel__content__tab'));
        this.init();
    }
    TabPanel.prototype.switchTab = function (header) {
        if (header.tagName !== 'A') {
            var tabToActiveName = header.getAttribute('data-tab');
            var $activeHeader = this.$panelHeaderTabs.querySelector(".".concat(this.activeHeaderClass));
            var $activeTab = this.$panelContent.querySelector(".".concat(this.activeTabClass));
            var activeTabName = $activeHeader.getAttribute('data-tab');
            if (tabToActiveName !== activeTabName) {
                $activeHeader.classList.remove(this.activeHeaderClass);
                $activeTab.classList.remove(this.activeTabClass);
                header.classList.add(this.activeHeaderClass);
                var tab = this.$panelContent.querySelector(".m-tabPanel__content__tab[data-tab=\"".concat(tabToActiveName, "\"]"));
                tab.classList.add(this.activeTabClass);
                this.positionSliderToElement(header);
                var $accordion = app.default.getSnippets().closest(header, '.m-accordion');
                if ($accordion) {
                    var $accordionContainer = $accordion.querySelector('.m-accordion__container');
                    var accordionObject = new Accordion($accordion);
                    accordionObject.calcContainerHeight($accordionContainer);
                }
            }
        }
    };
    TabPanel.prototype.positionSliderToElement = function (element) {
        var elementRect = element.getBoundingClientRect();
        var panelRect = this.$panelHeaderTabs.getBoundingClientRect();
        var left = elementRect.left - panelRect.left;
        var top = panelRect.height - 2;
        var width = elementRect.width;
        this.$headerSlider.setAttribute("style", "left: " + left + "px; width: " + width + "px; top: " + top + "px");
    };
    TabPanel.prototype.initializeSlider = function () {
        var $activeHeader = this.$panelHeaderContent.querySelector(".".concat(this.activeHeaderClass));
        this.positionSliderToElement($activeHeader);
        this.$headerSlider.classList.add('m-tabPanel__header__content__tabs__slider--initialized');
    };
    TabPanel.prototype.startDropHeader = function (ev) {
        this.isDown = true;
        this.$panelHeaderContent.classList.add('active');
        this.startX = ev.pageX - this.$panelHeaderContent.getBoundingClientRect().left;
        this.scrollLeft = this.$panelHeaderContent.scrollLeft;
    };
    TabPanel.prototype.stopDropHeader = function () {
        this.isDown = false;
        this.$panelHeaderContent.classList.remove('active');
    };
    TabPanel.prototype.moveHeader = function (ev) {
        if (!this.isDown)
            return;
        ev.preventDefault();
        var x = ev.pageX - this.$panelHeaderContent.getBoundingClientRect().left;
        var distance = x - this.startX;
        this.$panelHeaderContent.scrollLeft = this.scrollLeft - distance;
    };
    TabPanel.prototype.init = function () {
        var _this = this;
        window.addEventListener('resize', function () { return _this.initializeSlider(); });
        window.addEventListener('load', function () { return _this.initializeSlider(); });
        this.$tabHeaders.forEach(function (header) {
            header.addEventListener('mouseenter', function () { return _this.positionSliderToElement(header); });
            header.addEventListener('mouseleave', function () { return _this.initializeSlider(); });
            header.addEventListener('click', function () { return _this.switchTab(header); });
        });
        if (!document.body.classList.contains('mobile-device')) {
            this.$panelHeaderContent.addEventListener('mousedown', function (event) { return _this.startDropHeader(event); });
            this.$panelHeaderContent.addEventListener('mouseleave', function () { return _this.stopDropHeader(); });
            this.$panelHeaderContent.addEventListener('mouseup', function () { return _this.stopDropHeader(); });
            this.$panelHeaderContent.addEventListener('mousemove', function (event) { return _this.moveHeader(event); });
        }
    };
    return TabPanel;
}());
export { TabPanel };
