import * as app from "../../main";
var ProductTileHover = /** @class */ (function () {
    function ProductTileHover($productTile) {
        this.$productTile = $productTile;
        this.init();
    }
    ProductTileHover.prototype.adjustReplacementStyles = function () {
        if (this.$productTile.querySelector('.m-productReplacementInfo')) {
            if (window.getComputedStyle(this.$productTile.querySelector('.m-productReplacementInfo')).display !== 'none' && this.$productTile.querySelector('.m-cartTile__variantSection') !== null) {
                this.$productTile.querySelector('.m-cartTile__variantSection').classList.add('m-cartTile__replacement');
            }
        }
    };
    ProductTileHover.prototype.init = function () {
        var _this = this;
        document.addEventListener('click', function (event) {
            var element = event.target;
            if (_this.$productTile.contains(element)) {
                var customSelectField = app.default.getSnippets().closest(element, '.a-customSelect__field__input');
                if (element.classList.contains('a-customSelect__field__input') || customSelectField) {
                    _this.$productTile.classList.add('m-productTile--focus');
                }
                else {
                    _this.$productTile.classList.remove('m-productTile--focus');
                }
            }
            else {
                _this.$productTile.classList.remove('m-productTile--focus');
            }
        });
        document.addEventListener('scroll', function () { return _this.$productTile.classList.remove('m-productTile--focus'); });
        this.adjustReplacementStyles();
    };
    return ProductTileHover;
}());
export { ProductTileHover };
