var ErrorHandler = /** @class */ (function () {
    function ErrorHandler() {
        this.init();
    }
    ErrorHandler.prototype.init = function () {
        window.onerror = function (err) {
            if (err === 'ResizeObserver loop completed with undelivered notifications.' ||
                err === 'ResizeObserver loop limit exceeded') {
                return true;
            }
            if (err.toString().includes('is not allowed to override this storefront')) {
                return true;
            }
        };
    };
    return ErrorHandler;
}());
export { ErrorHandler };
