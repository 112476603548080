var OnElementReady = /** @class */ (function () {
    function OnElementReady() {
    }
    OnElementReady.prototype.onElementReady = function ($element) {
        return new Promise(function (resolve) {
            var waitForElement = function () {
                if ($element) {
                    resolve($element);
                }
                else {
                    window.requestAnimationFrame(waitForElement);
                }
            };
            waitForElement();
        });
    };
    ;
    return OnElementReady;
}());
export { OnElementReady };
