import * as app from "../../main";
var Counter = /** @class */ (function () {
    function Counter(counter) {
        this.stockQuantity = 0;
        this.productRequestQuantity = 0;
        this.$counter = counter;
        this.$stockCounterMinus = this.$counter.querySelector('.a-counter__minus');
        this.$stockCounterPlus = this.$counter.querySelector('.a-counter__plus');
        this.$stockCounterInput = this.$counter.querySelector('.a-counter__input');
        this.inputDefaultVal = this.$stockCounterInput.value;
        this.init();
    }
    Counter.prototype.updateQuantity = function (val) {
        var $product = app.default.getSnippets().closest(this.$counter, '.productForm');
        var $stockInfo = $product.querySelector('.m-productStockInfo');
        var indirectProduct = $product.getAttribute('data-indirect-product');
        var outOfStockInfoClass = 'm-productStockInfo--outOfStock';
        var inStockInfoClass = 'm-productStockInfo--inStock';
        this.stockQuantity = parseInt(this.$stockCounterInput.getAttribute('data-stock'));
        if (val === 1 || val === -1) {
            this.productRequestQuantity = parseInt(this.$stockCounterInput.value) + val;
            this.$stockCounterInput.value = this.productRequestQuantity.toString();
        }
        else {
            this.productRequestQuantity = parseInt(this.$stockCounterInput.value);
        }
        if (indirectProduct !== 'true' && $stockInfo !== null) {
            $stockInfo.setAttribute('data-request-quantity', this.productRequestQuantity.toString());
            if (this.stockQuantity < this.productRequestQuantity && !$stockInfo.classList.contains(outOfStockInfoClass)) {
                $stockInfo.classList.remove(inStockInfoClass);
                $stockInfo.classList.add(outOfStockInfoClass);
            }
            else if (this.productRequestQuantity > 0 && this.stockQuantity >= this.productRequestQuantity && $stockInfo.classList.contains(outOfStockInfoClass)) {
                $stockInfo.classList.add(inStockInfoClass);
                $stockInfo.classList.remove(outOfStockInfoClass);
            }
        }
        this.setButtonsAvailable();
    };
    Counter.prototype.subtraction = function (stockCounterMinus) {
        var _this = this;
        stockCounterMinus.addEventListener('click', function () { return _this.updateQuantity(-1); });
    };
    Counter.prototype.addition = function (stockCounterPlus) {
        var _this = this;
        stockCounterPlus.addEventListener('click', function () { return _this.updateQuantity(1); });
    };
    Counter.prototype.changeInputVal = function (stockCounterInput) {
        var inputVal = stockCounterInput.value.slice(0, 3);
        if (/^0/.test(inputVal)) {
            inputVal = inputVal.replace(/^0/, '');
        }
        stockCounterInput.value = inputVal;
        this.updateQuantity();
    };
    Counter.prototype.resetInputVal = function (stockCounterInput) {
        if (stockCounterInput.value === '') {
            stockCounterInput.value = this.inputDefaultVal;
            this.setButtonsAvailable();
        }
    };
    Counter.prototype.setButtonsAvailable = function () {
        var val = parseInt(this.$stockCounterInput.value);
        var min = parseInt(this.$stockCounterInput.getAttribute('min'));
        var max = parseInt(this.$stockCounterInput.getAttribute('max'));
        var minusBtn = this.$stockCounterInput.parentElement.querySelector('.a-counter__minus');
        var plusBtn = this.$stockCounterInput.parentElement.querySelector('.a-counter__plus');
        minusBtn.disabled = (min < val) ? false : true;
        plusBtn.disabled = (max > val) ? false : true;
    };
    Counter.prototype.init = function () {
        var _this = this;
        this.subtraction(this.$stockCounterMinus);
        this.addition(this.$stockCounterPlus);
        this.$stockCounterInput.addEventListener('input', function (event) { return _this.changeInputVal(event.target); });
        this.$stockCounterInput.addEventListener('blur', function (event) { return _this.resetInputVal(event.target); });
    };
    return Counter;
}());
export { Counter };
