var AlertFactory = /** @class */ (function () {
    function AlertFactory() {
    }
    AlertFactory.prototype.createAlert = function (message, title, severity, actions) {
        return this.createAlertInternal(message, title, severity, actions);
    };
    AlertFactory.prototype.createAlertInternal = function (message, title, severity, actions) {
        var mAlert = document.createElement('div');
        mAlert.classList.add('m-alert');
        for (var action in actions) {
            mAlert.classList.add("m-alert--".concat(action));
        }
        if (actions.disposable) {
            mAlert.setAttribute('isAutoDisposableAlert', 'true');
        }
        var mAlertIcon = document.createElement('div');
        mAlertIcon.classList.add('m-alert__icon');
        mAlertIcon.classList.add('m-alert__icon--' + severity);
        var mAlertText = document.createElement('div');
        mAlertText.classList.add('m-alert__text');
        var mAlertTextTitle = document.createElement('span');
        mAlertTextTitle.innerHTML = title;
        mAlertTextTitle.classList.add('m-alert__text__title');
        var mAlertTextMessage = document.createElement('span');
        mAlertTextMessage.classList.add('m-alert__text__message');
        mAlertTextMessage.innerHTML = message;
        var mAlertTextData = document.createElement('div');
        mAlertTextData.classList.add('m-alert__text__data');
        var mAlertCloseButton = document.createElement('div');
        mAlertCloseButton.classList.add('m-alert__button');
        mAlertText.appendChild(mAlertTextTitle);
        mAlertText.appendChild(mAlertTextMessage);
        mAlertText.appendChild(mAlertTextData);
        mAlert.appendChild(mAlertIcon);
        mAlert.appendChild(mAlertText);
        mAlert.appendChild(mAlertCloseButton);
        return mAlert;
    };
    return AlertFactory;
}());
export { AlertFactory };
