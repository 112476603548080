import alertObject from "../notification/global-alerts";
import { AlertFactory } from "../notification/alert-factory";
import axios from 'axios';
import { CartItemsIndicator } from "../cart-items-indicator/cart-items-indicator";
var QuickOrder = /** @class */ (function () {
    function QuickOrder() {
        this.summeryBoxVisibleClass = 'm-quickOrderSummary--visible';
        this.uploadedInputClass = 'm-quickOrderUploadField__btn--uploaded';
        this.notificationType = {
            'added': 'success',
            'not_found': 'warning',
            'not_available': 'warning',
            'already_added': 'warning',
            'incorrect_format': 'warning'
        };
        this.$quickOrder = document.querySelector('.o-quickOrder');
        this.$quickOrderSummary = this.$quickOrder.querySelector('.m-quickOrderSummary');
        this.$quickOrderAlerts = this.$quickOrder.querySelector('.m-quickOrderAlerts');
        this.$quickOrderLoader = this.$quickOrder.querySelector('.o-quickOrder__loader');
        this.$quickOrderCodesProceedBtn = this.$quickOrder.querySelector('.m-quickOrderData__send__proceedBtn--sendText');
        this.$quickOrderFileProceedBtn = this.$quickOrder.querySelector('.m-quickOrderData__send__proceedBtn--sendFile');
        this.$quickOrderInputField = this.$quickOrder.querySelector('.m-quickOrderData__send__inputField textarea');
        this.$quickOrderAlertDraft = this.$quickOrder.querySelector('.m-quickOrderAlert');
        this.$quickOrderAlertItemDraft = this.$quickOrderAlertDraft.querySelector('.m-quickOrderAlertItem');
        this.$quickOrderUploadInput = this.$quickOrder.querySelector('.m-quickOrderUploadField__input');
        this.$quickOrderUploadBtn = this.$quickOrder.querySelector('.m-quickOrderUploadField__btn');
        this.$uploadFileError = this.$quickOrder.querySelector('.m-quickOrderData__send__uploadField__error');
        this.alertFactoryObject = new AlertFactory();
        this.init();
    }
    QuickOrder.prototype.createNotification = function (data) {
        var _a;
        var status = data.status;
        var products = data.products;
        var alertTitle = this.$quickOrderAlertDraft.getAttribute("data-".concat(status));
        var alertType = this.notificationType[status];
        var alertAdditionalClass = (_a = {},
            _a[status] = true,
            _a);
        var alert = this.alertFactoryObject.createAlert('', alertTitle, alertType, alertAdditionalClass);
        var $alertData = alert.querySelector('.m-alert__text__data');
        for (var _i = 0, products_1 = products; _i < products_1.length; _i++) {
            var product = products_1[_i];
            var $quickOrderAlertItem = this.$quickOrderAlertItemDraft.cloneNode(true);
            $quickOrderAlertItem.querySelector('.m-quickOrderAlertItem__name').innerHTML = product.name;
            $quickOrderAlertItem.querySelector('.m-quickOrderAlertItem__code').innerHTML = product.code;
            $quickOrderAlertItem.querySelector('.m-quickOrderAlertItem__quantity').innerHTML = product.quantity ? "x ".concat(product.quantity) : '';
            $alertData.appendChild($quickOrderAlertItem);
        }
        alertObject.showAlert(alert, this.$quickOrderAlerts);
    };
    QuickOrder.prototype.removeNotifications = function () {
        var _this = this;
        var alertArr = this.$quickOrderAlerts.querySelectorAll('.m-alert');
        alertArr.forEach(function (alert) { return _this.$quickOrderAlerts.removeChild(alert); });
    };
    QuickOrder.prototype.enterDataToSummaryBox = function (summaryData) {
        var $summaryContainer = this.$quickOrder.querySelector('.m-quickOrderSummary__data');
        var $price = $summaryContainer.querySelector('.m-quickOrderSummary__data__price');
        var $itemCount = $summaryContainer.querySelector('.m-quickOrderSummary__data__items__number');
        var gst = $summaryContainer.getAttribute('data-gst');
        $price.innerHTML = summaryData.subtotal + gst;
        $itemCount.innerHTML = (summaryData.itemsCount).toString();
    };
    QuickOrder.prototype.toggleQuickOrderSummary = function (action) {
        if (action === void 0) { action = 'show'; }
        if (action == 'show') {
            this.$quickOrderSummary.classList.add(this.summeryBoxVisibleClass);
        }
        else {
            this.$quickOrderSummary.classList.remove(this.summeryBoxVisibleClass);
        }
    };
    QuickOrder.prototype.adjustInputField = function () {
        var inputField = this.$quickOrderInputField;
        inputField.value = this.$quickOrderInputField.value
            .trimStart()
            .replace(/, /g, ',')
            .replace(/ ,/g, ',');
        this.resetInputFieldHeight();
        this.$quickOrderInputField.style.cssText = "height: ".concat(this.$quickOrderInputField.scrollHeight, "px");
    };
    QuickOrder.prototype.resetInputFieldHeight = function () {
        this.$quickOrderInputField.style.removeProperty('height');
    };
    QuickOrder.prototype.displayUploadFileName = function () {
        if (this.$quickOrderUploadInput.files[0]) {
            this.$uploadFileError.classList.remove('m-quickOrderData__send__uploadField__error--active');
            var fileName = this.$quickOrderUploadInput.files[0].name;
            this.$quickOrderUploadBtn.textContent = fileName;
            this.$quickOrderUploadBtn.classList.add(this.uploadedInputClass);
        }
    };
    QuickOrder.prototype.removeUploadedFile = function () {
        this.$quickOrderUploadInput.value = '';
        var defaultText = this.$quickOrderUploadBtn.getAttribute('data-default-text');
        this.$quickOrderUploadBtn.textContent = defaultText;
        this.$quickOrderUploadBtn.classList.remove(this.uploadedInputClass);
    };
    QuickOrder.prototype.sendCodes = function () {
        var dataToSend = this.$quickOrderInputField.value;
        var isDataToSend = dataToSend.length;
        var container = this.$quickOrderInputField.closest('.a-textarea--error');
        if (isDataToSend && !container) {
            var data = { productCodes: dataToSend };
            this.$quickOrderInputField.value = '';
            this.sendData('/quickorder/addProductsJson', data, this.resetInputFieldHeight);
        }
    };
    QuickOrder.prototype.sendFile = function () {
        var formData = new FormData();
        formData.append("productCodes", this.$quickOrderUploadInput.files[0], this.$quickOrderUploadInput.files[0].name);
        this.sendData('/quickorder/addProductsCsv', formData, this.removeUploadedFile, true);
    };
    QuickOrder.prototype.sendData = function (endpoint, data, resetFunction, showError) {
        var _this = this;
        if (showError === void 0) { showError = false; }
        this.$quickOrderLoader.classList.add('a-loader--active');
        this.removeNotifications();
        this.toggleQuickOrderSummary('hide');
        axios.post(endpoint, data)
            .then(function (res) {
            var boxes = res.data.boxes;
            for (var _i = 0, boxes_1 = boxes; _i < boxes_1.length; _i++) {
                var box = boxes_1[_i];
                _this.createNotification(box);
            }
            var successBox = boxes.find(function (box) { return box.status === 'added'; });
            if (successBox) {
                _this.toggleQuickOrderSummary();
                _this.enterDataToSummaryBox(res.data.summary);
            }
            new CartItemsIndicator();
            var resetFunctionWithContext = resetFunction.bind(_this);
            resetFunctionWithContext();
            _this.$quickOrderLoader.classList.remove('a-loader--active');
        })
            .catch(function (error) {
            console.log(error);
            _this.$quickOrderLoader.classList.remove('a-loader--active');
            if (showError) {
                _this.$uploadFileError.classList.add('m-quickOrderData__send__uploadField__error--active');
            }
        });
    };
    QuickOrder.prototype.init = function () {
        var _this = this;
        this.$quickOrderCodesProceedBtn.addEventListener('click', function () { return setTimeout(function () { return _this.sendCodes(); }, 0); });
        this.$quickOrderFileProceedBtn.addEventListener('click', function () { return setTimeout(function () { return _this.sendFile(); }, 0); });
        this.$quickOrderInputField.addEventListener('input', function () { return _this.adjustInputField(); });
        this.$quickOrderUploadInput.addEventListener('change', function () { return _this.displayUploadFileName(); });
    };
    return QuickOrder;
}());
export { QuickOrder };
