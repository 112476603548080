var Matrix = /** @class */ (function () {
    function Matrix() {
        this.cellInput = 'm-variantMatrixGrid__cell__input';
        this.outStock = this.cellInput + ' m-variantMatrixGrid__cell__input--outStock';
        this.partStock = this.cellInput + ' m-variantMatrixGrid__cell__input--partStock';
        this.inStock = this.cellInput + ' m-variantMatrixGrid__cell__input--inStock';
        this.noStockInformation = this.cellInput + ' m-variantMatrixGrid__cell__input--noStockInformation';
        this.focusedElement = 'm-variantMatrixGrid__header--hovered';
        this.matrix = document.querySelector('.o-variant-matrix');
        this.table = this.matrix.querySelector('.m-variantMatrixGrid table');
        this.tableHeader = this.table.querySelector('.m-variantMatrixGrid__header');
        this.header = document.querySelector('.o-header__menu');
        this.matrixElements = this.matrix.querySelectorAll('.m-variantMatrixGrid__cell:not(.m-variantMatrixGrid__cell__notAvailable)');
        this.matrixElementsWithReplacement = this.matrix.querySelectorAll('.m-variantMatrixGrid__cell__notAvailable--withReplacement');
        this.matrixInputs = this.matrix.querySelectorAll('.m-variantMatrixGrid__cell:not(.m-variantMatrixGrid__cell__notAvailable) .m-variantMatrixGrid__cell__input');
        this.matrixSubmitButtons = this.matrix.querySelectorAll('.m-legend__cart__button--add');
        this.dealerTileInput = document.querySelector('.a-customSelect--dealer .a-customSelect__field__input--hidden');
        this.dealer = document.querySelector('input[name="dealer"]');
        this.tableLegendButton = document.querySelector('.m-legend .m-legend__cart');
        this.init();
    }
    Matrix.prototype.checkStock = function (input) {
        var stockValue = input.getAttribute('data-stock');
        var stockCheckDisabled = input.getAttribute('check-stock-disabled');
        if ((parseInt(stockValue) || 0) == 0) {
            input.value = '0';
            input.className = stockCheckDisabled === 'true' ? this.noStockInformation : this.outStock;
        }
        input.removeAttribute('disabled');
    };
    Matrix.prototype.enterValue = function (event) {
        var el = event.target;
        var stockValue = parseInt(el.getAttribute('data-stock')) || 0;
        if (!el.value) {
            el.className = this.cellInput;
            return;
        }
        var currentValue = parseInt(el.value) || 0;
        if (el.getAttribute('check-stock-disabled') === 'true') {
            el.className = this.noStockInformation;
        }
        else if (stockValue == 0) {
            el.className = this.outStock;
        }
        else {
            el.className = stockValue < currentValue ? this.partStock : this.inStock;
        }
    };
    Matrix.prototype.removeForbiddenCharacters = function (event) {
        var el = event.target;
        el.value = el.value.replace(/[^\d]/, '');
        this.countAllElements();
    };
    Matrix.prototype.countAllElements = function () {
        var allProductsCount = 0;
        var matrixElementsArr = Array.prototype.slice.call(this.matrixElements);
        var matrixButtonsArr = Array.prototype.slice.call(this.matrixSubmitButtons);
        matrixElementsArr.forEach(function (matrixCell) {
            var quantity = matrixCell.children.quantity;
            if (quantity.value) {
                allProductsCount += parseInt(quantity.value) || 0;
            }
        });
        matrixButtonsArr.forEach(function (button) {
            button.innerHTML = button.innerHTML.replace(/\d+/g, allProductsCount.toString());
        });
    };
    Matrix.prototype.setReplacementInfoPosition = function (matrixCell) {
        var replacementInfo = matrixCell.querySelector('.m-productReplacementInfo');
        var replacementInfoWidth = replacementInfo.offsetWidth;
        var replacementInfoHeight = replacementInfo.offsetHeight;
        var cellPosition = matrixCell.getBoundingClientRect();
        var headerPosition = this.header.getBoundingClientRect();
        var tableLegendButtonWidth = this.tableLegendButton.offsetWidth;
        var spaceHeaderAndCell = cellPosition.top - headerPosition.bottom;
        var spaceTableTopAndCell = matrixCell.offsetTop - this.tableHeader.offsetHeight;
        var spaceTableRightAndCell = this.table.offsetWidth - matrixCell.offsetLeft;
        var hasNotEnoughSpaceAboveCell = spaceTableTopAndCell < replacementInfoHeight;
        var headerCoversSpaceAboveCell = spaceHeaderAndCell < replacementInfoHeight;
        var showBelowCell = hasNotEnoughSpaceAboveCell || headerCoversSpaceAboveCell;
        var coversLegendButton = spaceTableRightAndCell - tableLegendButtonWidth < replacementInfoWidth;
        var showOnLeftSide = (spaceTableRightAndCell < replacementInfoWidth) || (showBelowCell && coversLegendButton);
        var horizontalStyle = showOnLeftSide ? 'right: 0; left: auto;' : 'right: auto; left: 0;';
        var verticalStyle = showBelowCell ? 'top: 100%; bottom: auto;' : 'top: auto; bottom: 100%;';
        replacementInfo.style.cssText = horizontalStyle + verticalStyle;
    };
    Matrix.prototype.addCellFocus = function (event) {
        event.stopPropagation();
        var xattr = parseInt(event.target.getAttribute('x')) || 0;
        var yattr = parseInt(event.target.getAttribute('y')) || 0;
        var cellYAttribute = yattr + 2;
        var leftHeaderCell = this.table.querySelector("tr:nth-child(" + cellYAttribute + ")").querySelector(":nth-child(1)");
        leftHeaderCell.classList.add(this.focusedElement);
        var cellXAttribute = xattr + 2;
        var topHeaderCell = this.tableHeader.querySelector(":nth-child(" + cellXAttribute + ")");
        topHeaderCell.classList.add(this.focusedElement);
    };
    Matrix.prototype.leaveCellFocus = function (event) {
        var _this = this;
        event.stopPropagation();
        var focusedHeaders = this.matrix.querySelectorAll(".".concat(this.focusedElement));
        focusedHeaders.forEach(function (cell) { return cell.classList.remove(_this.focusedElement); });
    };
    Matrix.prototype.init = function () {
        var _this = this;
        if (this.dealerTileInput) {
            this.dealerTileInput.addEventListener('customSelectValueChanged', function () {
                _this.dealer.value = _this.dealerTileInput.value;
            });
        }
        this.matrixElements.forEach(function (matrixCell) {
            matrixCell.addEventListener('mouseenter', function (event) { return _this.addCellFocus(event); });
            matrixCell.addEventListener('mouseleave', function (event) { return _this.leaveCellFocus(event); });
        });
        this.matrixElementsWithReplacement.forEach(function (matrixCell) {
            matrixCell.addEventListener('mouseenter', function () { return _this.setReplacementInfoPosition(matrixCell); });
        });
        this.matrixInputs.forEach(function (input) {
            _this.checkStock(input);
            input.addEventListener('keyup', function (event) { return _this.removeForbiddenCharacters(event); });
            input.addEventListener('input', function (event) { return _this.enterValue(event); });
        });
    };
    return Matrix;
}());
export { Matrix };
