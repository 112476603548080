import * as app from '../../main';
import axios from 'axios';
import SimpleBar from 'simplebar';
import { Modal } from "../modals/modal";
import { CartItemsIndicator } from "../cart-items-indicator/cart-items-indicator";
import alertObject from "../notification/global-alerts";
import { AlertFactory } from "../notification/alert-factory";
import { ScrollPageHandler } from "../scroll-page";
var OfferForm = /** @class */ (function () {
    function OfferForm() {
        this.$form = document.querySelector('#offerForm');
        this.$preferredMode = this.$form.querySelector('#preferred');
        this.$otherMode = this.$form.querySelector('#other');
        this.$submitButton = this.$form.querySelector('.a-greenBlueBtn');
        this.$customerEmail = this.$form.querySelector('#customerEmailSection');
        this.$customerEmailInput = this.$customerEmail.querySelector('input');
        this.otherDealerEmailValue = '';
        this.$modal = document.querySelector('#removeDealersProductModal');
        this.$productName = this.$modal.querySelector('.m-dealersProductModal__content__table__data__first--name');
        this.$sku = this.$modal.querySelector('.m-dealersProductModal__content__table__data__second--sku');
        this.$productQuantity = this.$modal.querySelector('.m-dealersProductModal__content__table__data__second--quantity');
        this.$productImage = this.$modal.querySelector('.m-dealersProductModal__content__table__data__first--img');
        this.$confirmModalButton = this.$modal.querySelector('.a-darkBtn');
        this.$closeModalBtn = this.$modal.querySelector('.m-modal__content__close');
        this.$redirectLink = this.$modal.querySelector('.m-dealersProductModal__redirect');
        this.alertFactoryObject = new AlertFactory();
        this.init();
    }
    OfferForm.prototype.toggleDealerMode = function () {
        this.$preferredDealerElement.classList.toggle('m-offerFormChooseDealer__option__nestedInput--hidden');
        this.$preferredDealerSearch.classList.toggle('m-offerFormChooseDealer__option__search--hidden');
        this.$otherDealerElement.classList.toggle('m-offerFormChooseDealer__option__nestedInput--hidden');
        this.$preferredDealerInputArr.forEach(function (input) {
            if (input.id !== 'template') {
                input.toggleAttribute('required');
            }
        });
        if (this.$otherMode.checked) {
            this.$otherDealerInput.setAttribute('required', 'true');
            this.$otherDealerInput.setAttribute('pattern', '^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$');
            this.$otherDealerInput.value = this.otherDealerEmailValue;
        }
        else {
            this.otherDealerEmailValue = this.$otherDealerInput.value;
            this.$otherDealerInput.value = '';
            this.$otherDealerInput.removeAttribute('required');
            this.$otherDealerInput.removeAttribute('pattern');
        }
    };
    OfferForm.prototype.colourButton = function () {
        var result = true;
        if (this.$otherMode) {
            var otherDealerRequired = this.$otherDealerInput.hasAttribute('required');
            result && (result = !otherDealerRequired || this.$otherDealerInput.checkValidity());
        }
        result && (result = this.$customerEmailInput.checkValidity());
        if (result) {
            this.$submitButton.classList.remove('a-greenBlueBtn--clickableDisabled');
        }
        else {
            this.$submitButton.classList.add('a-greenBlueBtn--clickableDisabled');
        }
    };
    OfferForm.prototype.searchDealer = function (action) {
        var _this = this;
        if (action === void 0) { action = 'insert'; }
        this.searchAbortController.abort();
        this.searchAbortController = new AbortController();
        this.$preferredDealerError.classList.add('m-offerFormChooseDealer__option__nestedInput__error--hidden');
        var dealerName = encodeURIComponent(this.$dealerName.value);
        var clearCurrentCityVal = action === 'insert' && !dealerName.length;
        var dealerCity = this.$dealerCity.value === 'notDefined' ? '' : this.$dealerCity.value;
        var requestUrl;
        requestUrl = "/dealer?name=".concat(dealerName, "&city=").concat(dealerCity);
        axios.get(requestUrl)
            .then(function (res) {
            var responseData = res.data;
            _this.$preferredDealerOptionsList.innerHTML = '';
            if (responseData.dealers.length) {
                _this.$preferredDealerContainer.classList.remove('m-offerFormChooseDealer__option__nestedInput--noData');
                _this.$preferredDealerOptionsList.appendChild(_this.$preferredDealerOptionTemplate);
                _this.updateDealersList(responseData.dealers);
                var listOfCitiesToUpdate = clearCurrentCityVal ? _this.listOfCities : responseData.cities;
                _this.updateCitiesList(listOfCitiesToUpdate);
            }
            else {
                _this.clearCitiesList();
                _this.noDealersToDisplay();
            }
        })
            .catch(function (error) {
            console.error(error);
            _this.noDealersToDisplay();
        });
    };
    OfferForm.prototype.emptyDealersList = function () {
        return this.$preferredDealerContainer.classList.contains('m-offerFormChooseDealer__option__nestedInput--noData');
    };
    OfferForm.prototype.noDealersToDisplay = function () {
        if (!this.emptyDealersList()) {
            this.$preferredDealerContainer.classList.add('m-offerFormChooseDealer__option__nestedInput--noData');
        }
    };
    OfferForm.prototype.updateDealersList = function (dealers) {
        var _this = this;
        dealers.sort(function (a, b) {
            return (a.dealerName.toLowerCase() > b.dealerName.toLowerCase()) ? 1 : -1;
        });
        dealers.forEach(function (item, index) {
            var element = _this.$preferredDealerOptionTemplate.cloneNode(true);
            element.hidden = false;
            element.classList.remove('a-radio--template');
            var elementInput = element.querySelector('input');
            var elementLabel = element.querySelector('label');
            var elementSpan = element.querySelector('.a-radio__label__text');
            var isRequired = elementInput.getAttribute('data-offer-form-is-preferred');
            elementInput.id = item.accountNumber.toString();
            elementInput.value = item.accountNumber.toString();
            elementInput.name = 'preferredDealer';
            elementInput.required = !!isRequired;
            elementLabel.htmlFor = item.accountNumber.toString();
            var city = item.city ? "<br /> ".concat(item.city) : '';
            elementSpan.innerHTML = "".concat(item.dealerName, " ").concat(city);
            if (index === 0) {
                elementInput.checked = true;
            }
            _this.$preferredDealerOptionsList.appendChild(element);
            var sub = _this.$preferredDealerContainerList;
            if (sub) {
                new SimpleBar(sub);
            }
        });
        app.default.getValidation(this.$form.id).getFields();
    };
    OfferForm.prototype.clearCitiesList = function () {
        var notSelectedCityOption = this.$cityOptionTemplate.cloneNode(true);
        this.$cityOptionsList.innerHTML = '';
        this.$cityOptionsList.appendChild(notSelectedCityOption);
    };
    OfferForm.prototype.updateCitiesList = function (cities) {
        var _this = this;
        this.clearCitiesList();
        cities.forEach(function (city) {
            var cityOption = _this.$cityOptionTemplate.cloneNode(true);
            _this.$cityOptionsList.appendChild(cityOption);
            cityOption.classList.remove('a-customSelect__dropdown__option--template');
            var cityOptionInput = cityOption.querySelector('input');
            var cityOptionLabel = cityOption.querySelector('label');
            cityOptionInput.id = city;
            cityOptionInput.value = city;
            cityOptionInput.name = city;
            cityOptionInput.setAttribute('data-text', city);
            cityOptionLabel.htmlFor = city;
            cityOptionLabel.innerHTML = city;
            _this.$cityOptionsList.appendChild(cityOption);
            var sub = _this.$citiesDropdown.querySelector('.a-scrollable');
            if (sub) {
                new SimpleBar(sub);
            }
        });
    };
    OfferForm.prototype.validateDealerProducts = function (ev) {
        var _this = this;
        var preffereDealerId = this.$preferredDealerOptionsList.querySelector('input[name=preferredDealer]:checked').value;
        var requestUrl = '/agent/checkout/validateproducts';
        var data = { preferredDealer: preffereDealerId };
        axios.post(requestUrl, data)
            .then(function (response) {
            var _a;
            _this.listOfProducts = response.data;
            if (response.data.length > 0) {
                _this.$productName.innerHTML = response.data[0].product.baseProductDisplayName === null
                    ? response.data[0].product.displayName
                    : "".concat(response.data[0].product.baseProductDisplayName, " ").concat(response.data[0].product.displayName);
                _this.$productQuantity.innerHTML = response.data[0].quantity;
                _this.$sku.innerHTML = response.data[0].product.code;
                response.data[0].product.images !== null && _this.$productImage.setAttribute('src', (_a = response.data[0].product.images[0]) === null || _a === void 0 ? void 0 : _a.url);
                _this.addModal(ev);
            }
            else {
                _this.submitForm(ev, true);
            }
        })
            .catch(function (error) {
            console.log(error);
            var message = 'Error occured during order simulation.<br />Please contact Ordering Tool Power User.';
            var element = _this.alertFactoryObject.createAlert(message, window.ACC.status.error, 'error', { 'animated': true, 'closable': true });
            alertObject.showAlert(element);
        });
    };
    OfferForm.prototype.deleteDealerUnsellableProducts = function () {
        var _this = this;
        axios.get('/agent/checkout/removeproducts ')
            .then(function (response) {
            new CartItemsIndicator();
            (response === null || response === void 0 ? void 0 : response.data) === "Empty cart" ? _this.$redirectLink.click() : _this.$closeModalBtn.click();
        })
            .catch(function (error) {
            console.log(error);
        });
    };
    OfferForm.prototype.addModal = function (ev) {
        ev.preventDefault();
        var modal = new Modal(document.querySelector('.m-modal#removeDealersProductModal'));
        modal.showModal();
    };
    OfferForm.prototype.submitForm = function (ev, dealersProductValidation) {
        var _a, _b, _c;
        ((_a = this.$otherMode) === null || _a === void 0 ? void 0 : _a.checked) !== true && dealersProductValidation !== true && this.validateDealerProducts(ev);
        ev.stopImmediatePropagation();
        app.default.getValidation(this.$form.id).validateFields();
        if (this.$preferredDealerContainer && this.emptyDealersList() && this.$preferredMode.checked) {
            ev.preventDefault();
            this.$preferredDealerError.classList.remove('m-offerFormChooseDealer__option__nestedInput__error--hidden');
        }
        else if (this.$form.checkValidity() && (((_b = this.listOfProducts) === null || _b === void 0 ? void 0 : _b.length) === 0 || ((_c = this.$otherMode) === null || _c === void 0 ? void 0 : _c.checked) === true)) {
            this.$form.submit();
        }
    };
    OfferForm.prototype.init = function () {
        var _this = this;
        if (this.$preferredMode) {
            this.$preferredDealerElement = this.$form.querySelector('#preferredDealerChooser');
            this.$preferredDealerInputArr = this.$preferredDealerElement.querySelectorAll('input');
            this.$dealerName = this.$form.querySelector('#dealerName');
            this.$dealerCity = this.$form.querySelector('#dealerCity');
            //variables to manipulate list of preferred dealers
            this.$preferredDealerSearch = this.$form.querySelector('.m-offerFormChooseDealer__option__search');
            this.$preferredDealerContainer = this.$form.querySelector('.m-offerFormChooseDealer__option__nestedInput--preferredDealer');
            this.$preferredDealerError = this.$preferredDealerContainer.querySelector('.m-offerFormChooseDealer__option__nestedInput__error');
            this.$preferredDealerContainerList = this.$preferredDealerContainer.querySelector('.m-offerFormChooseDealer__option__nestedInput__list');
            this.$preferredDealerOptionTemplate = this.$preferredDealerContainer.querySelector('.a-radio--template');
            this.$preferredDealerOptionsList = this.$preferredDealerContainer.querySelector('.simplebar-content');
            //variables to manipulate list of cities in dropdown
            this.$citiesDropdown = this.$form.querySelector('.m-offerFormChooseDealer__option__search__dropdown');
            this.$cityOptionTemplate = this.$citiesDropdown.querySelector('.a-customSelect__dropdown__option--template');
            this.$cityOptionsList = this.$citiesDropdown.querySelector('.simplebar-content');
            this.listOfCities = this.$citiesDropdown.getAttribute('data-cities').replace(/(\[)|(\])/g, '').split(',');
            this.searchAbortController = new AbortController();
            this.$dealerName.addEventListener('input', function () { return _this.searchDealer(); });
            this.$dealerCity.addEventListener('customSelectValueChanged', function () { return _this.searchDealer('choose'); });
        }
        if (this.$otherMode) {
            this.$otherDealerElement = this.$form.querySelector('#otherDealerChooser');
            this.$otherDealerInput = this.$otherDealerElement.querySelector('input');
            this.$otherDealerInput.addEventListener('input', function () { return _this.colourButton(); });
        }
        if (this.$otherMode && this.$preferredMode) {
            this.$preferredMode.addEventListener('change', function () {
                _this.toggleDealerMode();
                _this.colourButton();
            });
            this.$otherMode.addEventListener('change', function () {
                if (window.innerWidth < 1152) {
                    var scrollPageObject = new ScrollPageHandler();
                    scrollPageObject.scrollPage('.m-offerFormChooseDealer__option');
                }
                _this.toggleDealerMode();
                _this.colourButton();
            });
        }
        this.colourButton();
        this.$customerEmailInput.addEventListener('input', function () { return _this.colourButton(); });
        this.$submitButton.addEventListener('click', function (ev) { return _this.submitForm(ev); });
        this.$confirmModalButton.addEventListener('click', function (event) { return _this.deleteDealerUnsellableProducts(); });
    };
    return OfferForm;
}());
export { OfferForm };
