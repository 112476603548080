var GallerySectionHandler = /** @class */ (function () {
    function GallerySectionHandler() {
        this.$gallery = document.querySelector('.m-pdpZoomGallery');
        this.$galleryContent = this.$gallery.querySelector('.m-pdpZoomGallery__mainContent');
        this.$galleryImg = this.$gallery.querySelector('.m-pdpZoomGallery__mainContent__img');
        this.$thumbnails = document.querySelector('#MagicScroll');
        this.$medicalNotification = document.querySelector('.m-notification__medical');
        this.init();
    }
    GallerySectionHandler.prototype.setOptions = function () {
        var hintTextOnDesktop = this.$galleryContent.getAttribute('data-hint-desktop');
        var hintTextOnTablet = this.$galleryContent.getAttribute('data-hint-tablet');
        window.mzOptions = {
            hint: 'always',
            textClickZoomHint: hintTextOnDesktop,
            textExpandHint: '',
            zoomWidth: '70%',
            zoomHeight: '100%',
            transitionEffect: false,
            zoomOn: 'click'
        };
        window.mzMobileOptions = {
            textClickZoomHint: hintTextOnTablet,
            textExpandHint: ''
        };
        window.MagicZoom.refresh();
    };
    GallerySectionHandler.prototype.setPdpMargin = function () {
        var _a, _b;
        var thumbnailsHeight = 0;
        var medicalNotificationHeight = 0;
        var thumbnailsRect = (_a = this.$thumbnails) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        var medicalNotificationRect = (_b = this.$medicalNotification) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
        var pdpEl = document.querySelector('.m-pdpDetails');
        var dataEl = document.querySelector('.m-pdpDetails__dataContainer__content');
        var dataRect = dataEl === null || dataEl === void 0 ? void 0 : dataEl.getBoundingClientRect();
        medicalNotificationRect !== undefined ? medicalNotificationHeight = medicalNotificationRect === null || medicalNotificationRect === void 0 ? void 0 : medicalNotificationRect.height : medicalNotificationHeight = 0;
        thumbnailsRect !== undefined ? thumbnailsHeight = thumbnailsRect === null || thumbnailsRect === void 0 ? void 0 : thumbnailsRect.height : thumbnailsHeight = 0;
        var marginSize = 0;
        var mobileMarginSize = 0;
        mobileMarginSize = thumbnailsHeight + medicalNotificationHeight;
        if (((thumbnailsRect === null || thumbnailsRect === void 0 ? void 0 : thumbnailsRect.bottom) < (dataRect === null || dataRect === void 0 ? void 0 : dataRect.bottom) && medicalNotificationRect == undefined) || ((medicalNotificationRect === null || medicalNotificationRect === void 0 ? void 0 : medicalNotificationRect.bottom) < (dataRect === null || dataRect === void 0 ? void 0 : dataRect.bottom) && thumbnailsRect == undefined)) {
            marginSize = 0;
        }
        else if ((thumbnailsRect === null || thumbnailsRect === void 0 ? void 0 : thumbnailsRect.bottom) < (dataRect === null || dataRect === void 0 ? void 0 : dataRect.bottom) && medicalNotificationRect) {
            marginSize = medicalNotificationHeight;
        }
        else {
            marginSize = thumbnailsHeight + medicalNotificationHeight;
        }
        pdpEl.setAttribute('style', "--pdpGalleryMargin-height: ".concat(marginSize, "px;\n        --pdpMobileGalleryMargin-height: ").concat(mobileMarginSize, "px;"));
    };
    GallerySectionHandler.prototype.setMedicalNotificationStyles = function () {
        if (!this.$thumbnails && this.$medicalNotification) {
            this.$medicalNotification.classList.add('m-notification__medical__pdp__withoutthumbnails');
        }
    };
    GallerySectionHandler.prototype.init = function () {
        var _this = this;
        window.addEventListener('resize', function () {
            window.MagicScroll.refresh('MagicScroll');
            _this.setPdpMargin();
            _this.setMedicalNotificationStyles();
        });
        this.setPdpMargin();
        this.setMedicalNotificationStyles();
        if (this.$galleryImg) {
            this.setOptions();
        }
    };
    return GallerySectionHandler;
}());
export { GallerySectionHandler };
