import * as app from '../main';
var FormValidation = /** @class */ (function () {
    function FormValidation(form) {
        this.inputClass = 'a-input';
        this.textareaClass = 'a-textarea';
        this.checkboxClass = 'a-checkbox';
        this.radioClass = 'a-radio';
        this.$form = form;
        this.$formSubmitBtns = this.$form.querySelectorAll('.formToValid__submitBtn');
        this.init();
    }
    FormValidation.prototype.submitForm = function (event) {
        if (!this.$form.checkValidity()) {
            event.preventDefault();
            this.validateFields();
            return;
        }
    };
    FormValidation.prototype.getFields = function () {
        this.$formInputs = this.$form.querySelectorAll('.formToValid__field .a-input');
        this.$formTextareas = this.$form.querySelectorAll('.formToValid__field .a-textarea');
        this.$formCheckboxes = this.$form.querySelectorAll('.formToValid__field .a-checkbox');
        this.$formRadios = this.$form.querySelectorAll('.formToValid__field .a-radio:last-child');
    };
    FormValidation.prototype.validateFields = function () {
        var _this = this;
        this.$formInputs.forEach(function (input) {
            _this.validationResult(input, _this.inputClass);
        });
        this.$formTextareas.forEach(function (textarea) {
            _this.validationResult(textarea, _this.textareaClass);
        });
        this.$formCheckboxes.forEach(function (checkbox) {
            _this.validationResult(checkbox, _this.checkboxClass);
        });
        this.$formRadios.forEach(function (radio) {
            _this.validationResult(radio, _this.radioClass);
        });
    };
    FormValidation.prototype.validationResult = function ($fieldContainer, fieldClass) {
        var $field;
        var isValid;
        if (fieldClass === this.inputClass || fieldClass === this.checkboxClass) {
            $field = $fieldContainer.querySelector('input');
            isValid = $field.checkValidity();
        }
        if (fieldClass === this.textareaClass) {
            $field = $fieldContainer.querySelector('textarea');
            var pattern = $field.getAttribute('pattern');
            isValid = pattern ? this.textareaPatternValidation(pattern, $field) : $field.checkValidity();
            if ($field.closest('.m-quickOrderData') && !isValid) {
                this.errorMessageForCodeFieldQuickOrder($field, $fieldContainer);
            }
        }
        if (fieldClass === this.radioClass) {
            $field = $fieldContainer.querySelector('input');
            var $radioSelector = 'input[name="' + $field.name + '"]:checked';
            var $radioFieldContainer = app.default.getSnippets().closest($field, '.formToValid__field');
            isValid = Boolean($radioFieldContainer.querySelector($radioSelector));
        }
        var errorClass = "".concat(fieldClass, "--error");
        var successClass = "".concat(fieldClass, "--success");
        if (!isValid) {
            $fieldContainer.classList.remove(successClass);
            $fieldContainer.classList.add(errorClass);
        }
        else if (isValid && !$field.value) {
            $fieldContainer.classList.remove(successClass);
            $fieldContainer.classList.remove(errorClass);
        }
        else {
            $fieldContainer.classList.remove(errorClass);
            $fieldContainer.classList.add(successClass);
        }
    };
    FormValidation.prototype.errorMessageForCodeFieldQuickOrder = function (textarea, container) {
        var codePattern = new RegExp('^[a-zA-Z0-9]\\w*(\\s[1-9]\\d*)?$');
        var text = textarea.value;
        var $errorField = container.querySelector('.a-textarea__error');
        var emptyMessage = $errorField.getAttribute('data-empty');
        var invalidMessage = $errorField.getAttribute('data-incorrect-variables');
        var errorToDisplay;
        if (text) {
            var productCodeArr = text.split(/[\n,]/);
            var incorrectCodes = '';
            for (var _i = 0, productCodeArr_1 = productCodeArr; _i < productCodeArr_1.length; _i++) {
                var productCode = productCodeArr_1[_i];
                if (productCode.length && !codePattern.test(productCode)) {
                    incorrectCodes += " ".concat(productCode, ",");
                }
            }
            errorToDisplay = "".concat(invalidMessage).concat(incorrectCodes);
        }
        else {
            errorToDisplay = "".concat(emptyMessage);
        }
        container.querySelector('.a-textarea__error').innerHTML = errorToDisplay;
    };
    FormValidation.prototype.textareaPatternValidation = function (pattern, $textarea) {
        var text = $textarea.value;
        var patternRegex = new RegExp(pattern);
        var correct = patternRegex.test(text);
        return correct;
    };
    FormValidation.prototype.initValidationResult = function ($fieldContainer, fieldClass) {
        var errorClass = "".concat(fieldClass, "--error");
        var successClass = "".concat(fieldClass, "--success");
        $fieldContainer.classList.remove(successClass);
        $fieldContainer.classList.remove(errorClass);
    };
    FormValidation.prototype.reinitFormValidation = function () {
        this.$formSubmitBtns = this.$form.querySelectorAll('.formToValid__submitBtn');
        this.init();
    };
    FormValidation.prototype.init = function () {
        var _this = this;
        this.getFields();
        this.$formSubmitBtns.forEach(function (btn) {
            btn.addEventListener('click', function (event) { return _this.submitForm(event); });
        });
        this.$formInputs.forEach(function (input) {
            input.addEventListener('blur', function () {
                _this.validationResult(input, _this.inputClass);
            }, true);
        });
        this.$formTextareas.forEach(function (textarea) {
            textarea.addEventListener('blur', function () {
                _this.validationResult(textarea, _this.textareaClass);
            }, true);
        });
        this.$formCheckboxes.forEach(function (checkbox) {
            checkbox.addEventListener('change', function () {
                _this.validationResult(checkbox, _this.checkboxClass);
            });
        });
        this.$formRadios.forEach(function (radio) {
            radio.addEventListener('change', function () {
                _this.validationResult(radio, _this.radioClass);
            });
        });
    };
    return FormValidation;
}());
export { FormValidation };
