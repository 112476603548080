import { ScrollPageHandler } from '../scroll-page';
import * as app from "../../main";
var SeeRatingsHandler = /** @class */ (function () {
    function SeeRatingsHandler() {
        this.$seeRaterBtn = document.querySelector('.m-pdpBaseData__rating__link');
        this.init();
    }
    SeeRatingsHandler.prototype.openRatingTab = function () {
        var tabToActive = document.querySelector('.m-tabPanel__header__content__tabs__name[data-tab="ratings"]');
        var tabPanel = app.default.getSnippets().closest(tabToActive, '.m-tabPanel');
        var tabPanelArr = app.default.getTabPanelArr();
        var tabPanelObj = tabPanelArr.find(function (panel) { return panel.element == tabPanel; });
        tabPanelObj.obj.switchTab(tabToActive);
        var scrollPageObject = new ScrollPageHandler();
        scrollPageObject.scrollPage('.m-pdpTabPanel');
    };
    SeeRatingsHandler.prototype.init = function () {
        var _this = this;
        this.$seeRaterBtn.addEventListener('click', function () { return _this.openRatingTab(); });
    };
    return SeeRatingsHandler;
}());
export { SeeRatingsHandler };
