var LanguageSelector = /** @class */ (function () {
    function LanguageSelector() {
        this.activeLanguageSelector = "m-languageSelector--active";
        this.languageContainer = document.querySelector('.m-languageSelector');
        this.init();
    }
    LanguageSelector.prototype.showLanguageTooltip = function (ev) {
        ev.stopPropagation();
        this.languageContainer.classList.add(this.activeLanguageSelector);
    };
    LanguageSelector.prototype.closeLanguageTooltip = function () {
        if (this.languageContainer.classList.contains(this.activeLanguageSelector)) {
            this.languageContainer.classList.remove(this.activeLanguageSelector);
        }
    };
    LanguageSelector.prototype.init = function () {
        var _this = this;
        this.languageContainer.addEventListener('click', function (event) { return _this.showLanguageTooltip(event); });
        document.addEventListener('click', function () { return _this.closeLanguageTooltip(); });
    };
    return LanguageSelector;
}());
export { LanguageSelector };
