import * as app from "../main";
var Accordion = /** @class */ (function () {
    function Accordion(content) {
        this.$content = content;
        this.$accordionContainer = this.$content.querySelector('.m-accordion__container');
        this.$accordionHeader = this.$content.querySelector('.m-accordion__header');
        this.hasCustomSelect = !!this.$content.querySelector('.a-customSelect');
        this.calcContainerHeight(this.$accordionContainer);
        this.init();
    }
    Accordion.prototype.toggleOnClick = function (ev) {
        ev.preventDefault();
        var clickedEl = ev.target;
        var accordion = app.default.getSnippets().closest(clickedEl, '.m-accordion');
        var header = accordion.querySelector('.m-accordion__header');
        var container = accordion.querySelector('.m-accordion__container');
        header.classList.toggle('m-accordion__header--active');
        if (this.hasCustomSelect && container.classList.contains('m-accordion__container--collapsed')) {
            // If accordion contains custom select it needs to have overflow: visible, because without it select is not full visible
            container.classList.remove('m-accordion__container--collapsed');
            // class is added with delay so that animation works properly
            window.setTimeout(function () {
                container.classList.add('m-accordion__container--opened');
            }, 600);
        }
        else {
            container.classList.toggle('m-accordion__container--collapsed');
            container.classList.remove('m-accordion__container--opened');
        }
    };
    Accordion.prototype.calcContainerHeight = function (el) {
        setTimeout(function () {
            var containerData = el.querySelector('.m-accordion__container__data');
            var containerH = containerData.getBoundingClientRect().height;
            el.setAttribute('style', "max-height:".concat(containerH, "px"));
        }, 100);
    };
    Accordion.prototype.init = function () {
        var _this = this;
        this.$accordionHeader.addEventListener('click', function (event) { return _this.toggleOnClick(event); });
        window.addEventListener('resize', function () { return _this.calcContainerHeight(_this.$accordionContainer); });
    };
    return Accordion;
}());
export { Accordion };
