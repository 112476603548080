var CartSummary = /** @class */ (function () {
    function CartSummary($cartSummary) {
        this.cartSummaryShadowHeight = 13;
        this.$cartSummary = $cartSummary;
        this.$title = document.querySelector('.content h1');
        // Fix for cart promotion page which has different title section structure
        this.$cartPromotionPageTitleSection = document.querySelector('.o-cartPage__titleSection');
        this.$cartHeader = document.querySelector('.m-cartHeader');
        this.$recent = document.querySelector('.m-sectionGrid--recent');
        this.$cartPathSelection = document.querySelector('.m-cartPathSelection');
        this.init();
    }
    CartSummary.prototype.updateOffsetConditionValue = function () {
        // this.$title.offsetTop is equal as initial this.$cartSummary.offsetTop (which cannot be used due to problems when resizing with sticky class applied, also its value changes depend on element having sticky class or not) 
        var offset = 0;
        if (this.$title) {
            offset = this.$title.offsetTop;
        }
        else if (this.$cartPromotionPageTitleSection) {
            offset = this.$cartPromotionPageTitleSection.offsetHeight;
        }
        this.offsetConditionValue = offset - this.cartSummaryShadowHeight;
    };
    CartSummary.prototype.updateDimensions = function () {
        window.pageYOffset > this.offsetConditionValue ? this.$cartSummary.classList.add('m-cartSummary--sticky') : this.$cartSummary.classList.remove('m-cartSummary--sticky');
    };
    CartSummary.prototype.init = function () {
        var _this = this;
        if (document.body.classList.contains('internet-explorer')) {
            this.updateOffsetConditionValue();
            this.updateDimensions();
            window.addEventListener('scroll', function () { return _this.updateDimensions(); });
            window.addEventListener('resize', function () {
                _this.updateOffsetConditionValue();
                _this.updateDimensions();
            });
        }
        if (this.$cartHeader) {
            document.onscroll = function () {
                _this.resizeSummary();
            };
        }
    };
    CartSummary.prototype.resizeSummary = function () {
        var $activeTab = this.$cartHeader.querySelector('.m-tabPanel__content__tab--active');
        var $cartContentSummary;
        if ($activeTab) {
            $cartContentSummary = $activeTab.querySelector('.o-cartPage__contentSection__summary');
        }
        else {
            $cartContentSummary = document.querySelector('.o-cartPage__contentSection__summary');
        }
        if ($cartContentSummary) {
            if (window.innerWidth >= 1152) {
                if (this.$cartHeader && this.$title) {
                    var newHeight = this.$cartHeader.offsetHeight + this.$title.offsetHeight;
                    if (this.$cartPathSelection) {
                        if (!this.$cartPathSelection.classList.contains('m-cartPathSelection--hidden')) {
                            newHeight += this.$cartPathSelection.offsetHeight;
                        }
                    }
                    $cartContentSummary.style.height = newHeight + 'px';
                }
            }
            else {
                $cartContentSummary.style.height = null;
            }
        }
    };
    return CartSummary;
}());
export { CartSummary };
