var Machines = /** @class */ (function () {
    function Machines() {
        this.$registrationRadioBtnArr = document.querySelectorAll('.a-radio__input');
        this.$registrationSubmitBtn = document.querySelector('input[type="submit"]');
        this.init();
    }
    Machines.prototype.initSubmitBtn = function () {
        var initInput = document.querySelector('input[name="selectedOption"]:checked');
        if (initInput) {
            this.updateSubmitBtn(initInput);
        }
    };
    Machines.prototype.updateSubmitBtn = function (input) {
        var id = input.getAttribute('data-screenset-id');
        var url = input.getAttribute('data-redirect-url');
        this.$registrationSubmitBtn.setAttribute('data-gigya-id', id);
        this.$registrationSubmitBtn.setAttribute('data-gigya-redirect-url', url);
        this.$registrationSubmitBtn.removeAttribute('disabled');
        this.$registrationSubmitBtn.classList.remove('a-darkBtn--disabled');
    };
    Machines.prototype.init = function () {
        var _this = this;
        this.$registrationRadioBtnArr.forEach(function (btn) {
            btn.addEventListener('change', function () { return _this.updateSubmitBtn(btn); });
        });
        this.initSubmitBtn();
        window.addEventListener('load', function () {
            // fix for browser omitting autocomplete attribute
            setTimeout(function () {
                _this.initSubmitBtn();
            }, 0);
        });
    };
    return Machines;
}());
export { Machines };
