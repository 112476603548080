var SessionStorageData = /** @class */ (function () {
    function SessionStorageData() {
    }
    SessionStorageData.prototype.getCurrentWebshop = function () {
        return location.pathname.split('/')[1].toLowerCase();
    };
    SessionStorageData.prototype.setItem = function (name, object) {
        var data = {
            data: object,
            webshop: this.getCurrentWebshop()
        };
        sessionStorage.setItem(name, JSON.stringify(data));
    };
    SessionStorageData.prototype.getItem = function (name) {
        var dataJson = sessionStorage.getItem(name);
        var data = JSON.parse(dataJson);
        sessionStorage.removeItem(name);
        return data;
    };
    SessionStorageData.prototype.useSessionValues = function (name, callback) {
        var sessionData = this.getItem(name);
        var isLoggedIn = document.body.classList.contains('-logged-in');
        var webshop = sessionData === null || sessionData === void 0 ? void 0 : sessionData.webshop;
        var currentWebshop = this.getCurrentWebshop();
        if (sessionData && isLoggedIn && currentWebshop === webshop) {
            callback(sessionData);
        }
    };
    return SessionStorageData;
}());
export { SessionStorageData };
