var LuminousGallery = require('luminous-lightbox').LuminousGallery;
import Swiper from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
var CarouselHandler = /** @class */ (function () {
    function CarouselHandler(carousel) {
        this.arrowDisabledClass = 'm-carouselArrows--disabled';
        this.$carousel = carousel;
        this.$prevArrow = this.$carousel.querySelector('.m-carouselArrows__left');
        this.$nextArrow = this.$carousel.querySelector('.m-carouselArrows__right');
        this.$slidesArr = this.$carousel.querySelectorAll('.m-pdpMediaCarousel__content__media');
        this.$swiperContentArr = this.$carousel.querySelectorAll('.swiper-container');
        this.init();
    }
    CarouselHandler.prototype.createCarousel = function (swiper) {
        SwiperCore.use([Navigation]);
        new Swiper(swiper, {
            slidesPerView: 'auto',
            allowTouchMove: true,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: this.$nextArrow,
                prevEl: this.$prevArrow,
                disabledClass: this.arrowDisabledClass
            },
            breakpoints: {
                768: {
                    slidesPerGroup: 2,
                },
                1152: {
                    slidesPerGroup: 3,
                    slidesPerView: 3,
                    allowTouchMove: false
                }
            }
        });
    };
    CarouselHandler.prototype.openLigthbox = function () {
        var iframeDivClass = 'a-lightbox-iframe-container';
        var videoClass = 'a-lightbox-video';
        var videoInfoDivClass = 'a-lightbox-video-info';
        var luminousOpts = {
            namespace: 'a-lightbox',
            sourceAttribute: 'href',
            caption: function (trigger) {
                var number = trigger.querySelector('img').getAttribute('number');
                var mediaType = trigger.getAttribute('media-type');
                var isVideo = mediaType === 'is-video';
                var isEmbeddedVideo = mediaType === 'is-embedded-video';
                var openedLightbox = document.querySelector('.a-lightbox-open');
                var lightboxWrappers = (openedLightbox || document).querySelectorAll('.a-lightbox-lightbox-position-helper');
                lightboxWrappers.forEach(function (lightboxWrapper) {
                    var img = lightboxWrapper.querySelector('img');
                    var iframeDiv = lightboxWrapper.querySelector(".".concat(iframeDivClass));
                    var video = lightboxWrapper.querySelector('video');
                    var videoInfoDiv = lightboxWrapper.querySelector(".".concat(videoInfoDivClass));
                    if ((isVideo || isEmbeddedVideo) && img) {
                        lightboxWrapper.removeChild(img);
                    }
                    if (isVideo) {
                        if (iframeDiv) {
                            lightboxWrapper.removeChild(iframeDiv);
                        }
                        if (!video) {
                            video = document.createElement('video');
                            video.className = videoClass;
                            lightboxWrapper.insertBefore(video, lightboxWrapper.hasChildNodes() ? lightboxWrapper.childNodes[0] : null);
                            video.addEventListener('loadeddata', (function () {
                                document.querySelectorAll('.a-lightbox-lightbox').forEach(function (element) { return element.classList.remove('lum-loading'); });
                            }));
                        }
                        video.src = trigger.getAttribute('href');
                        video.autoplay = true;
                        if (!videoInfoDiv) {
                            videoInfoDiv = document.createElement('div');
                            videoInfoDiv.className = videoInfoDivClass;
                            videoInfoDiv.hidden = true;
                            lightboxWrapper.appendChild(videoInfoDiv);
                        }
                        videoInfoDiv.innerText = video.src;
                    }
                    else if (isEmbeddedVideo) {
                        if (video) {
                            lightboxWrapper.removeChild(video);
                        }
                        if (videoInfoDiv) {
                            lightboxWrapper.removeChild(videoInfoDiv);
                        }
                        if (!iframeDiv) {
                            iframeDiv = document.createElement('div');
                            iframeDiv.className = iframeDivClass;
                            lightboxWrapper.insertBefore(iframeDiv, lightboxWrapper.hasChildNodes() ? lightboxWrapper.childNodes[0] : null);
                        }
                        iframeDiv.innerHTML = trigger.parentElement.querySelector("[embedded-video-id=\"".concat(number, "\"]")).innerHTML;
                        document.querySelectorAll('.a-lightbox-lightbox').forEach(function (element) { return element.classList.remove('lum-loading'); });
                    }
                });
                return number;
            },
            openTrigger: 'click',
            closeTrigger: 'click',
            onOpen: function () { },
            onClose: function () {
                var lightboxWrapper = document.querySelector('.a-lightbox-open .a-lightbox-lightbox-position-helper');
                var video = lightboxWrapper.querySelector('video');
                var iframe = lightboxWrapper.querySelector('iframe');
                if (video) {
                    video.pause();
                    video.currentTime = 0;
                }
                else if (iframe) {
                    iframe.src = '';
                }
            },
            closeWithEscape: true,
            closeOnScroll: false,
            showCloseButton: true,
            appendToNode: document.body,
            includeImgixJSClass: false,
            injectBaseStyles: true
        };
        var galleryOpts = {
            arrowNavigation: true
        };
        new LuminousGallery(Array.prototype.slice.call(this.$slidesArr), galleryOpts, luminousOpts);
    };
    CarouselHandler.prototype.init = function () {
        var _this = this;
        this.$swiperContentArr.forEach(function (swiper) { return _this.createCarousel(swiper); });
        this.openLigthbox();
    };
    return CarouselHandler;
}());
export { CarouselHandler };
