var AddToFavouritesTrigger = /** @class */ (function () {
    function AddToFavouritesTrigger(element) {
        this.$btn = element;
        this.$favouriteModal = document.querySelector('#addToFavorites');
        this.$productCodeInput = this.$favouriteModal.querySelector('input[name="productCode"]');
        this.init();
    }
    AddToFavouritesTrigger.prototype.setFavouriteModalData = function (event) {
        var $btn = event.currentTarget;
        var productCode = $btn.getAttribute('data-code');
        var variantCode = $btn.getAttribute('data-variant');
        this.$productCodeInput.value = variantCode ? variantCode : productCode;
    };
    AddToFavouritesTrigger.prototype.init = function () {
        var _this = this;
        this.$btn.addEventListener('click', function (event) { return _this.setFavouriteModalData(event); });
    };
    return AddToFavouritesTrigger;
}());
export { AddToFavouritesTrigger };
