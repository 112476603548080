var MultilineDropdown = /** @class */ (function () {
    function MultilineDropdown(element) {
        this.inputActiveClass = 'a-multilineDropdown__trigger--active';
        this.dropdownActiveClass = 'a-multilineDropdown__content--visible';
        this.$trigger = element.querySelector('.a-multilineDropdown__trigger');
        this.$triggerValue = this.$trigger.querySelector('.a-multilineDropdown__trigger__value');
        this.$contentContainer = element.querySelector('.a-multilineDropdown__content');
        this.$values = Array.prototype.slice.call(this.$contentContainer.querySelectorAll('.a-multilineDropdown__content__value'));
        this.$valueHolder = element.querySelector('input[type=hidden]');
        this.init();
    }
    MultilineDropdown.prototype.init = function () {
        var _this = this;
        this.$trigger.addEventListener('click', function () {
            _this.toggleDropdown();
        });
        this.$values.forEach(function (value) {
            value.addEventListener('click', function () {
                _this.selectValue(value);
            });
        });
        document.addEventListener('click', function (event) {
            if (!_this.$contentContainer.contains(event.target) && !_this.$trigger.contains(event.target)) {
                _this.closeDropdown();
            }
        });
    };
    MultilineDropdown.prototype.closeDropdown = function () {
        var $activeDropdown = document.querySelector('.' + this.inputActiveClass);
        if ($activeDropdown) {
            this.$trigger.classList.remove(this.inputActiveClass);
            this.$contentContainer.classList.remove(this.dropdownActiveClass);
        }
    };
    MultilineDropdown.prototype.toggleDropdown = function () {
        this.$trigger.classList.add(this.inputActiveClass);
        this.$contentContainer.classList.add(this.dropdownActiveClass);
    };
    MultilineDropdown.prototype.selectValue = function (value) {
        var val = value.getAttribute('data-value');
        var selectedValue = value.innerHTML;
        this.$valueHolder.value = val;
        this.$triggerValue.innerHTML = selectedValue;
        this.closeDropdown();
    };
    return MultilineDropdown;
}());
export { MultilineDropdown };
