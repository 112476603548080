var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ListOfProducts } from "./list-of-products";
import * as app from "../../main";
var OffersListPage = /** @class */ (function (_super) {
    __extends(OffersListPage, _super);
    function OffersListPage() {
        var _this = _super.call(this) || this;
        _this.getProductsPricesEndpoint = '/my-profile/inquiries/product/price/';
        _this.addAllProductsEndpoint = '/my-profile/inquiries/addtocart';
        _this.$offersListPage = document.querySelector('.m-offersList');
        _this.$addAllBtnArr = _this.$offersListPage.querySelectorAll('.m-offersList__data__item__actionCell__btn:not([tooltip])');
        _this.$removeListBtnArr = _this.$offersListPage.querySelectorAll('.m-offersList__data__item__actionCell__link--remove');
        _this.$editListBtnArr = _this.$offersListPage.querySelectorAll('.m-offersList__data__item__actionCell__link--edit');
        _this.$deactivateListBtnArr = _this.$offersListPage.querySelectorAll('.m-offersList__data__item__actionCell__link--deactivate');
        _this.$cellsChevronArr = _this.$offersListPage.querySelectorAll('.m-offersList__data__item__arrow');
        _this.$editModal = _this.$offersListPage.querySelector('.m-offersListEditModal');
        _this.$editModalInput = _this.$editModal.querySelector('#offerList__editModal [name=uid]');
        _this.$removeModalInput = _this.$offersListPage.querySelector('#offerList__removeModal [name=uid]');
        _this.$deactivateModalInput = _this.$offersListPage.querySelector('#offerList__deactivateModal [name=uid]');
        _this.$removableElementArr = _this.$offersListPage.querySelectorAll('.m-productTile__delete');
        _this.page = _this.$offersListPage;
        _this.init();
        return _this;
    }
    OffersListPage.prototype.addAllProducts = function (event) {
        event.preventDefault();
        _super.prototype.addAll.call(this, this.addAllProductsEndpoint);
    };
    OffersListPage.prototype.setDataInEditModal = function (ev) {
        var $offer = app.default.getSnippets().closest(ev.target, '.m-offersList__data__item');
        var $nameField = $offer.querySelector('.m-offersList__data__item__cell__value--name');
        var name = $nameField.textContent;
        var $dateFromField = $offer.querySelector('.m-offersList__data__item__cell__value__dateFrom');
        var dateFrom = $dateFromField.getAttribute('date-from');
        var $dateToField = $offer.querySelector('.m-offersList__data__item__cell__value__dateTo');
        var dateTo = $dateToField.getAttribute('date-to');
        var $nameInput = this.$editModal.querySelector('[name="title"]');
        var $dateFromInput = this.$editModal.querySelector('[name="dateFrom"]');
        var $dateToInput = this.$editModal.querySelector('[name="dateTo"]');
        $nameInput.value = name;
        $dateFromInput.value = dateFrom;
        $dateToInput.value = dateTo;
    };
    OffersListPage.prototype.init = function () {
        var _this = this;
        this.$addAllBtnArr.forEach(function ($btn) { return $btn.addEventListener('click', function (event) { return _this.addAllProducts(event); }); });
        this.$removeListBtnArr.forEach(function ($btn) { return $btn.addEventListener('click', function (event) { return _super.prototype.setListIdInModal.call(_this, event, _this.$removeModalInput); }); });
        this.$deactivateListBtnArr.forEach(function ($btn) { return $btn.addEventListener('click', function (event) { return _super.prototype.setListIdInModal.call(_this, event, _this.$deactivateModalInput); }); });
        this.$editListBtnArr.forEach(function ($btn) { return $btn.addEventListener('click', function (event) {
            _this.setListIdInModal(event, _this.$editModalInput);
            _this.setDataInEditModal(event);
        }); });
        this.$cellsChevronArr.forEach(function ($chevron) { return $chevron.addEventListener('click', function (event) { return _super.prototype.getPricesOfProductsOnList.call(_this, _this.getProductsPricesEndpoint, event); }); });
        this.$removableElementArr.forEach(function ($removeElement) { return $removeElement.addEventListener('click', function (event) { return _super.prototype.removeProductFromList.call(_this, event, 'm-accordion__container'); }); });
    };
    return OffersListPage;
}(ListOfProducts));
export { OffersListPage };
