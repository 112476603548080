var BlinkingPoint = /** @class */ (function () {
    function BlinkingPoint(item) {
        this.$item = item;
        this.HIGHLIGHT_FEATURE_COOKIE_PATTERN = 'HighlightFeature-{id}';
        this.init();
    }
    BlinkingPoint.prototype.manageBlinking = function () {
        var crossSystemId = this.$item.getAttribute('data-shared-id');
        if (!this.isHighlightCookieSet(crossSystemId)) {
            this.$item.classList.add('a-blinkPoint');
            var blinkContainer_1 = document.createElement('span');
            blinkContainer_1.classList.add('a-blinkPoint__container');
            blinkContainer_1.classList.add('a-blinkPoint__container--visible');
            this.$item.appendChild(blinkContainer_1);
            document.cookie = "".concat(this.HIGHLIGHT_FEATURE_COOKIE_PATTERN.replace('{id}', crossSystemId), "=").concat(Date.now(), "; SameSite=Lax; Max-Age=31536000;");
            setTimeout(function () {
                blinkContainer_1.classList.remove('a-blinkPoint__container--visible');
            }, 5000);
        }
    };
    BlinkingPoint.prototype.isHighlightCookieSet = function (crossSystemId) {
        return document.cookie.includes(this.HIGHLIGHT_FEATURE_COOKIE_PATTERN.replace('{id}', crossSystemId));
    };
    BlinkingPoint.prototype.init = function () {
        this.manageBlinking();
    };
    return BlinkingPoint;
}());
export { BlinkingPoint };
