import axios from 'axios';
var PunchOut = /** @class */ (function () {
    function PunchOut() {
        this.$punchOutPage = document.querySelector('.m-punchOut');
        this.$loader = this.$punchOutPage.querySelector('.a-loader');
        this.init();
    }
    PunchOut.prototype.punchOutSimulate = function () {
        var _this = this;
        this.$loader.classList.add('a-loader--active');
        var requestUrl = '/checkout/punchout/simulate';
        axios.post(requestUrl, '', { headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            } })
            .then(function () {
            _this.sendOrderMessage();
        })
            .catch(function (error) {
            console.log(error);
            _this.displayErrorMessage();
        });
    };
    PunchOut.prototype.sendOrderMessage = function () {
        var _this = this;
        var requestUrl = '/punchout/cxml/requisition/message';
        var $punchOutForm = document.createElement('form');
        $punchOutForm.method = 'post';
        var $punchOutInput = document.createElement('input');
        $punchOutInput.type = 'hidden';
        $punchOutInput.name = 'cxml-base64';
        $punchOutForm.appendChild($punchOutInput);
        axios.get(requestUrl)
            .then(function (res) {
            $punchOutForm.action = res.data.browseFormPostUrl;
            $punchOutInput.value = res.data.orderAsCXML;
            document.querySelector('body').appendChild($punchOutForm);
            $punchOutForm.submit();
            _this.$loader.classList.remove('a-loader--active');
        })
            .catch(function () { return _this.displayErrorMessage(); });
    };
    PunchOut.prototype.displayErrorMessage = function () {
        var $errorModal = document.querySelector('.m-punchOut__modal');
        $errorModal.parentElement.classList.add('m-modal--active');
        this.$loader.classList.remove('a-loader--active');
    };
    PunchOut.prototype.init = function () {
        this.punchOutSimulate();
    };
    return PunchOut;
}());
export { PunchOut };
