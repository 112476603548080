var CartHeader = /** @class */ (function () {
    function CartHeader(cartPathSelector) {
        this.$cartPathSelector = cartPathSelector;
        this.$carousel = document.querySelector('.m-sectionGrid--recent');
        this.$header = document.querySelector('.m-cartHeader');
        this.$sections = this.$header.querySelectorAll('.m-cartHeader__summary__description');
        this.$dealerTabs = document.querySelectorAll('.m-cartHeader .m-tabPanel__header__content__tabs__name');
        this.init();
    }
    CartHeader.prototype.changeHeader = function () {
        var _a, _b, _c;
        var activeTab = (_a = this.$header) === null || _a === void 0 ? void 0 : _a.querySelector('.m-tabPanel__header__content__tabs__name--active');
        var dealer = activeTab === null || activeTab === void 0 ? void 0 : activeTab.getAttribute('data-tab');
        var headerToActive = (_b = this.$header) === null || _b === void 0 ? void 0 : _b.querySelector(".m-cartHeader__summary__description[data-dealer=\"".concat(dealer, "\"]"));
        var headerActiveClass = 'm-cartHeader__summary__description--active';
        if (headerToActive && !(headerToActive === null || headerToActive === void 0 ? void 0 : headerToActive.classList.contains(headerActiveClass))) {
            var activeHeader = (_c = this.$header) === null || _c === void 0 ? void 0 : _c.querySelector('.m-cartHeader__summary__description--active');
            activeHeader === null || activeHeader === void 0 ? void 0 : activeHeader.classList.remove(headerActiveClass);
            headerToActive === null || headerToActive === void 0 ? void 0 : headerToActive.classList.add('m-cartHeader__summary__description--active');
            if (this.$carousel) {
                this.setSeparatorMarginsForRecentlyViewedProducts(headerToActive);
            }
        }
    };
    CartHeader.prototype.setSeparatorMarginsForRecentlyViewedProducts = function (headerToActive) {
        var contentSize = parseInt(headerToActive.getAttribute('contentSize')) || 0;
        if (contentSize > 0) {
            this.$recentlyViewedProductsComponent.classList.add('m-cartPathSelection__separator');
            this.$recentlyViewedProductsComponent.classList.remove('m-cartPathSelection__emptyCartSeparator');
        }
        else {
            this.$recentlyViewedProductsComponent.classList.add('m-cartPathSelection__emptyCartSeparator');
            this.$recentlyViewedProductsComponent.classList.remove('m-cartPathSelection__separator');
        }
    };
    CartHeader.prototype.init = function () {
        var _this = this;
        if (this.$carousel) {
            this.$recentlyViewedProductsComponent = document.querySelector('.m-recentlyViewedProducts');
        }
        this.changeHeader();
        this.$dealerTabs.forEach(function (tab) {
            tab.addEventListener('click', function (event) {
                _this.changeHeader();
                if (_this.$cartPathSelector) {
                    _this.$cartPathSelector.selectedTabChangeHandler();
                }
            });
        });
    };
    return CartHeader;
}());
export { CartHeader };
