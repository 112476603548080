import "../sass/style.scss";

import "./vendor/magiczooomplus/magiczoomplus";
import "./vendor/magicscroll/magicscroll";

import 'abortcontroller-polyfill';
import { GatewayMachines } from "./app/device/gateway-machines";
import { Gigya } from "./app/gigya/gigya"
import { DetectBrowser } from "./app/detect-browser";
import { AxiosSettings } from "./app/axios-settings";
import { RemoveShadow } from "./app/remove-shadow";
import "./globals";
import "simplebar";
import { FormValidation} from "./app/form-validation";
import { CustomSelect } from "./app/custom-select";
import { Machines } from "./app/device/machines";
import { GatewayCountrySelector } from "./app/gateway-country-selector";
import { MainMenu } from "./app/header/main-menu";
import { Search } from "./app/header/search";
import { LanguageSelector } from "./app/header/language-selector";
import "./app/notification/global-alerts";
import { AddToFavoritesModal } from "./app/modals/add-to-favorites";
import { ProductTileHover } from "./app/product/product-tile-hover";
import { Counter } from "./app/product/counter";
import { VariantsHandler } from "./app/product/variants";
import { CartCounter } from "./app/product/cart-counter";
import { DownloadsHandler } from "./app/product/download-section";
import { VariantsSection } from "./app/product/variant-section";
import { Accordion } from "./app/accordion";
import { PdpTabPanel } from "./app/product/tabs";
import { CarouselHandler } from "./app/product/carousel";
import { ProductsCarouselHandler } from "./app/product/products-carousel";
import { CreateRater } from "./app/product/create-rater";
import { SeeRatingsHandler } from "./app/product/open-rating-tab";
import { GallerySectionHandler } from "./app/product/gallery";
import { DisplayRater } from "./app/display-rater";
import { Tooltip } from "./app/tooltip";
import { Modal } from "./app/modals/modal";
import { LanguageSelectorModalContent } from "./app/modals/language-selector-modal-content";
import { IndirectCheckoutModal } from "./app/modals/indirect-checkout-modal";
import { ServiceRequestModal } from "./app/modals/service-request-modal";
import { TabPanel } from "./app/tabs/tab-panel";
import { SearchFilterButton } from "./app/plp/search-filter-button";
import "./app/checkout/card-selector";
import Svg4Everybody from "svg4everybody";
import { FilterPanel } from "./app/plp/filter-panel";
import { HeroBrands } from "./app/plp/hero-brands";
import { QuickOrder } from "./app/quickorder/quickorder";
import { AddToCart } from "./app/add-to-cart";
import { CartItemsIndicator } from "./app/cart-items-indicator/cart-items-indicator";
import { Matrix } from "./app/variant-matrix/variant-matrix";
import { RemoveFromCart } from "./app/remove-from-cart";
import { MultilineDropdown } from "./app/dropdowns/multiline-dropdown";
import { CartHeader } from "./app/checkout/cart-header";
import { PaymentModeForm } from "./app/checkout/payment-mode-form";
import { OrderSimulation } from "./app/checkout/order-simulation";
import { ShippingMethod } from "./app/checkout/shipping-method";
import { IndirectDealerFormHandler } from "./app/checkout/indirect-dealer-form-handler";
import { IndirectCheckout } from "./app/checkout/indirect-checkout";
import { MyAccount } from "./app/my-account/my-account";
import { Devices } from "./app/my-account/devices";
import { FavoritesListPage } from "./app/my-account/favorites-list";
import { PreferredDealersPage } from "./app/my-account/preferred-dealers";
import { SnippetsPanel } from "./app/snippets";
import { SearchCategories } from "./app/search-categories";
import { PunchOut } from "./app/punchout";
import { BlinkingPoint } from "./app/blinking-points";
import { ServiceRequestContactUsModal } from "./app/modals/service-request-contact-us-modal";
import { ServiceRequests } from "./app/my-account/service-requests";
import { CartSummary } from "./app/checkout/cart-summary";
import { AddToFavouritesTrigger } from "./app/product/add-to-favourites";
import { PlpHeader } from "./app/plp/plp-header";
import { SessionStorageData } from "./app/session-data/session-storage-data";
import { CartPathSelector } from "./app/checkout/cart-path-selector";
import { OfferForm } from "./app/offer/offer-form";
import { OffersListPage } from "./app/my-account/offers-list";
import {DataRange} from "./app/date-range";
import {Toolbar} from "./app/toolbar";
import {AgentSearchProducts} from "./app/agent/agent-search-products";
import {AgentImpersonateCustomer} from "./app/agent/agent-impersonate-customer";
import {CheckoutIndicator} from "./app/checkout/checkout-indicator";
import {TeaserComponent} from "./app/plp/teaser-component";
import {OrderDetails} from "./app/my-account/order-details";
import {CartDetails} from "./app/my-account/cart-details";
import {ScrollToTop} from "./app/scroll-to-top";
import {ErrorHandler} from "./app/error-handler";

class Main {
  $formsToValid = document.querySelectorAll('.formToValid');
  $customSelectArr= document.querySelectorAll('.a-customSelect');
  $machinesPage = document.querySelector('.m-registrationChoice');
  $dealerTabs = document.querySelectorAll('.m-cartHeader .m-tabPanel__header__content__tabs__name');
  $pageIndirectCheckout = document.querySelector('.o-indirectCheckout');
  $dealerHandler = document.querySelector('.m-checkoutSummary');
  $orderSimulation = document.querySelector('.o-paymentPage__content__simulation');
  $paymentMode = document.querySelector('#paymentForm');
  $shipping = document.querySelector('.o-deliveryPage__content__shippingMethod__deliveryModes');
  $dropdowns = document.querySelectorAll('.a-multilineDropdown');
  $languageSelector = document.querySelector('.m-countrySelector');
  $searchElement = document.querySelector('.m-search');
  $mainMenu = document.querySelector('.o-header');
  $myAccountElement = document.querySelector('.o-myAccount');
  $myAccountMenu = document.querySelector('.m-myAccountMenu');
  $devicePage = document.querySelector('.m-machines');
  $myAccountFavoriteListPage = document.querySelector('.m-favoritesList');
  $myAccountOffersListPage = document.querySelector('.m-offersList');
  $myAccountPreferredDealersPage = document.querySelector('.m-preferredDealers');
  $counterArr = document.querySelectorAll('.a-counter');
  $multiVariantsProductArr = document.querySelectorAll('.productForm[data-variants*="variant"]');
  $cartCounterArr = document.querySelectorAll('.updateEntryQty .a-counter');
  $downloadSection = document.querySelector('.m-pdpDownloadSection');
  $variantsSection = document.querySelector('.m-pdpVariantSection');
  $removeFromCartButtons = document.querySelectorAll('.m-removeCartEntryBtn');
  $elementWithTooltipArr = document.querySelectorAll('[tooltip]');
  $pdpTabPanel = document.querySelector('.m-pdpTabPanel');
  $productsTiles = document.querySelectorAll('.m-productTile');
  $modalArr = document.querySelectorAll('.m-modal');
  $gatewayCountrySelector = document.querySelector('.m-gatewayModal--languageSelector');
  $raterArray = document.querySelectorAll('.a-rater--castVote');
  $favModal = document.querySelector('.m-favoritesModal');
  $contentArr = document.querySelectorAll('.m-accordion');
  $carouselArr = document.querySelectorAll('.m-pdpMediaCarousel');
  $productsCarouselArr = document.querySelectorAll('.m-productsCarouselSection__carousel');
  $rater = document.querySelector('.m-pdpRating--active');
  $seeRatingsBtn = document.querySelector('.m-pdpBaseData__rating__link');
  $gallery = document.querySelector('.m-pdpZoomGallery');
  $addToCartButtons = document.querySelectorAll('.m-productOrderPossibility__addBtn--addToCart');
  $quickOrderElement = document.querySelector('.o-quickOrder');
  $matrixElement = document.querySelector('.o-variant-matrix');
  $tabPanels = document.querySelectorAll('.m-tabPanel');
  $filterPanel = document.querySelector('.m-filterPanel');
  $heroPanel = document.querySelector('.m-heroBrands');
  $languageSelectorContent = document.querySelector('.m-countrySelector:not(.m-gatewayModal__content)');
  $indirectCheckoutModal = document.querySelector('.m-indirectCheckoutModal');
  $serviceRequestModal = document.querySelector('#service-request-modal');
  $gatewayMachineComponent = document.querySelector('.m-gatewayModal--optionsChoice');
  $filterButtonArr = document.querySelectorAll('.m-plpFilter__btn');
  $numberOfProducts = document.querySelector('.-logged-in .m-mainMenu__actions__basket');
  $searchPageCategories = document.querySelector('.m-searchCategories');
  $punchOutPage= document.querySelector('.m-punchOut');
  $blinkingPointArr= document.querySelectorAll('[data-shared-id]');
  $serviceRequestContactUsModal = document.querySelector('#service-request-contact-us-modal');
  $serviceRequestsPage = document.querySelector('.m-serviceRequests');
  $cartSummaryArr = document.querySelectorAll('.m-cartSummary');
  $favTriggerArr = document.querySelectorAll('.m-favouriteHeart--modal');
  $plpHeader = document.querySelector('.m-plpHeader:not(.m-plpHeader--preselectedProducts)');
  $cartPathSelector = document.querySelector('.m-cartPathSelection');
  $offerForm = document.querySelector('#offerForm');
  $dateRangeComponent = document.querySelector('.m-dateRange');
  $agentSearchBtn = document.querySelector('.m-agentLandingPageContent__searchBtn');
  $impersonateCustomerElement = document.querySelector('.m-agentLandingPageHeader__customerElement');
  $checkoutIndicator = document.querySelector('.m-checkoutIndicator');
  $teaserComponent = document.querySelector('.m-plpGrid__teaser');
  $orderDetails = document.querySelector('.m-orderDetails');
  $cartDetails = document.querySelector('.m-cartDetails');

  constructor() {}

  init() {
    new ErrorHandler();
    Svg4Everybody();
    new DetectBrowser();
    new AxiosSettings();
    new RemoveShadow();
    this.SnippetsPanelObj = Object.seal(new SnippetsPanel());
    this.SessionStorageDataObj = Object.seal(new SessionStorageData());

    if (window.gigya != undefined) {
      new Gigya();
    }

    if (this.$customSelectArr.length > 0) {
      this.$customSelectArr.forEach(select => {
        this.SelectArr.push({
          element: select,
          obj: new CustomSelect(select)
        });
      });
    }

    if(this.$favTriggerArr.length > 0) {
      this.$favTriggerArr.forEach(favTrigger => new AddToFavouritesTrigger(favTrigger));
    }
    if (this.$pageIndirectCheckout) {
      new IndirectCheckout();
    }

    if (this.$dealerHandler) {
      new IndirectDealerFormHandler();
    }

    if (this.$orderSimulation) {
      new OrderSimulation();
    }

    if (this.$paymentMode) {
      new PaymentModeForm();
    }

    if (this.$shipping) {
      new ShippingMethod();
    }

    if (this.$dropdowns.length) {
      let dropdownsArr = Array.prototype.slice.call(this.$dropdowns);
      dropdownsArr.forEach((dropdownEl) => {
        new MultilineDropdown(dropdownEl);
      })
    }

    if (this.$languageSelector) {
      new LanguageSelector(this.$languageSelector);
    }

    if (this.$searchElement) {
      this.SearchObj = Object.seal(new Search());
    }

    if (this.$mainMenu) {
      this.MainMenuObj = Object.seal(new MainMenu());
    }

    if (this.$myAccountElement && this.$myAccountMenu) {
      new MyAccount();
    }

    if (this.$devicePage) {
      new Devices();
    }

    if (this.$myAccountFavoriteListPage) {
      new FavoritesListPage();
    }

    if (this.$myAccountOffersListPage) {
      new OffersListPage();
    }

    if(this.$myAccountPreferredDealersPage) {
      new PreferredDealersPage();
    }

    if(this.$machinesPage) {
      new Machines();
    }

    if (this.$counterArr.length > 0) {
      this.$counterArr.forEach(counter => {
        new Counter(counter);
      });
    }

    if (this.$cartCounterArr.length > 0) {
      this.$cartCounterArr.forEach(counter => {
        new CartCounter(counter);
      });
    }

    if (this.$multiVariantsProductArr.length > 0) {
      this.$multiVariantsProductArr.forEach(product => {
        new VariantsHandler(product);
      });
    }

    if (this.$downloadSection) {
      new DownloadsHandler();
    }

    if (this.$variantsSection) {
      new VariantsSection();
    }

    if (this.$removeFromCartButtons.length > 0) {
      Array.prototype.slice.call(this.$removeFromCartButtons)
        .forEach((element) => {
          new RemoveFromCart(element);
        });
    }

    if (this.$elementWithTooltipArr.length > 0) {
      this.$elementWithTooltipArr.forEach((element, index) => {
        new Tooltip(element, index);
      });
    }

    if (this.$pdpTabPanel) {
      new PdpTabPanel();
    }

    if(this.$productsTiles.length > 0) {
      this.$productsTiles.forEach(tile => {
        new ProductTileHover(tile);
      })
    }

    if (this.$modalArr.length > 0) {
      this.$modalArr.forEach(modal => {
        this.ModalObjArr.push({
          element: modal,
          obj: new Modal(modal)
        });
      });
    }

    if (this.$raterArray.length) {
      let raterArray = Array.prototype.slice.call(this.$raterArray);
      raterArray.forEach((rater) => {
        new DisplayRater(rater);
      })
    }

    if (this.$carouselArr.length > 0) {
      this.$carouselArr.forEach(carousel => {
        new CarouselHandler(carousel);
      });
    }

    if (this.$contentArr.length > 0) {
      this.$contentArr.forEach(content => {
        this.AccordionObjArr.push({
          element: content,
          obj: new Accordion(content)
        });
      });
    }

    if (this.$productsCarouselArr.length > 0) {
      this.$productsCarouselArr.forEach(carousel => {
        new ProductsCarouselHandler(carousel);
      });
    }

    if (this.$rater) {
      new CreateRater();
    }

    if (this.$seeRatingsBtn) {
      new SeeRatingsHandler();
    }

    if (this.$gallery) {
      new GallerySectionHandler();
    }

    if (this.$addToCartButtons.length > 0) {
      Array.prototype.slice.call(this.$addToCartButtons)
        .forEach((button) => new AddToCart(button));
    }

    if (this.$quickOrderElement) {
      new QuickOrder();
    }

    if (this.$matrixElement) {
      new Matrix();
    }

    if (this.$tabPanels.length) {
      let tabArray = Array.prototype.slice.call(this.$tabPanels);
      tabArray.forEach((panel) => {
        this.TabPanelObjArr.push({
          element: panel,
          obj: new TabPanel(panel)
        });
      })
    }

    if (this.$filterPanel) {
      this.FilterPanelObj = Object.seal(new FilterPanel());

     if(this.$filterButtonArr) {

       this.$filterButtonArr.forEach((btn) => {
         new SearchFilterButton(btn);
       })
     }
    }

    if (this.$heroPanel) {
      new HeroBrands();
    }

    if (this.$languageSelectorContent) {
      new LanguageSelectorModalContent(this.$languageSelectorContent);
    }

    if (this.$gatewayCountrySelector) {
      new GatewayCountrySelector(this.$gatewayCountrySelector);
    }

    if (this.$indirectCheckoutModal) {
      new IndirectCheckoutModal();
    }

    if (this.$serviceRequestsPage) {
      new ServiceRequests();
    }

    if (this.$serviceRequestModal) {
      new ServiceRequestModal();
    }

    if (this.$serviceRequestContactUsModal) {
      new ServiceRequestContactUsModal();
    }

    if (this.$favModal) {
      new AddToFavoritesModal();
    }

    if (this.$gatewayMachineComponent) {
      new GatewayMachines();
    }

    if (this.$numberOfProducts) {
      new CartItemsIndicator();
    }

    if (this.$cartPathSelector) {
      this.CartPathSelectorObj = Object.seal(new CartPathSelector());
    }

    if (this.$dealerTabs.length) {
      new CartHeader(this.CartPathSelectorObj);
    }

    if (this.$searchPageCategories) {
      new SearchCategories();
    }

    if (this.$punchOutPage) {
      new PunchOut();
    }

    if(this.$blinkingPointArr.length) {
      this.$blinkingPointArr.forEach(($point) => {
        new BlinkingPoint($point);
      })
    }

    if(this.$cartSummaryArr.length) {
      this.$cartSummaryArr.forEach(cartSummary => new CartSummary(cartSummary));
    }

    if(this.$plpHeader) {
      new PlpHeader();
    }

    if (this.$offerForm) {
      new OfferForm();
    }

    if (this.$formsToValid.length) {
      Array.prototype.slice.call(this.$formsToValid).forEach(form => {
        this.FormValidationArr.push({
          id: form.id,
          validationForm: new FormValidation(form)
        });
      });
    }

    if (this.$dateRangeComponent) {
      new DataRange();
    }

    if (this.$agentSearchBtn) {
      new AgentSearchProducts(this.$agentSearchBtn);
    }

    if (this.$impersonateCustomerElement) {
      new AgentImpersonateCustomer();
    }

    if (this.$checkoutIndicator) {
      new CheckoutIndicator();
    }

    if(this.$teaserComponent) {
      new TeaserComponent();
    }

    if (this.$orderDetails) {
      new OrderDetails();
    }
    if (this.$cartDetails) {
      new CartDetails();
    }

    new ScrollToTop();

    new Toolbar();
  }

  FilterPanelObj;
  SnippetsPanelObj;
  SearchObj;
  MainMenuObj;
  AccordionObjArr = [];
  TabPanelObjArr = [];
  FormValidationArr = [];
  SelectArr = [];
  SessionStorageDataObj;
  ModalObjArr = [];
  CartPathSelectorObj;

  getFilter() {
    return this.FilterPanelObj;
  }

  getSnippets() {
    return this.SnippetsPanelObj;
  }

  getValidation(formId = '') {
    if (!this.FormValidationArr.length || !formId) return null;
    const validationFormObj = this.FormValidationArr.find(form => form.id === formId) || this.FormValidationArr[0];
    return validationFormObj ? validationFormObj.validationForm : null;
  }

  getSearch() {
    return this.SearchObj;
  }

  getMainMenu() {
    return this.MainMenuObj;
  }

  getAccordionArr() {
    return this.AccordionObjArr;
  }

  getTabPanelArr() {
    return this.TabPanelObjArr;
  }

  getSelectArr() {
    return this.SelectArr;
  }

  sessionStorageData() {
    return this.SessionStorageDataObj;
  }

  getModalArr() {
    return this.ModalObjArr;
  }
}

let app = new Main();
document.addEventListener("DOMContentLoaded", () => app.init());

export default app;