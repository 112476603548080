import { Tooltip } from "../tooltip";
import { CartSummary } from "./cart-summary";
import axios from 'axios';
import * as app from '../../main';
import alertObject from '../notification/global-alerts';
import { AlertFactory } from "../notification/alert-factory";
var OrderSimulation = /** @class */ (function () {
    function OrderSimulation() {
        this.$simulationContainer = document.querySelector('.o-paymentPage__content__simulation');
        this.$simulationLoader = this.$simulationContainer.querySelector('.o-paymentPage__content__simulation__loader');
        this.$simulationResult = this.$simulationContainer.querySelector('.o-paymentPage__content__simulation__result');
        this.$paymentForm = document.querySelector('#paymentForm');
        this.$customerEmailInput = this.$paymentForm.querySelector('input[id=customerEmail]');
        this.$creditCardRadio = this.$paymentForm.querySelector('input[id=creditCardRadio]');
        this.$netTermsRadio = this.$paymentForm.querySelector('input[id=netTermsRadio]');
        this.$paymetricIFrame = this.$paymentForm.querySelector('iframe');
        this.$pageLoader = document.querySelector('.a-loader');
        this.startTime = new Date().getTime();
        if (this.$paymetricIFrame != null) {
            this.paymetricTimeout = parseInt(this.$paymetricIFrame.getAttribute('data-timeout')) * 1000;
        }
        else {
            this.paymetricTimeout = 999999;
        }
        this.init();
    }
    OrderSimulation.prototype.performOrderSimulation = function () {
        var _this_1 = this;
        this.$simulationLoader.classList.add('a-loader--active');
        var requestUrl = '/checkout/simulate';
        axios.post(requestUrl, '', { headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            } })
            .then(function (res) {
            _this_1.$simulationResult.innerHTML = res.data;
            _this_1.$simulationLoader.classList.remove('a-loader--active');
            _this_1.connectEventHandlersToOrderSimulation();
            _this_1.showDelayedProductsMessage();
            _this_1.$btnOrderArr = document.querySelectorAll('.a-greenBlueBtn');
            document.querySelector('.o-paymentPage__heading--notsupported')
                && _this_1.$btnOrderArr.forEach(function (btn) { return btn.classList.add('a-greenBlueBtn--disabled'); });
            _this_1.$btnOrderArr.forEach(function (btn) {
                btn.addEventListener('click', function () { return _this_1.order(); });
                btn.classList.add('formToValid__submitBtn');
            });
            var formValidation = app.default.getValidation(_this_1.$paymentForm.id);
            if (formValidation) {
                formValidation.reinitFormValidation();
            }
            if (_this_1.$customerEmailInput) {
                _this_1.validateEmailOnChange();
                _this_1.$customerEmailInput.addEventListener('input', function () { return _this_1.validateEmailOnChange(); });
            }
        })
            .catch(function (error) { return console.log(error); });
    };
    OrderSimulation.prototype.showDelayedProductsMessage = function () {
        var $simulation = this.$simulationResult.querySelector('.m-orderSimulation');
        var showDelayedProductMessage = $simulation && $simulation.hasAttribute('data-delayed-message') && $simulation.hasAttribute('data-delayed-title');
        if (showDelayedProductMessage) {
            var alertFactoryObject = new AlertFactory();
            var element = alertFactoryObject.createAlert($simulation.getAttribute('data-delayed-message'), $simulation.getAttribute('data-delayed-title'), 'info', { 'animated': true, 'closable': true });
            alertObject.showAlert(element);
        }
    };
    OrderSimulation.prototype.connectEventHandlersToOrderSimulation = function () {
        var _this_1 = this;
        var $tooltipArr = Array.prototype.slice.call(this.$simulationResult.querySelectorAll('[tooltip]'));
        var $reloadBtn = this.$simulationResult.querySelector('.m-orderSimulation__reload');
        $tooltipArr.forEach(function ($tooltip, index) {
            new Tooltip($tooltip, index);
        });
        if ($reloadBtn != null) {
            $reloadBtn.addEventListener('click', function (event) {
                event.preventDefault();
                _this_1.performOrderSimulation();
            });
        }
        var $cartSummary = this.$simulationResult.querySelector('.m-cartSummary');
        if ($cartSummary) {
            new CartSummary($cartSummary);
        }
    };
    OrderSimulation.prototype.enableSubmitButtons = function () {
        this.$btnOrderArr.forEach(function (btn) { return btn.classList.remove('a-greenBlueBtn--disabled'); });
    };
    OrderSimulation.prototype.enableClickableSubmitButtons = function () {
        this.$btnOrderArr.forEach(function (btn) { return btn.classList.remove('a-greenBlueBtn--clickableDisabled'); });
    };
    OrderSimulation.prototype.disableClickableSubmitButtons = function () {
        this.$btnOrderArr.forEach(function (btn) { return btn.classList.add('a-greenBlueBtn--clickableDisabled'); });
    };
    OrderSimulation.prototype.validateEmailOnChange = function () {
        if (this.$customerEmailInput.checkValidity()) {
            this.enableClickableSubmitButtons();
        }
        else {
            this.disableClickableSubmitButtons();
        }
    };
    OrderSimulation.prototype.order = function () {
        var formValidation = app.default.getValidation(this.$paymentForm.id);
        if (formValidation) {
            formValidation.validateFields();
        }
        if (this.$paymentForm.checkValidity()) {
            this.$btnOrderArr.forEach(function (btn) { return btn.classList.add('a-greenBlueBtn--disabled'); });
            this.submitOrder();
        }
    };
    OrderSimulation.prototype.submitOrder = function () {
        var paymentType = this.$paymentForm.querySelector('input[name=paymentType]:checked').value;
        var $creditCartInput = this.$paymentForm.querySelector('input[name=creditCardPaymentType]');
        var creditCardPaymentType = '';
        if ($creditCartInput != null) {
            creditCardPaymentType = $creditCartInput.value;
            if (paymentType === "CREDIT_CARD") {
                paymentType = creditCardPaymentType;
            }
        }
        var _this = this;
        switch (paymentType) {
            case "NEW_CARD":
                if ((new Date()).getTime() - this.startTime > this.paymetricTimeout) {
                    location.reload(); // we can't use cached version, we need fresh access token
                    return;
                }
                window.$XIFrame.validate({
                    iFrameId: 'paymetricFrame',
                    targetUrl: _this.$paymetricIFrame.getAttribute('data-target'),
                    onValidate: function (isValid) {
                        if (isValid) {
                            window.$XIFrame.submit({
                                iFrameId: 'paymetricFrame',
                                targetUrl: _this.$paymetricIFrame.getAttribute('data-target'),
                                onSuccess: function () {
                                    window.$XIFrame.submit = function () {
                                        return true;
                                    };
                                    _this.submitPaymentForm();
                                },
                                onError: function () {
                                    _this.enableSubmitButtons();
                                }
                            });
                        }
                        else {
                            _this.enableSubmitButtons();
                        }
                    }
                });
                return;
            case "EXISTING_CARD":
                if (!creditCardPaymentType.length) {
                    return;
                }
                this.submitPaymentForm();
                break;
            case "NET_TERMS":
                this.submitPaymentForm();
                break;
        }
    };
    OrderSimulation.prototype.submitPaymentForm = function () {
        this.$paymentForm.submit();
        this.$pageLoader.classList.add('a-loader--active');
    };
    OrderSimulation.prototype.init = function () {
        this.performOrderSimulation();
    };
    return OrderSimulation;
}());
export { OrderSimulation };
