//pollyfills for ie11
var SnippetsPanel = /** @class */ (function () {
    function SnippetsPanel() {
        this.addCustomEventPolyfill();
    }
    SnippetsPanel.prototype.closest = function (el, selector) {
        var matchesFn;
        var parent;
        // find vendor prefix
        ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector']
            .some(function (fn) {
            if (typeof document.body[fn] == 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        });
        // traverse parents
        if (matchesFn) {
            while (el) {
                parent = (el.localName === 'svg' || el.localName === 'path') ? el.parentNode : el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        }
        return null;
    };
    SnippetsPanel.prototype.addCustomEventPolyfill = function () {
        if (typeof window.CustomEvent === 'function')
            return false; //If not IE
        function CustomEvent(type, eventInitDict) {
            if (eventInitDict === void 0) { eventInitDict = null; }
            eventInitDict = eventInitDict || { bubbles: false, cancelable: false, composed: undefined };
            var event = document.createEvent('CustomEvent');
            event.initCustomEvent(type, eventInitDict.bubbles, eventInitDict.cancelable, eventInitDict.composed);
            return event;
        }
        CustomEvent.prototype = window.Event.prototype;
        window.CustomEvent = CustomEvent;
    };
    return SnippetsPanel;
}());
export { SnippetsPanel };
