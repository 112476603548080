var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import * as app from "../../main";
var SavedFilters = /** @class */ (function () {
    function SavedFilters() {
        this.options = [];
        this.savedFiltersData = [];
        this.$filterPanel = document.querySelector('.m-filterPanel');
        this.$filterActionsPanel = this.$filterPanel.querySelector('.m-filterPanel__actions__filters');
        this.$savedFiltersContainer = this.$filterPanel.querySelector('.m-filter__container--savedFilters');
        this.$savedFiltersSection = this.$filterPanel.querySelector('.m-filter--savedFilters');
        this.$filterRaw = this.$filterPanel.querySelector('.m-filter__container__savedFilter--raw');
        this.$editBtn = this.$filterPanel.querySelector('.m-filter__header__edit');
        this.$savedFiltersArr = this.$filterPanel.querySelectorAll('.m-filter__container__savedFilter');
        this.$options = this.$filterPanel.querySelectorAll('.m-filter__container__option');
        this.$saveFilterModal = document.querySelector('#save-filter-modal');
        this.$closeModalBtn = this.$saveFilterModal.querySelector('#save-filter-modal .m-modal__content__close');
        this.$saveFilterModalInput = this.$saveFilterModal.querySelector('.a-input');
        this.$filterNameInput = document.querySelector('.m-plpHeader__panel__action__form input[name=filter]');
        this.reload = true;
        this.init();
    }
    SavedFilters.prototype.saveFilter = function (filterName, categoryCode) {
        var _this = this;
        var requestUrl = '/savedFilter/create';
        var data = {
            name: filterName,
            categoryCode: categoryCode,
            facets: this.options
        };
        axios.post(requestUrl, data)
            .then(function (res) {
            _this.filterToActivate = res.data.name;
            _this.refreshFilterList();
            _this.turnOffSavingFilters();
            _this.$closeModalBtn.click();
        })
            .catch(function (error) {
            if (error.response.status === 422) {
                _this.$saveFilterModalInput.classList.add('a-input--error');
            }
            console.log(error);
        });
    };
    SavedFilters.prototype.removeFilter = function (categoryCode, filterName) {
        var _this = this;
        var requestUrl = '/savedFilter/' + categoryCode + '/' + filterName;
        axios.delete(requestUrl)
            .then(function (res) { return _this.refreshFilterList(); })
            .catch(function (error) { return console.log(error); });
    };
    SavedFilters.prototype.refreshFilterList = function () {
        var _this = this;
        var location = window.document.location;
        var requestUrl = location.origin + location.pathname + "/savedfilters";
        axios.get(requestUrl)
            .then(function (res) {
            _this.createFiltersSection(res.data);
            if (_this.reload) {
                _this.selectFilter();
            }
        })
            .catch(function (error) { return console.log(error); });
    };
    SavedFilters.prototype.setFilterNameToForm = function (val) {
        this.$filterNameInput.value = encodeURIComponent(val);
    };
    SavedFilters.prototype.createFiltersSection = function (filters) {
        var _this = this;
        this.$savedFiltersContainer.innerHTML = '';
        this.savedFiltersData = filters;
        if (filters.length) {
            this.$savedFiltersSection.classList.remove('m-filter--hidden');
            filters.forEach(function (filter) {
                var $itemPattern = _this.$filterRaw.cloneNode(true);
                $itemPattern.classList.remove('m-filter__container__savedFilter--raw');
                if (filter.name === _this.filterToActivate) {
                    $itemPattern.classList.add('m-filter__container__savedFilter--selected');
                }
                $itemPattern.querySelector('.m-filter__container__savedFilter__text').textContent += filter.name;
                $itemPattern.setAttribute('data-filter-category', filter.categoryCode);
                _this.$savedFiltersContainer.appendChild($itemPattern);
                $itemPattern.addEventListener('click', function (ev) { return _this.activeFacets(ev); });
            });
            this.$savedFiltersArr = document.querySelectorAll('.m-filter__container__savedFilter');
            this.removeSavedFilterHandler();
        }
        else {
            this.$savedFiltersSection.classList.add('m-filter--hidden');
        }
        var accordionArr = app.default.getAccordionArr();
        var accordionObj = accordionArr.find(function (accordion) { return accordion.element == _this.$savedFiltersSection; });
        accordionObj.obj.calcContainerHeight(this.$savedFiltersSection.querySelector('.m-accordion__container'));
        this.filterToActivate = '';
    };
    SavedFilters.prototype.setOptions = function (query) {
        this.options = query;
    };
    SavedFilters.prototype.getOptions = function () {
        return this.options;
    };
    SavedFilters.prototype.selectFilter = function () {
        var _this = this;
        var activeFilterName = this.$savedFiltersSection.getAttribute('data-selected-filter');
        var decodedActiveFilterName = decodeURIComponent(activeFilterName);
        if (this.$savedFiltersArr.length) {
            this.$savedFiltersArr.forEach(function ($savedFilter) {
                var filterName = $savedFilter.querySelector('.m-filter__container__savedFilter__text').innerHTML;
                if (decodedActiveFilterName !== '' && filterName === decodedActiveFilterName) {
                    $savedFilter.classList.add('m-filter__container__savedFilter--selected');
                    _this.setFilterNameToForm(filterName);
                }
            });
        }
    };
    SavedFilters.prototype.removeFilterItem = function (ev) {
        return __awaiter(this, void 0, void 0, function () {
            var $filter, filterName, filterCategory;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ev.stopPropagation();
                        $filter = app.default.getSnippets().closest(ev.target, '.m-filter__container__savedFilter');
                        filterName = $filter.querySelector('.m-filter__container__savedFilter__text').innerHTML;
                        filterCategory = $filter.getAttribute('data-filter-category');
                        this.$savedFiltersArr.forEach(function ($savedFilter) {
                            if ($savedFilter !== $filter && $savedFilter.classList.contains('m-filter__container__savedFilter--selected')) {
                                _this.filterToActivate = $savedFilter.querySelector('.m-filter__container__savedFilter__text').innerHTML;
                            }
                        });
                        if (!$filter.classList.contains('m-filter__container__savedFilter--selected')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.resetFacets()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.removeFilter(filterCategory, filterName);
                        return [2 /*return*/];
                }
            });
        });
    };
    SavedFilters.prototype.removeSavedFilterHandler = function () {
        var _this = this;
        this.$removeBtnArr = document.querySelectorAll('.m-filter__container__savedFilter__remove');
        this.$removeBtnArr.forEach(function (btn) {
            btn.addEventListener('click', function (ev) { return _this.removeFilterItem(ev); });
        });
    };
    SavedFilters.prototype.toggleSavedFiltersEditMode = function (ev) {
        ev.stopPropagation();
        var $savedFilterArr = document.querySelectorAll('.m-filter__container__savedFilter');
        $savedFilterArr.forEach(function (filter) { return filter.classList.toggle('m-filter__container__savedFilter--edit'); });
    };
    SavedFilters.prototype.getEditBtn = function () {
        return this.$editBtn;
    };
    SavedFilters.prototype.turnOffSavingFilters = function () {
        this.$filterActionsPanel.classList.remove('m-filterPanel__actions__filters--activeSave');
    };
    SavedFilters.prototype.resetFacets = function () {
        return __awaiter(this, void 0, void 0, function () {
            var filterPanel, url, query;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filterPanel = app.default.getFilter();
                        url = new URL(location.origin + location.pathname + "/facets");
                        query = ':relevance';
                        url.searchParams.append('q', query);
                        return [4 /*yield*/, filterPanel.updateFilters(url.href)];
                    case 1:
                        _a.sent();
                        this.$options.forEach(function ($option) {
                            $option.classList.remove('m-filter__container__option--selected');
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SavedFilters.prototype.activeFacets = function (ev) {
        return __awaiter(this, void 0, void 0, function () {
            var filterPanel, $target, $savedFilter, foundFilter, facets, url, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.reload = false;
                        filterPanel = app.default.getFilter();
                        $target = ev.target;
                        $savedFilter = $target.classList.contains('m-filter__container__savedFilter') ? $target : app.default.getSnippets().closest($target, '.m-filter__container__savedFilter');
                        foundFilter = this.savedFiltersData.find(function (obj) { return obj.name === $savedFilter.querySelector('.m-filter__container__savedFilter__text').innerHTML; });
                        this.turnOffSavingFilters();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.resetFacets()];
                    case 2:
                        _a.sent();
                        if (!$savedFilter.classList.contains('m-filter__container__savedFilter--selected')) return [3 /*break*/, 3];
                        $savedFilter.classList.remove('m-filter__container__savedFilter--selected');
                        return [3 /*break*/, 5];
                    case 3:
                        this.removeSavedFiltersSelections();
                        $savedFilter.classList.add('m-filter__container__savedFilter--selected');
                        facets = this.selectActiveFacet(foundFilter.facets);
                        url = this.updateFacetsInQuery(facets);
                        //select options in save filter
                        return [4 /*yield*/, filterPanel.updateFilters(url.toString())];
                    case 4:
                        //select options in save filter
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _a.sent();
                        console.log('Fetch error: ', error_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    SavedFilters.prototype.selectActiveFacet = function (facets) {
        var _this = this;
        var queryParams = '';
        facets.forEach(function (filter) {
            queryParams += ":".concat(filter.category, ":").concat(filter.option);
            _this.$options.forEach(function ($option) {
                var optionValue = $option.getAttribute('data-filter-value');
                var optionCategory = $option.getAttribute('data-filter-category');
                if (optionValue === filter.option && optionCategory === filter.category) {
                    $option.classList.add('m-filter__container__option--selected');
                }
            });
        });
        return queryParams;
    };
    SavedFilters.prototype.updateFacetsInQuery = function (facets) {
        var query = ":relevance".concat(facets);
        var url = new URL(location.origin + location.pathname + "/facets");
        url.searchParams.append('q', query);
        return url;
    };
    SavedFilters.prototype.removeSavedFiltersSelections = function () {
        this.$savedFiltersArr.forEach(function ($savedFilter) { return $savedFilter.classList.remove('m-filter__container__savedFilter--selected'); });
    };
    SavedFilters.prototype.init = function () {
        var _this = this;
        this.$saveFilterModalInput.querySelector('input').addEventListener('keydown', function () { return _this.$saveFilterModalInput.classList.remove('a-input--error'); });
    };
    return SavedFilters;
}());
export { SavedFilters };
