var IndirectCheckoutModal = /** @class */ (function () {
    function IndirectCheckoutModal() {
        this.$modal = document.querySelector('.m-indirectCheckoutModal');
        this.$submitButton = this.$modal.querySelector('.m-modal__content__actions__btn');
        this.$form = document.querySelector('#indirectCheckoutForm');
        this.init();
    }
    IndirectCheckoutModal.prototype.submitForm = function (event) {
        event.preventDefault();
        this.$form.submit();
    };
    IndirectCheckoutModal.prototype.init = function () {
        var _this = this;
        this.$submitButton.addEventListener('click', function (event) { return _this.submitForm(event); });
    };
    return IndirectCheckoutModal;
}());
export { IndirectCheckoutModal };
