import { CheckoutAlert } from "../notification/checkout-alert";
import axios from 'axios';
var serialize = require('form-serialize');
var IndirectDealerFormHandler = /** @class */ (function () {
    function IndirectDealerFormHandler() {
        this.$dealerHandler = document.querySelector('.m-checkoutSummary');
        this.$onlineDealerForm = document.querySelector('#onlineDealerForm');
        this.$feedbackButton = this.$dealerHandler.querySelector(".m-checkoutSummary__feedback__accept__btn");
        this.$feedbackTextArea = this.$dealerHandler.querySelector("#feedbackText");
        this.init();
    }
    IndirectDealerFormHandler.prototype.sendFeedback = function () {
        var $this = this;
        var $form = this.$form;
        var requestUrl = $form.action;
        axios.post(requestUrl, serialize($form))
            .then(function (res) { return $this.createNotification(res.data); })
            .catch(function (error) { return console.log(error); });
    };
    IndirectDealerFormHandler.prototype.onStartNotification = function () {
        var _this = this;
        var $this = this;
        var requestUrl = '/checkout/notification';
        axios.get(requestUrl)
            .then(function (res) {
            _this.createNotification(res.data);
            var onlineDealerLink = document.querySelector('#online-dealer-checkout-manual-redirect');
            onlineDealerLink.addEventListener('click', function (evt) {
                $this.$onlineDealerForm.submit();
            });
        })
            .catch(function (error) { return console.log(error); });
    };
    IndirectDealerFormHandler.prototype.disableButton = function () {
        this.$feedbackButton.disabled = true;
        this.$feedbackButton.classList.add("m-checkoutSummary__feedback__accept__btn--disabled");
    };
    IndirectDealerFormHandler.prototype.disableFeedbackTextArea = function () {
        this.$feedbackTextArea.disabled = true;
        this.$feedbackTextArea.classList.add("m-checkoutSummary__feedback__accept__text--disabled");
    };
    IndirectDealerFormHandler.prototype.createNotification = function (response) {
        var alertContainer = document.querySelector('.o-global-alerts');
        alertContainer.insertAdjacentHTML('beforeend', response);
        new CheckoutAlert(alertContainer.lastElementChild);
    };
    IndirectDealerFormHandler.prototype.init = function () {
        var _this = this;
        if (this.$feedbackButton) {
            this.$form = this.$feedbackButton.form;
            this.$feedbackButton.addEventListener('click', function (event) {
                event.preventDefault();
                _this.sendFeedback();
                _this.disableButton();
                _this.disableFeedbackTextArea();
            });
        }
        if (this.$onlineDealerForm) {
            this.onStartNotification();
            this.$onlineDealerForm.submit();
        }
    };
    return IndirectDealerFormHandler;
}());
export { IndirectDealerFormHandler };
