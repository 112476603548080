import * as app from '../../main';
import axios from 'axios';
var VariantsHandler = /** @class */ (function () {
    function VariantsHandler(product) {
        this.price = '';
        this.stockQuantity = 0;
        this.productId = '';
        this.orderPossibility = {
            contactUs: false,
            requestForQuote: false,
            excluded: false,
            noExist: false,
            discontinued: false,
            hasProductReplacement: false
        };
        this.isLogged = document.body.classList.contains('-logged-in');
        this.ONE_VARIANT_DROPDOWN = 'oneVariantDropdown';
        this.$product = product;
        this.$selectVariantX = this.$product.querySelector('.variant-x');
        this.$selectVariantY = this.$product.querySelector('.variant-y');
        this.$selectVariant = this.$product.querySelector('.variant');
        this.basicCustomer = this.$product.getAttribute('data-is-basic-customer') === 'true';
        this.$selectDealer = this.$product.querySelector('.a-customSelect--dealer');
        this.$stockCounterInput = this.$product.querySelector('.a-counter__input');
        this.$addBtnPrice = this.$product.querySelector('.m-productOrderPossibility__addBtn__text');
        this.price = this.$addBtnPrice.getAttribute('data-price');
        this.$productCodeInfo = this.$product.querySelector('[class*="verifyInfo__code"]');
        this.$contactUsLink = this.$product.querySelector('.m-productOrderPossibility__contactLink--contactUs');
        this.$replacementLink = this.$product.querySelector('.m-productReplacementInfo');
        this.$requestForQuoteLink = this.$product.querySelector('.m-productOrderPossibility__contactLink--requestForQuote');
        this.$counter = this.$product.querySelector('.m-productOrderPossibility__quantity');
        this.$addToCartBtn = this.$product.querySelector('.m-productOrderPossibility__addBtn');
        this.$addToCartLink = this.$product.querySelector('.m-productOrderPossibility__contactLink--addToCart');
        this.$stockInfo = this.$product.querySelector('.m-productStockInfo');
        this.$productCodePost = this.$product.querySelector('input[name="productCodePost"]');
        this.$favTrigger = this.$product.querySelector('.m-favouriteHeart');
        this.$additionalInfoHazardous = this.$product.querySelector('.m-productAdditionalInfo__info--hazardous');
        this.$additionalInfoHeavy = this.$product.querySelector('.m-productAdditionalInfo__info--heavy');
        this.$additionalInfoSuperHeavy = this.$product.querySelector('.m-productAdditionalInfo__info--superHeavy');
        this.$additionalInfoTemperatureControlled = this.$product.querySelector('.m-productAdditionalInfo__info--temperatureControlled');
        this.init();
    }
    VariantsHandler.prototype.addVariantListeners = function () {
        var _this = this;
        if (this.$product.getAttribute('data-variants') === 'multivariants') {
            this.$selectVariantX.addEventListener('customSelectValueChanged', function (event) {
                _this.onVariantSelectChange(event, 'multivariants');
            });
            this.$selectVariantY.addEventListener('customSelectValueChanged', function (event) {
                _this.onVariantSelectChange(event, 'multivariants');
            });
        }
        else {
            this.$selectVariant.addEventListener('customSelectValueChanged', function (event) {
                _this.onVariantSelectChange(event);
            });
        }
    };
    VariantsHandler.prototype.onVariantSelectChange = function (event, type) {
        this.checkProduct(event.target, type);
        this.updateQuantityInfo();
        this.updateProductId();
        this.updateOrderPossibility();
        this.updateFavTriggerVisibility();
    };
    VariantsHandler.prototype.updateFavTriggerVisibility = function () {
        if (this.$favTrigger) {
            if (this.orderPossibility.noExist) {
                this.$favTrigger.classList.add('m-favouriteHeart--hidden');
            }
            else {
                this.$favTrigger.classList.remove('m-favouriteHeart--hidden');
            }
        }
    };
    VariantsHandler.prototype.checkProduct = function (input, type) {
        var productCode = input.getAttribute('data-code');
        this.orderPossibility = {
            contactUs: false,
            requestForQuote: false,
            excluded: false,
            noExist: false,
            discontinued: false,
            hasProductReplacement: false
        };
        if (type === 'multivariants') {
            var variantX = this.$selectVariantX.value;
            var variantY = this.$selectVariantY.value;
            var matrices = window.matrices;
            var hasProductWithVariant = matrices[productCode] && matrices[productCode][variantX] && matrices[productCode][variantX][variantY];
            if (hasProductWithVariant) {
                var productWithVariant = matrices[productCode][variantX][variantY];
                this.price = '';
                this.$productCodePost.value = productWithVariant.code.toString();
                this.stockQuantity = productWithVariant.stock;
                this.$stockCounterInput.setAttribute('data-stock', this.stockQuantity.toString());
                this.productId = productWithVariant.code.toString();
                this.orderPossibility = {
                    excluded: productWithVariant.excluded === 'true',
                    contactUs: productWithVariant.contactus === 'true' || productWithVariant.contactleader === 'true',
                    noExist: false,
                    discontinued: productWithVariant.discontinued === 'true',
                    hasProductReplacement: productWithVariant.hasProductReplacement === 'true',
                    requestForQuote: productWithVariant.requestforquote === 'true',
                };
                this.updateAdditionalInfoIcons({
                    isHazardous: productWithVariant.hazardous === 'true',
                    isHeavy: productWithVariant.heavy === 'true',
                    isSuperHeavy: productWithVariant.superHeavy === 'true',
                    temperatureControlled: productWithVariant.temperatureControlled === 'true',
                });
            }
            else {
                this.productId = '';
                this.stockQuantity = 0;
                this.orderPossibility.noExist = true;
                this.updateAdditionalInfoIcons({
                    isHazardous: false,
                    isHeavy: false,
                    isSuperHeavy: false,
                    temperatureControlled: false,
                });
            }
        }
        else {
            var $customSelect = app.default.getSnippets().closest(input, '.a-customSelect');
            var variant = $customSelect.querySelector(".a-customSelect__dropdown__option input:checked");
            this.stockQuantity = parseInt(variant.dataset.stock);
            this.productId = input.value;
            this.orderPossibility = {
                excluded: variant.dataset.variantExcluded === 'true',
                requestForQuote: variant.dataset.requestForQuote === 'true',
                contactUs: variant.dataset.contactus === 'true',
                discontinued: variant.dataset.productDiscontinued === 'true',
                hasProductReplacement: variant.dataset.hasProductReplacement === 'true',
                noExist: false,
            };
            this.updateAdditionalInfoIcons({
                isHazardous: variant.dataset.hazardous === 'true',
                isHeavy: variant.dataset.heavy === 'true',
                isSuperHeavy: variant.dataset.superHeavy === 'true',
                temperatureControlled: variant.dataset.temperatureControlled === 'true',
            });
            var productElement = app.default.getSnippets().closest(input, '.productForm');
            var isIndirectProduct = this.$product.getAttribute('data-indirect-product');
            if (productElement) {
                this.getPrice(this.productId);
                productElement.querySelector('.a-counter__input').setAttribute('data-stock', variant.dataset.stock);
            }
            else if (isIndirectProduct && variant.dataset.contactus !== 'true') {
                this.getPrice(this.productId);
            }
        }
    };
    VariantsHandler.prototype.updateQuantityInfo = function () {
        var productRequestQuantity = parseInt(this.$stockCounterInput.value);
        var outOfStockInfoClass = 'm-productStockInfo--outOfStock';
        var inStockInfoClass = 'm-productStockInfo--inStock';
        var tooltipClass = 'tooltip';
        if (!this.$stockInfo) {
            return;
        }
        if (this.orderPossibility.contactUs) {
            this.$stockInfo.classList.remove(inStockInfoClass);
            this.$stockInfo.classList.remove(outOfStockInfoClass);
            this.$stockInfo.classList.remove(tooltipClass);
            return;
        }
        if (this.productId.length > 0 && !this.orderPossibility.excluded) {
            if (this.stockQuantity < productRequestQuantity && !this.$stockInfo.classList.contains(outOfStockInfoClass)) {
                this.$stockInfo.classList.remove(inStockInfoClass);
                this.$stockInfo.classList.add(outOfStockInfoClass);
                this.$stockInfo.classList.add(tooltipClass);
            }
            else if (this.stockQuantity >= productRequestQuantity && !this.$stockInfo.classList.contains(inStockInfoClass)) {
                this.$stockInfo.classList.add(inStockInfoClass);
                this.$stockInfo.classList.remove(outOfStockInfoClass);
                this.$stockInfo.classList.remove(tooltipClass);
            }
        }
        else {
            this.$stockInfo.classList.remove(outOfStockInfoClass);
            this.$stockInfo.classList.remove(inStockInfoClass);
        }
    };
    VariantsHandler.prototype.updateProductId = function () {
        this.$productCodeInfo.innerText = this.productId;
        if (this.$favTrigger) {
            this.$favTrigger.setAttribute('data-variant', this.productId);
            this.$favTrigger.setAttribute('data-trigger-code', this.productId);
        }
        if (this.$stockInfo) {
            this.$stockInfo.setAttribute('data-product-code', this.productId);
        }
        this.$stockCounterInput.setAttribute('data-code', this.productId);
    };
    VariantsHandler.prototype.getPrice = function (id) {
        var _this = this;
        var newPriceText;
        var requestUrl = "/p/".concat(id, "/price");
        axios.get(requestUrl)
            .then(function (res) {
            var response = res.data;
            if (response && Object.keys(response).length !== 0) {
                for (var key in response) {
                    newPriceText = response[key];
                }
                _this.price = newPriceText.split('m-productOrderPossibility__addBtn__text__value">')[1].split('<')[0];
                _this.$addBtnPrice.innerHTML = newPriceText;
                _this.updateOrderPossibility(_this.ONE_VARIANT_DROPDOWN);
            }
            else {
                _this.price = '';
                _this.updateOrderPossibility(_this.ONE_VARIANT_DROPDOWN);
            }
        })
            .catch(function (error) { return console.log(error); });
    };
    VariantsHandler.prototype.updateAdditionalInfoIcons = function (product) {
        var hiddenClass = 'm-productAdditionalInfo__info--hidden';
        product.isHazardous ? this.$additionalInfoHazardous.classList.remove(hiddenClass) : this.$additionalInfoHazardous.classList.add(hiddenClass);
        product.isHeavy ? this.$additionalInfoHeavy.classList.remove(hiddenClass) : this.$additionalInfoHeavy.classList.add(hiddenClass);
        product.isSuperHeavy ? this.$additionalInfoSuperHeavy.classList.remove(hiddenClass) : this.$additionalInfoSuperHeavy.classList.add(hiddenClass);
        product.temperatureControlled ? this.$additionalInfoTemperatureControlled.classList.remove(hiddenClass) : this.$additionalInfoTemperatureControlled.classList.add(hiddenClass);
    };
    VariantsHandler.prototype.updateOrderPossibility = function (type) {
        var linkActiveClass = 'm-productOrderPossibility__contactLink--active';
        var linkReplacementActiveClass = 'm-productReplacementInfo--active';
        var linkQuantityActiveClass = 'm-productOrderPossibility__quantity--active';
        var linkAddBtnActiveClass = 'm-productOrderPossibility__addBtn--active';
        var selectHideCLass = 'a-customSelect--hidden';
        //for basic customer the bottom of a tile is always 'add to cart', we don't need to change
        if (this.basicCustomer) {
            return;
        }
        if (this.isLogged) {
            if (this.orderPossibility.excluded) {
                this.hideOrderPossibility(linkActiveClass);
                return;
            }
            if (this.orderPossibility.discontinued && this.orderPossibility.hasProductReplacement) {
                this.$contactUsLink.classList.remove(linkActiveClass);
                this.$replacementLink.classList.add(linkReplacementActiveClass);
                this.$requestForQuoteLink.classList.remove(linkActiveClass);
                this.$counter.classList.remove(linkQuantityActiveClass);
                this.$addToCartBtn.classList.remove(linkAddBtnActiveClass);
                return;
            }
            if (this.orderPossibility.contactUs) {
                this.$contactUsLink.classList.add(linkActiveClass);
                this.$replacementLink.classList.remove(linkReplacementActiveClass);
                this.$requestForQuoteLink.classList.remove(linkActiveClass);
                this.$counter.classList.remove(linkQuantityActiveClass);
                this.$addToCartBtn.classList.remove(linkAddBtnActiveClass);
                return;
            }
            if (this.orderPossibility.requestForQuote) {
                this.$replacementLink.classList.remove(linkReplacementActiveClass);
                this.$contactUsLink.classList.remove(linkActiveClass);
                this.$requestForQuoteLink.classList.add(linkActiveClass);
                this.$counter.classList.remove(linkQuantityActiveClass);
                this.$addToCartBtn.classList.remove(linkAddBtnActiveClass);
                return;
            }
            if (this.orderPossibility.noExist) {
                this.hideOrderPossibility(linkActiveClass);
                return;
            }
            if (type === this.ONE_VARIANT_DROPDOWN) {
                if (this.price.length < 1) {
                    this.$contactUsLink.classList.add(linkActiveClass);
                    this.$requestForQuoteLink.classList.remove(linkActiveClass);
                    this.$counter.classList.remove(linkQuantityActiveClass);
                    this.$addToCartBtn.classList.remove(linkAddBtnActiveClass);
                    if (this.$selectDealer) {
                        this.$selectDealer.parentElement.classList.add(selectHideCLass);
                    }
                    return;
                }
                else if (this.price.length >= 1 && this.$selectDealer) {
                    if (this.$selectDealer.parentElement.classList.contains(selectHideCLass)) {
                        this.$selectDealer.parentElement.classList.remove(selectHideCLass);
                    }
                }
            }
            this.$replacementLink.classList.remove(linkReplacementActiveClass);
            this.$contactUsLink.classList.remove(linkActiveClass);
            this.$requestForQuoteLink.classList.remove(linkActiveClass);
            this.$counter.classList.add(linkQuantityActiveClass);
            this.$addToCartBtn.classList.add(linkAddBtnActiveClass);
        }
        else {
            if (this.orderPossibility.discontinued && this.orderPossibility.hasProductReplacement) {
                this.$replacementLink.classList.add(linkReplacementActiveClass);
                this.$addToCartLink.classList.remove(linkActiveClass);
                return;
            }
            this.$replacementLink.classList.remove(linkReplacementActiveClass);
            this.$addToCartLink.classList.add(linkActiveClass);
        }
    };
    VariantsHandler.prototype.hideOrderPossibility = function (linkActiveClass) {
        this.$contactUsLink.classList.remove(linkActiveClass);
        this.$requestForQuoteLink.classList.remove(linkActiveClass);
        this.$counter.classList.remove('m-productOrderPossibility__quantity--active');
        this.$addToCartBtn.classList.remove('m-productOrderPossibility__addBtn--active');
    };
    VariantsHandler.prototype.init = function () {
        this.addVariantListeners();
        var customEvent = new CustomEvent('customSelectValueChanged');
        if (this.$selectVariantX instanceof Element) {
            this.$selectVariantX.dispatchEvent(customEvent);
            this.$selectVariantY.dispatchEvent(customEvent);
        }
        if (this.$selectVariant instanceof Element) {
            this.$selectVariant.dispatchEvent(customEvent);
        }
    };
    return VariantsHandler;
}());
export { VariantsHandler };
