var CardSelector = /** @class */ (function () {
    function CardSelector(element) {
        this.$selector = element;
        this.$creditCardPaymentType = element.querySelector('input[name=creditCardPaymentType]');
        this.$existingCardId = element.querySelector('input[name=existingCardId]');
        this.$trigger = element.querySelector('.m-cardSelector__trigger');
        this.$triggerIcon = this.$trigger.querySelector('.m-cardSelector__trigger__icon');
        this.$triggerLabel = this.$trigger.querySelector('.m-cardSelector__trigger__label');
        this.$contentContainer = element.querySelector('.m-cardSelector__content');
        this.$paymetricContainer = document.querySelector('.m-paymetric');
        if (this.$paymetricContainer != null) {
            this.$paymetricIFrame = this.$paymetricContainer.querySelector('iframe');
        }
        this.$values = Array.prototype.slice.call(this.$contentContainer.querySelectorAll('.m-cardSelector__content__value'));
        this.init();
    }
    CardSelector.prototype.disable = function () {
        this.disabled = true;
        this.$selector.classList.add('m-cardSelector--disabled');
        this.$paymetricContainer.classList.remove('m-paymetric--active');
        if (this.$contentContainer.classList.contains('m-cardSelector__content--visible')) {
            this.closeDropdown();
        }
    };
    CardSelector.prototype.enable = function () {
        this.disabled = false;
        this.$selector.classList.remove('m-cardSelector--disabled');
        if (this.$creditCardPaymentType.value == 'NEW_CARD') {
            this.initializePaymetric();
        }
    };
    CardSelector.prototype.initializePaymetric = function () {
        var src = this.$paymetricIFrame.getAttribute('data-src');
        this.$paymetricIFrame.contentWindow.location.replace(src);
        this.$paymetricContainer.classList.add('m-paymetric--active');
    };
    CardSelector.prototype.openDropdown = function () {
        this.$contentContainer.classList.add('m-cardSelector__content--visible');
        this.$trigger.classList.add('m-cardSelector__trigger--active');
        this.$triggerIcon.classList.add('icon-chevron-up');
        this.$triggerIcon.classList.remove('icon-chevron-down');
    };
    CardSelector.prototype.closeDropdown = function () {
        this.$contentContainer.classList.remove('m-cardSelector__content--visible');
        this.$trigger.classList.remove('m-cardSelector__trigger--active');
        this.$triggerIcon.classList.remove('icon-chevron-up');
        this.$triggerIcon.classList.add('icon-chevron-down');
    };
    CardSelector.prototype.selectValue = function (value) {
        var val = value.getAttribute('data-value');
        this.$triggerLabel.innerHTML = value.innerHTML;
        if (val == 'NEW_CARD') {
            this.$creditCardPaymentType.value = val;
            this.$existingCardId.value = '';
            this.initializePaymetric();
        }
        else {
            this.$creditCardPaymentType.value = 'EXISTING_CARD';
            this.$existingCardId.value = val;
            this.$paymetricContainer.classList.remove('m-paymetric--active');
        }
        this.closeDropdown();
    };
    CardSelector.prototype.init = function () {
        var _this = this;
        this.disable();
        document.addEventListener('click', function (event) {
            var element = event.target;
            if (!_this.$contentContainer.contains(element) && !_this.$trigger.contains(element)) {
                _this.closeDropdown();
            }
        });
        this.$trigger.addEventListener('click', function (event) {
            event.preventDefault();
            if (!_this.disabled) {
                if (_this.$trigger.classList.contains('m-cardSelector__trigger--active')) {
                    _this.closeDropdown();
                }
                else {
                    _this.openDropdown();
                }
            }
        });
        this.$values.forEach(function (value) {
            value.addEventListener('click', function (event) {
                event.preventDefault();
                _this.selectValue(value);
            });
        });
    };
    return CardSelector;
}());
export { CardSelector };
