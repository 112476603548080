var PdpTabPanel = /** @class */ (function () {
    function PdpTabPanel() {
        this.activeTabClass = 'm-tabPanel__content__tab--active';
        this.activeHeaderClass = 'm-tabPanel__header__content__tabs__name--active';
        this.$pdpTabPanel = document.querySelector('.m-pdpTabPanel');
        this.$tabsContentArr = this.$pdpTabPanel.querySelectorAll('.m-pdpTabContent');
        this.$panelContent = this.$pdpTabPanel.querySelector('.m-tabPanel__content');
        this.$panelHeaderContent = this.$pdpTabPanel.querySelector('.m-tabPanel__header__content');
        this.$tabHeaders = Array.prototype.slice.call(this.$panelHeaderContent.querySelectorAll('.m-tabPanel__header__content__tabs__name'));
        this.$accordionHeaders = this.$pdpTabPanel.querySelectorAll('.m-accordion__header');
        this.init();
    }
    PdpTabPanel.prototype.initActiveTab = function () {
        if (this.$tabHeaders.length) {
            var $activeHeader = this.$tabHeaders[0];
            $activeHeader.classList.add(this.activeHeaderClass);
            var activeTabName = $activeHeader.getAttribute('data-tab');
            var tab = this.$panelContent.querySelector(".m-tabPanel__content__tab[data-tab=\"".concat(activeTabName, "\"]"));
            tab.classList.add(this.activeTabClass);
        }
    };
    PdpTabPanel.prototype.init = function () {
        this.initActiveTab();
    };
    return PdpTabPanel;
}());
export { PdpTabPanel };
