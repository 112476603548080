var createRater = require('rater-js');
var CreateRater = /** @class */ (function () {
    function CreateRater() {
        this.$rater = document.querySelector('.-logged-in .m-pdpRating');
        this.$newCommentBtn = this.$rater.querySelector('.m-pdpRating__rate__btnContainer__rateBtn');
        this.$addCommentBtn = this.$rater.querySelector('.m-pdpRating__newRate__content__comment__addBtn');
        this.$newRateContainer = this.$rater.querySelector('.m-pdpRating__newRate');
        this.$newRateContent = this.$rater.querySelector('.m-pdpRating__newRate__content');
        this.$newRate = this.$rater.querySelector('#newRate');
        this.$rateValueInput = this.$rater.querySelector('.m-pdpRating__newRate__content__data__value');
        this.$titleValueInput = this.$rater.querySelector('.m-pdpRating__newRate__content__data__title input');
        this.$rate = this.$rater.querySelector('.m-pdpRating__rate');
        this.$rateInfo = this.$rater.querySelector('.m-pdpRating__rate__info');
        this.$rateSection = document.querySelector('.m-pdpRating');
        this.windowWidth = window.outerWidth;
        this.init();
    }
    CreateRater.prototype.toogleNewCommentContainer = function () {
        var newRateContentH = this.$newRateContent.getBoundingClientRect().height;
        this.$newRateContainer.setAttribute('style', 'max-height:' + newRateContentH + 'px');
        var rateH = this.$rate.getBoundingClientRect().height;
        var rateSectionH = this.$rateSection.getBoundingClientRect().height;
        var newRateSectionH = rateSectionH + rateH + newRateContentH;
        this.$rateSection.setAttribute('style', 'max-height:' + newRateSectionH + 'px');
        this.$newRateContainer.classList.toggle('m-pdpRating__newRate--collapsed');
        this.$rateInfo.classList.toggle('m-pdpRating__rate__info--active');
    };
    CreateRater.prototype.createRater = function () {
        var $this = this;
        this.commentRater = createRater({
            element: this.$rater.querySelector('.m-pdpRating__newRate__content__data__rater'),
            starSize: 24,
            step: 0.5,
            max: 5,
            rateCallback: function rateCallback(rating, done) {
                this.setRating(rating);
                $this.$rateValueInput.value = this.getRating(rating);
                if ($this.$titleValueInput.value.length >= 4) {
                    $this.activeBtn();
                }
                done();
            }
        });
    };
    CreateRater.prototype.addRaterTitle = function () {
        if (this.$rateValueInput.value !== '' && this.$titleValueInput.value.length >= 4) {
            this.activeBtn();
        }
        else {
            this.$addCommentBtn.classList.add('a-darkBorderedBtn--disabled');
            this.$addCommentBtn.disabled = true;
        }
    };
    CreateRater.prototype.activeBtn = function () {
        this.$addCommentBtn.disabled = false;
        this.$addCommentBtn.classList.remove('a-darkBorderedBtn--disabled');
    };
    CreateRater.prototype.init = function () {
        var _this = this;
        this.createRater();
        this.$newCommentBtn.addEventListener('click', function () { return _this.toogleNewCommentContainer(); });
        this.$titleValueInput.addEventListener('input', function (event) { return _this.addRaterTitle(); });
        window.addEventListener('resize', function (event) {
            var currentWindowWidth = window.outerWidth;
            if (currentWindowWidth !== _this.windowWidth) {
                _this.windowWidth = currentWindowWidth;
                if (!_this.$newRateContainer.classList.contains('m-pdpRating__newRate--collapsed')) {
                    _this.toogleNewCommentContainer();
                    _this.$newRate.click();
                }
            }
        });
    };
    return CreateRater;
}());
export { CreateRater };
