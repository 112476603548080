import axios from 'axios';
var ShippingMethod = /** @class */ (function () {
    function ShippingMethod() {
        this.hazardousFee = false;
        this.isHazardousNotificationEnabled = false;
        this.$shippingModeContainer = document.querySelector('.o-deliveryPage__content__shippingMethod__deliveryModes');
        this.$form = document.querySelector('#deliveryForm');
        this.$loader = document.querySelector('.a-loader');
        this.$checkoutBtns = Array.prototype.slice.call(document.querySelectorAll('.a-greenBlueBtn'));
        this.$backBtn = document.querySelector('.m-checkoutBack__previousStep');
        this.$hazardousProductComponent = document.querySelector('.o-deliveryPage__hazardousProductComponent');
        this.isHazardousNotificationEnabled = this.$hazardousProductComponent.getAttribute('notification-enabled') == 'true';
        this.init();
    }
    ShippingMethod.prototype.fetchShippingMethods = function () {
        var _this_1 = this;
        var cartToken = this.$form.querySelector('input[name=cartToken]').value;
        var addressId = this.$form.querySelector('input[name=addressId]').value;
        this.$loader.classList.add('a-loader--active');
        var requestUrl = '/checkout/delivery/modes?addressId=' + addressId + "&token=" + cartToken;
        axios.get(requestUrl, { headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            } })
            .then(function (res) {
            _this_1.hazardousFee = false;
            _this_1.$shippingModeContainer.innerHTML = '';
            var response = res.data;
            if (response.cartModified && !response.deliveryModes) {
                location.reload();
            }
            if (response.deliveryModes && response.deliveryModes.length == 0) {
                _this_1.reportNoDeliveryModes();
            }
            response.deliveryInfo && _this_1.displayDeliveryInfo(response.deliveryInfo);
            response.deliveryModes.forEach(function (deliveryMode) {
                if (deliveryMode.hazardousFee != null) {
                    _this_1.hazardousFee = true;
                }
                _this_1.createDeliveryMode(deliveryMode);
            });
            _this_1.$loader.classList.remove('a-loader--active');
            _this_1.hazardousFeeSetting();
        })
            .catch(function (error) { return console.log(error); });
    };
    ShippingMethod.prototype.hazardousFeeSetting = function () {
        if (!this.hazardousFee || !this.isHazardousNotificationEnabled) {
            this.$hazardousProductComponent.classList.add('o-deliveryPage__hazardousProductComponent--hidden');
        }
        else {
            this.$hazardousProductComponent.classList.remove('o-deliveryPage__hazardousProductComponent--hidden');
        }
    };
    ShippingMethod.prototype.displayDeliveryInfo = function (info) {
        var paragraph = document.createElement('div');
        paragraph.innerHTML =
            "<div class=\"m-deliveryModes__info\">\n                <svg class=\"a-reorder-infoIcon\">\n                    <use xlink:href=\"/_ui/responsive/theme-whiteleaf/src-images/spritesheet.svg#icon-info-navy\"></use>\n                </svg>\n                <div >".concat(info, "</div>\n            </div>");
        this.$shippingModeContainer.appendChild(paragraph);
    };
    ShippingMethod.prototype.createDeliveryMode = function (deliveryMode) {
        var _this_1 = this;
        var _a, _b;
        var paragraph = document.createElement('div');
        paragraph.classList.add('m-deliveryModes__mode', 'a-radio');
        var radio = document.createElement('input');
        radio.type = 'radio';
        radio.name = 'deliveryModeId';
        radio.classList.add('a-radio__input');
        radio.value = deliveryMode.code;
        radio.id = deliveryMode.code;
        radio.addEventListener('change', function (event) {
            _this_1.toggleCheckoutBtnState(false);
        });
        var label = document.createElement('label');
        label.htmlFor = deliveryMode.code;
        label.classList.add('m-deliveryModes__mode__label');
        label.classList.add('a-radio__label');
        // fix for IE11 - classes should be added separately
        var name = document.createElement('span');
        name.innerHTML = deliveryMode.name;
        name.classList.add('a-radio__label__text');
        var price = document.createElement('div');
        price.innerHTML = deliveryMode.deliveryCost.value == 0 && deliveryMode.deliveryCost.currencyIso == 'GBP'
            ? 'Free'
            : deliveryMode.deliveryCost.formattedValue;
        if (deliveryMode.hazardousFee && this.isHazardousNotificationEnabled) {
            price.innerHTML += "<span class=\"m-deliveryModes__mode__label__price--hazardous\"> (= ".concat((_a = deliveryMode.freight) === null || _a === void 0 ? void 0 : _a.formattedValue, " freight + ").concat((_b = deliveryMode.hazardousFee) === null || _b === void 0 ? void 0 : _b.formattedValue, " hazardous fee)</span>");
        }
        price.classList.add('m-deliveryModes__mode__label__price');
        label.appendChild(name);
        paragraph.appendChild(radio);
        paragraph.appendChild(label);
        paragraph.appendChild(price);
        this.$shippingModeContainer.appendChild(paragraph);
    };
    ShippingMethod.prototype.reportNoDeliveryModes = function () {
        this.$shippingModeContainer.innerHTML = window.shippingMessages.notFound;
    };
    ShippingMethod.prototype.reloadShippingMethodsOnAddressChange = function () {
        var _this = this;
        var $addressInput = this.$form.querySelector('input[name=addressId]');
        $addressInput.addEventListener('change', function (event) {
            _this.refreshShippingMethods();
        });
    };
    ShippingMethod.prototype.refreshShippingMethods = function () {
        var _this_1 = this;
        this.$loader.classList.toggle('a-loader--active');
        this.$shippingModeContainer.innerHTML = "";
        this.$checkoutBtns.forEach(function (btn) {
            _this_1.toggleCheckoutBtnState(true);
        });
        this.fetchShippingMethods();
    };
    ShippingMethod.prototype.toggleCheckoutBtnState = function (disabled) {
        this.$checkoutBtns.forEach(function (btn) {
            btn.disabled = disabled;
            if (disabled) {
                btn.classList.add('a-greenBlueBtn--disabled');
            }
            else {
                btn.classList.remove('a-greenBlueBtn--disabled');
            }
        });
    };
    ShippingMethod.prototype.init = function () {
        var _this_1 = this;
        this.fetchShippingMethods();
        this.reloadShippingMethodsOnAddressChange();
        this.$checkoutBtns.forEach(function (btn) {
            btn.addEventListener('click', function (event) {
                event.preventDefault();
                if (!btn.classList.contains('a-greenBlueBtn--disabled')) {
                    _this_1.$form.submit();
                }
            });
        });
        this.$backBtn.addEventListener('click', function (event) {
            event.preventDefault();
            window.history.back();
        });
    };
    return ShippingMethod;
}());
export { ShippingMethod };
