var AgentImpersonateCustomer = /** @class */ (function () {
    function AgentImpersonateCustomer() {
        this.init();
    }
    AgentImpersonateCustomer.prototype.setScrollbarWidth = function () {
        var customerEl = document.querySelector('.m-agentLandingPageHeader__customerElement');
        var scrollbarWidth = window.innerWidth - document.body.offsetWidth;
        customerEl.setAttribute('style', "--scroll--width: ".concat(scrollbarWidth, "px"));
    };
    AgentImpersonateCustomer.prototype.init = function () {
        var _this = this;
        window.onresize = function () {
            _this.setScrollbarWidth();
        };
        this.setScrollbarWidth();
    };
    return AgentImpersonateCustomer;
}());
export { AgentImpersonateCustomer };
