import * as app from "../../main";
var MyAccount = /** @class */ (function () {
    function MyAccount() {
        this.buttonRolledPlace = 200;
        this.$myAccount = document.querySelector('.o-myAccount');
        this.$menu = this.$myAccount.querySelector('.m-myAccountMenu');
        this.$menuShowButton = this.$myAccount.querySelector('.m-myAccountMenuBtnContainer__btn');
        this.$menuHideButton = this.$myAccount.querySelector('.m-myAccountMenu__closeButton');
        this.$body = document.body;
        this.$submitSelectArr = this.$myAccount.querySelectorAll('.submit-select');
        this.init();
    }
    MyAccount.prototype.toggleMenu = function (event) {
        if (event) {
            event.stopPropagation();
        }
        this.$menu.classList.contains('m-myAccountMenu--mobileVisible') ? this.$body.classList.remove('shadow') : this.$body.classList.add('shadow');
        this.$menu.classList.toggle('m-myAccountMenu--mobileVisible');
    };
    MyAccount.prototype.toggleMenuButton = function () {
        this.$menuShowButton.classList.toggle('m-myAccountMenuBtnContainer__btn--withoutText');
    };
    MyAccount.prototype.closeUserMenu = function () {
        if (this.$menu.classList.contains('m-myAccountMenu--mobileVisible')) {
            this.toggleMenu();
        }
    };
    MyAccount.prototype.closeOnEsc = function (event) {
        var key = event.key || event.keyCode;
        if (key === 'Escape' || key === 'Esc' || key === 27) {
            this.closeUserMenu();
        }
    };
    MyAccount.prototype.init = function () {
        var _this = this;
        this.$menuShowButton.addEventListener('click', function (_event) { return _this.toggleMenu(_event); });
        this.$menuHideButton.addEventListener('click', function (_event) { return _this.toggleMenu(_event); });
        window.addEventListener('scroll', function () {
            var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            var visibilityMenuBtnText = _this.$menuShowButton.classList.contains('m-myAccountMenuBtnContainer__btn--withoutText');
            if ((scrollPosition < _this.buttonRolledPlace && visibilityMenuBtnText)
                || (scrollPosition >= _this.buttonRolledPlace && !visibilityMenuBtnText)) {
                _this.toggleMenuButton();
            }
        });
        window.addEventListener('resize', function () { return _this.closeUserMenu(); });
        document.addEventListener('keydown', function (_event) { return _this.closeOnEsc(_event); });
        this.$menu.addEventListener('click', function (_event) { return _event.stopPropagation(); });
        this.$submitSelectArr.forEach(function (select) {
            select.addEventListener('customSelectValueChanged', function (_event) {
                app.default.getSnippets().closest(select, 'form').submit();
            });
        });
        document.addEventListener('click', function (event) {
            if (!_this.$myAccount.contains(event.target)) {
                _this.closeUserMenu();
            }
        });
    };
    return MyAccount;
}());
export { MyAccount };
