var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as app from "../../main";
import { AlertFactory } from '../notification/alert-factory';
import alertObject from '../notification/global-alerts';
import { ListOfProducts } from './list-of-products';
var FavoritesListPage = /** @class */ (function (_super) {
    __extends(FavoritesListPage, _super);
    function FavoritesListPage() {
        var _this = _super.call(this) || this;
        _this.getProductsPricesEndpoint = 'favorites/price/';
        _this.addAllProductsEndpoint = '/cart/addallfavs';
        _this.$favoritesListPage = document.querySelector('.m-favoritesList');
        _this.$favoritesListLoader = _this.$favoritesListPage.querySelector('.m-favoritesList__loader');
        _this.$favoritesEditModalInput = _this.$favoritesListPage.querySelector('#favoriteList__editModal [name=listId]');
        _this.$favoritesRemoveModalInput = _this.$favoritesListPage.querySelector('#favoriteList__removeModal [name=listId]');
        _this.$cellsChevronArr = _this.$favoritesListPage.querySelectorAll('.m-favoritesList__data__item__arrow');
        _this.$removableElements = _this.$favoritesListPage.querySelectorAll('.m-productTile__delete');
        _this.$addAllBtnArr = _this.$favoritesListPage.querySelectorAll('.m-favoritesList__data__item__actionCell__btn');
        _this.$removeListBtnArr = _this.$favoritesListPage.querySelectorAll('.m-favoritesList__data__item__actionCell__link--remove');
        _this.$editListBtnArr = _this.$favoritesListPage.querySelectorAll('.m-favoritesList__data__item__actionCell__link--edit');
        _this.$itemData = _this.$favoritesListPage.querySelectorAll('.m-favoritesList__data__item');
        _this.$dealerDropdownArr = _this.$favoritesListPage.querySelectorAll('.a-customSelect--dealer .a-customSelect__field__input--hidden');
        _this.$editModal = _this.$favoritesListPage.querySelector('#favoriteList__editModal');
        _this.page = _this.$favoritesListPage;
        _this.init();
        return _this;
    }
    FavoritesListPage.prototype.addAllProducts = function (event) {
        event.preventDefault();
        var mainItem = app.default.getSnippets().closest(event.currentTarget, '.m-favoritesList__data__item');
        var productTiles = mainItem.querySelectorAll('.m-productTile');
        if (this.checkIfAllDealersAreSelected(productTiles)) {
            _super.prototype.addAll.call(this, this.addAllProductsEndpoint);
            this.$favoritesListLoader.classList.add('a-loader--active');
        }
        else {
            if (!mainItem.querySelector('.m-accordion__header').classList.contains('m-accordion__header--active')) {
                var listId = mainItem.getAttribute('data-list-id');
                _super.prototype.getPricesOfProducts.call(this, this.getProductsPricesEndpoint, listId);
                var clickedAccordion_1 = app.default.getSnippets().closest(event.target, '.m-accordion');
                var accordionArr = app.default.getAccordionArr();
                var accordionObj = accordionArr.find(function (accordion) { return accordion.element == clickedAccordion_1; });
                accordionObj.obj.toggleOnClick(event);
            }
            var warningTitle = mainItem.getAttribute('data-attribute-warningTitle');
            var alertFactoryObject = new AlertFactory();
            var warningMessage = mainItem.getAttribute('data-attribute-warningMessage');
            var element = alertFactoryObject.createAlert(warningMessage, warningTitle, 'warning', { 'animated': true, 'closable': true });
            alertObject.showAlert(element);
        }
    };
    FavoritesListPage.prototype.checkIfAllDealersAreSelected = function (productTiles) {
        var isSelected = true;
        productTiles.forEach(function (element) {
            var dealerField = element.querySelector('.a-customSelect--dealer .a-customSelect__field');
            if (dealerField != null) {
                var dealerInput = dealerField.querySelector('.a-customSelect__field__input--hidden');
                if (isNaN(parseInt(dealerInput.value))) {
                    isSelected = false;
                    element.classList.add('m-productTile--hover');
                    dealerField.classList.add('a-input--error');
                }
                else if (element.classList.contains('m-productTile--hover')) {
                    element.classList.remove('m-productTile--hover');
                }
            }
        });
        return isSelected;
    };
    FavoritesListPage.prototype.createEmptyTab = function () {
        this.$itemData.forEach(function (mainItem) {
            var productTiles = mainItem.querySelectorAll('.m-productTile');
            if (productTiles.length === 0) {
                var chevron = mainItem.querySelector('.m-favoritesList__data__item__arrow');
                var allBtn = mainItem.querySelector('.m-favoritesList__data__item__actionCell__btn');
                var emptyElements = mainItem.querySelectorAll('.m-favoritesList__emptyItem');
                chevron.classList.add('m-favoritesList__data__item__arrow--disable');
                allBtn.classList.add('a-lightBtn--disabled');
                allBtn.disabled = true;
                emptyElements.forEach(function (emptyElement) {
                    emptyElement.classList.remove('m-favoritesList__emptyItem--hidden');
                });
            }
        });
    };
    FavoritesListPage.prototype.checkData = function (event) {
        var input = event.target;
        var dropdownContainer = app.default.getSnippets().closest(event.target, '.a-customSelect__field');
        if (dropdownContainer.classList.contains('a-input--error') && input.value) {
            dropdownContainer.classList.remove('a-input--error');
        }
    };
    FavoritesListPage.prototype.setDataInFavEditModal = function (ev) {
        var $favorite = app.default.getSnippets().closest(ev.target, '.m-favoritesList__data__item');
        var $nameField = $favorite.querySelector('.m-favoritesList__data__item__cell__value--name');
        var name = $nameField.textContent;
        var $nameInput = this.$editModal.querySelector('[name="listName"]');
        $nameInput.value = name;
    };
    FavoritesListPage.prototype.init = function () {
        var _this = this;
        this.createEmptyTab();
        this.$addAllBtnArr.forEach(function ($btn) { return $btn.addEventListener('click', function (event) { return _this.addAllProducts(event); }); });
        this.$removeListBtnArr.forEach(function ($btn) { return $btn.addEventListener('click', function (event) { return _super.prototype.setListIdInModal.call(_this, event, _this.$favoritesRemoveModalInput); }); });
        this.$editListBtnArr.forEach(function ($btn) { return $btn.addEventListener('click', function (event) {
            _this.setListIdInModal(event, _this.$favoritesEditModalInput);
            _this.setDataInFavEditModal(event);
        }); });
        this.$cellsChevronArr.forEach(function ($chevron) { return $chevron.addEventListener('click', function (event) { return _super.prototype.getPricesOfProductsOnList.call(_this, _this.getProductsPricesEndpoint, event); }); });
        this.$removableElements.forEach(function (removeElement) { return removeElement.addEventListener('click', function (event) { return _super.prototype.removeProductFromList.call(_this, event, 'm-favoritesList__data__item__tilePanel'); }); });
        this.$dealerDropdownArr.forEach(function (dropdown) { return dropdown.addEventListener('customSelectValueChanged', function (event) { return _this.checkData(event); }); });
    };
    return FavoritesListPage;
}(ListOfProducts));
export { FavoritesListPage };
