import { AlertFactory } from '../notification/alert-factory';
import alertObject from '../notification/global-alerts';
import axios from 'axios';
var ServiceRequestModal = /** @class */ (function () {
    function ServiceRequestModal() {
        var _a;
        this.$modal = document.querySelector('#service-request-modal');
        this.$reachContainer = this.$modal.querySelector('.m-serviceRequestModal__part--reach');
        this.$reachOptionArr = this.$modal.querySelectorAll('input[type=radio][name=requestRadio]');
        this.$reachInputEmail = this.$reachContainer.querySelector('input[name="temporaryEmail"]');
        this.$reachInputPhone = this.$reachContainer.querySelector('input[name="phone"]');
        this.$inputEmail = this.$modal.querySelector('input[name="email"]');
        this.$deviceTypeContainer = this.$modal.querySelector('.m-serviceRequestModal__part--deviceType');
        this.$deviceTypeArr = this.$modal.querySelectorAll('input[type=radio][name=productTypeRadio]');
        this.$deviceInputMaterialDeviceType = this.$modal.querySelector('input[name="materialDeviceType"]');
        this.$deviceInputDeviceType = this.$modal.querySelector('input[name="deviceType"]');
        this.$deviceInputErrorMessage = this.$modal.querySelector('textarea[name="errorMessage"]');
        this.$submitBtn = this.$modal.querySelector('button[type=submit]');
        this.$form = this.$modal.querySelector('form');
        this.$closeBtn = this.$modal.querySelector('.m-modal__content__close');
        this.$removeSection = this.$modal.querySelector('.m-serviceRequestModal__part__remove');
        this.$removeBtn = this.$modal.querySelector('.m-serviceRequestModal__part__remove__link');
        this.$uploadElement = this.$modal.querySelector('.m-serviceRequestModal__part__upload');
        this.$uploadError = this.$uploadElement.querySelector('.m-serviceRequestModal__part__field__error');
        this.fileLengthErrorText = this.$uploadError.getAttribute('data-fileLength');
        this.fileSizeErrorText = this.$uploadError.getAttribute('data-fileSize');
        this.fileFormatErrorText = this.$uploadError.getAttribute('data-fileFormat');
        this.$uploadAction = this.$uploadElement.querySelector('.m-serviceRequestModal__part__upload__action');
        this.$uploadBtn = this.$uploadElement.querySelector('.m-serviceRequestModal__part__upload__action__btn');
        this.$uploadInput = this.$uploadElement.querySelector('input[type=file]');
        this.$fileTemplateElement = this.$modal.querySelector('#serviceRequestFileTemplate');
        this.$fileListElement = this.$modal.querySelector('#serviceRequestFileList');
        this.$fields = this.$modal.querySelectorAll('.a-input, .a-textarea');
        this.$unsafeExtensions = ((_a = this.$modal.getAttribute('unsafe-extensions')) === null || _a === void 0 ? void 0 : _a.split(',')) || [];
        this.alertFactoryObject = new AlertFactory();
        this.init();
    }
    ServiceRequestModal.prototype.toggleReachOptions = function () {
        var reachOption = this.$reachContainer.querySelector('input[type=radio]:checked').getAttribute('value');
        this.$reachContainer.classList.remove('m-serviceRequestModal__part--emailRequired');
        this.$reachContainer.classList.remove('m-serviceRequestModal__part--phoneRequired');
        this.$reachContainer.classList.add("m-serviceRequestModal__part--".concat(reachOption, "Required"));
        var isEmailOption = reachOption === 'email';
        var isPhoneOption = reachOption === 'phone';
        this.$reachInputEmail.required = isEmailOption;
        this.$reachInputPhone.required = isPhoneOption;
        if (isEmailOption) {
            this.$reachInputPhone.value = '';
        }
        else if (isPhoneOption) {
            this.$reachInputEmail.value = '';
            this.$inputEmail.value = '';
        }
    };
    ServiceRequestModal.prototype.toggleDeviceType = function () {
        this.deviceType = this.$deviceTypeContainer.querySelector('input[type=radio]:checked').getAttribute('value');
        this.$deviceTypeContainer.classList.remove('m-serviceRequestModal__part--material');
        this.$deviceTypeContainer.classList.remove('m-serviceRequestModal__part--equipment');
        this.$deviceTypeContainer.classList.add("m-serviceRequestModal__part--".concat(this.deviceType));
        this.$deviceInputMaterialDeviceType.required = this.deviceType === 'material';
        var isEquipment = this.deviceType === 'equipment';
        this.$deviceInputDeviceType.required = isEquipment;
        this.$deviceInputErrorMessage.required = isEquipment;
    };
    ServiceRequestModal.prototype.submitForm = function (event) {
        var _this = this;
        event.preventDefault();
        if (!this.$form.checkValidity()) {
            return;
        }
        if (this.deviceType === 'material') {
            this.$deviceInputDeviceType.value = this.$deviceInputMaterialDeviceType.value;
        }
        var data = new FormData(this.$form);
        var requestUrl = this.$form.getAttribute('action');
        axios.post(requestUrl, data, { headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            } })
            .then(function (res) {
            var element = _this.alertFactoryObject.createAlert(res.data, 'Success', 'success', { 'animated': true, 'closable': true, 'disposable': true });
            alertObject.showAlert(element);
            _this.$closeBtn.click();
        })
            .catch(function (error) {
            var element = _this.alertFactoryObject.createAlert(error.message, 'Error', 'error', { 'animated': true, 'closable': true });
            alertObject.showAlert(element);
            _this.$closeBtn.click();
        });
    };
    ServiceRequestModal.prototype.uploadFiles = function (event) {
        event.preventDefault();
        this.clearUploadErrors();
        var uploadedFiles = this.$uploadInput.files;
        if (!uploadedFiles.length)
            return;
        var files = [];
        for (var i = 0; i < uploadedFiles.length; i++) {
            files.push(uploadedFiles.item(i));
        }
        if (!this.isLengthValid(files)) {
            this.showFileUploadError(this.fileLengthErrorText);
        }
        else if (!this.isSizeValid(files)) {
            this.showFileUploadError(this.fileSizeErrorText);
        }
        else if (!this.isExtensionValid(files)) {
            this.showFileUploadError(this.fileFormatErrorText);
        }
        else {
            this.displayUploadedFiles(files);
        }
    };
    ServiceRequestModal.prototype.showFileUploadError = function (text) {
        this.$uploadError.innerHTML = text;
        this.$uploadElement.classList.add('m-serviceRequestModal__part__upload--error');
        this.initFileInput();
    };
    ServiceRequestModal.prototype.clearUploadErrors = function () {
        this.$uploadElement.classList.remove('m-serviceRequestModal__part__upload--error');
    };
    ServiceRequestModal.prototype.displayUploadedFiles = function (files) {
        var _this = this;
        files.forEach(function (file, index) {
            var element = _this.$fileTemplateElement.cloneNode(true);
            element.id = "serviceRequestFileNr".concat(index);
            _this.$fileListElement.appendChild(element);
            element.hidden = false;
            element.textContent = file.name;
        });
        this.$uploadAction.classList.remove('m-serviceRequestModal__part__upload__action--visible');
        this.$removeSection.classList.add('m-serviceRequestModal__part__remove--visible');
    };
    ServiceRequestModal.prototype.removeUploadedFiles = function () {
        this.$fileListElement.innerHTML = '';
        this.$uploadAction.classList.add('m-serviceRequestModal__part__upload__action--visible');
        this.$removeSection.classList.remove('m-serviceRequestModal__part__remove--visible');
        this.initFileInput();
    };
    ServiceRequestModal.prototype.isSizeValid = function (files) {
        var size = files.reduce(function (sum, file) {
            return sum + file.size;
        }, 0);
        return size / 1024 / 1024 <= 5;
    };
    ServiceRequestModal.prototype.isLengthValid = function (files) {
        return files.length <= 5;
    };
    ServiceRequestModal.prototype.isExtensionValid = function (files) {
        var _this = this;
        return files.reduce(function (isValid, file) {
            return _this.$unsafeExtensions.some(function (fileExtension) { return file.name.endsWith(fileExtension); }) ? false : isValid;
        }, true);
    };
    ServiceRequestModal.prototype.initFileInput = function () {
        var _this = this;
        if (document.body.classList.contains('internet-explorer')) {
            // IE file input value is read-only, cannot be changed directly
            var inputCopy = this.$uploadInput.cloneNode(true);
            this.$uploadAction.removeChild(this.$uploadInput);
            this.$uploadAction.appendChild(inputCopy);
            this.$uploadInput = this.$uploadAction.querySelector('input[type=file]');
            this.$uploadInput.addEventListener('change', function (event) { return _this.uploadFiles(event); });
        }
        else {
            this.$uploadInput.value = '';
        }
    };
    ServiceRequestModal.prototype.hideValidationResult = function (element) {
        element.classList.remove('a-input--error');
        element.classList.remove('a-input--success');
        element.classList.remove('a-textarea--error');
        element.classList.remove('a-textarea--success');
    };
    ServiceRequestModal.prototype.checkedMaterialOption = function () {
        var allocationPage = this.$modal.getAttribute('allocation');
        if (allocationPage === 'machineListPage') {
            var $materialOption = this.$modal.querySelector('.m-serviceRequestModal__part__radioPanel__material');
            var $equipmentOption = this.$modal.querySelector('.m-serviceRequestModal__part__radioPanel__equipment');
            $equipmentOption.checked = true;
            $materialOption.checked = false;
        }
    };
    ServiceRequestModal.prototype.clearModal = function () {
        var _this = this;
        this.$fields.forEach(function (element) { return _this.hideValidationResult(element); });
        this.$form.querySelectorAll('input[type="text"]:not([readonly])').forEach(function (element) {
            element.value = '';
        });
        this.$form.querySelectorAll('textarea').forEach(function (element) {
            element.value = '';
        });
        this.removeUploadedFiles();
        this.clearUploadErrors();
    };
    ServiceRequestModal.prototype.setEmailValue = function () {
        this.$inputEmail.value = this.$reachInputEmail.value;
    };
    ServiceRequestModal.prototype.init = function () {
        var _this = this;
        this.toggleReachOptions();
        this.toggleDeviceType();
        this.$modal.addEventListener('show', function () { return _this.clearModal(); });
        this.checkedMaterialOption();
        this.$submitBtn.addEventListener('click', function (event) { return _this.submitForm(event); });
        this.$reachOptionArr.forEach(function (option) {
            option.addEventListener('click', function () { return _this.toggleReachOptions(); });
        });
        this.$deviceTypeArr.forEach(function (option) {
            option.addEventListener('click', function () { return _this.toggleDeviceType(); });
        });
        this.$removeBtn.addEventListener('click', function () { return _this.removeUploadedFiles(); });
        this.$uploadBtn.addEventListener('click', function () { return _this.$uploadInput.click(); });
        this.$uploadInput.addEventListener('change', function (event) { return _this.uploadFiles(event); });
        this.$reachInputEmail.addEventListener('change', function () { return _this.setEmailValue(); });
    };
    return ServiceRequestModal;
}());
export { ServiceRequestModal };
