import * as app from "../../main";
var LanguageSelector = /** @class */ (function () {
    function LanguageSelector(countrySelector) {
        this.valueArray = [];
        this.countryArray = [];
        this.languageArray = [];
        this.countryDataList = [];
        this.inputOpen = "m-countrySelector__dropdowns__country--open";
        this.buttonDisabled = "a-darkBtn--disabled";
        this.hiddenListItemClass = "m-countrySelector__dropdowns__country__valueList__item--hidden";
        this.$countrySelector = countrySelector;
        this.$countryField = this.$countrySelector.querySelector('.m-countrySelector__dropdowns__country input');
        this.$countryList = this.$countrySelector.querySelector('.m-countrySelector__dropdowns__country__valueList');
        this.$submitBtn = this.$countrySelector.querySelector(".m-countrySelector__button");
        this.$iconSearch = this.$countrySelector.querySelector('.m-countrySelector__dropdowns__country__search');
        this.$countryFieldContainer = this.$countrySelector.querySelector('.m-countrySelector__dropdowns__country .a-input');
        this.$dropdownCountry = this.$countrySelector.querySelector('.m-countrySelector__dropdowns__country');
        this.$languageSelect = this.$countrySelector.querySelector('.m-countrySelector__dropdowns__language .a-customSelect');
        this.$languageSelectField = this.$languageSelect.querySelector('.a-customSelect__field');
        this.$languageSelectInputVisible = this.$languageSelect.querySelector('.a-input__field__input__text');
        this.$languageSelectInputHidden = this.$languageSelect.querySelector('.a-customSelect__field__input--hidden');
        this.$languageSelectOptionsContainer = this.$languageSelect.querySelector('.simplebar-content');
        this.init();
    }
    LanguageSelector.prototype.prepareCountrySelector = function (countryData) {
        var _this = this;
        var countryElements = '';
        countryData.forEach(function (country) {
            var name = country.name;
            if (country.englishName !== undefined) {
                name += " - ".concat(country.englishName);
            }
            countryElements += "<li id=\"".concat(country.countryCode, "\" class=\"m-countrySelector__dropdowns__country__valueList__item\">").concat(name, "</li>");
        });
        var noResultMessage = this.$countryList.getAttribute('data-empty-message');
        countryElements += "<li id=\"no-results-message\" class=\"m-countrySelector__dropdowns__country__valueList__item m-countrySelector__dropdowns__country__valueList__item--noResultsMessage\">".concat(noResultMessage, "</li>");
        var dropdownElement = this.$countryList.getElementsByClassName('simplebar-content').item(0);
        if (dropdownElement) {
            dropdownElement.innerHTML = countryElements;
        }
        else {
            this.$countryList.innerHTML = countryElements;
        }
        this.countryArray = Array.prototype.slice.call(this.$countrySelector.querySelectorAll('li'));
        this.countryArray.forEach(function (item) { return _this.valueArray.push(item.textContent); });
    };
    LanguageSelector.prototype.showNoResultsMessage = function () {
        this.$countryList.classList.add('empty');
    };
    LanguageSelector.prototype.hideNoResultsMessage = function () {
        this.$countryList.classList.remove('empty');
    };
    LanguageSelector.prototype.openCountryDropdown = function () {
        this.$countryList.classList.add('open');
        this.$countryField.classList.add(this.inputOpen);
    };
    LanguageSelector.prototype.closeCountryDropdown = function () {
        this.$countryList.classList.remove('open');
        this.$countryField.classList.remove(this.inputOpen);
    };
    LanguageSelector.prototype.filterCountries = function (inputValue) {
        var _this = this;
        var hasVisibleOption = false;
        var disableInput = true;
        this.valueArray.forEach(function (country, index) {
            if (country.toLowerCase().includes(inputValue)) {
                _this.countryArray[index].classList.remove(_this.hiddenListItemClass);
                hasVisibleOption = true;
            }
            else {
                _this.countryArray[index].classList.add(_this.hiddenListItemClass);
            }
            disableInput = country.toLowerCase() === inputValue ? false : disableInput;
        });
        hasVisibleOption ? this.hideNoResultsMessage() : this.showNoResultsMessage();
        disableInput || this.$languageSelectOptionsContainer.children.length <= 1 ? this.setDisabledInput() : this.removeDisabledInput();
    };
    LanguageSelector.prototype.setDisabledInput = function () {
        this.$languageSelectField.classList.add('a-customSelect__field--disabled');
    };
    LanguageSelector.prototype.removeDisabledInput = function () {
        this.$languageSelectField.classList.remove('a-customSelect__field--disabled');
    };
    LanguageSelector.prototype.disableGoButton = function () {
        this.$submitBtn.classList.add(this.buttonDisabled);
        this.$submitBtn.setAttribute('disabled', 'disabled');
    };
    LanguageSelector.prototype.enableGoButton = function () {
        this.$submitBtn.classList.remove(this.buttonDisabled);
        this.$submitBtn.removeAttribute('disabled');
    };
    LanguageSelector.prototype.setLanguage = function (langCode, langName, selectorForUrl, typeAction) {
        this.$languageSelectInputVisible.innerText = langName;
        this.$languageSelectInputHidden.value = langCode;
        this.$languageSelectInputHidden.setAttribute('data-name', langName);
        selectorForUrl.setAttribute(typeAction, this.findLink(this.$countryField.value, langName));
        this.selectedId = langCode;
    };
    LanguageSelector.prototype.prepareLanguageSelector = function (selectorData, selectorForUrl, typeAction) {
        var _this = this;
        var optionsHtml = '';
        selectorData.forEach(function (data, index) {
            _this.setDefaultLanguage(data, selectorForUrl, typeAction);
            var optionId = "lang_".concat(index);
            optionsHtml += "<div class=\"a-customSelect__dropdown__option\">\n                <input class=\"a-customSelect__dropdown__option__input\" id=\"".concat(optionId, "\" type=\"radio\" name=\"language\" value=\"").concat(data.isoCode || data.name, "\" data-text=\"").concat(data.name, "\" ").concat(data.defaultLanguage ? 'checked' : '', "/>\n                <label class=\"a-customSelect__dropdown__option__label\" for=\"").concat(optionId, "\">").concat(data.name, "</label>\n            </div>");
        });
        this.$languageSelectOptionsContainer.innerHTML = optionsHtml;
        this.languageArray = Array.prototype.slice.call(this.$languageSelectOptionsContainer.querySelectorAll('div'));
        this.languageArray.forEach(function (lang) {
            lang.addEventListener('click', function () {
                var input = lang.querySelector('input');
                _this.setLanguage(input.value, input.getAttribute('data-text'), selectorForUrl, typeAction);
            });
        });
        this.$languageSelectOptionsContainer.children.length <= 1
            ? this.setDisabledInput()
            : this.removeDisabledInput();
    };
    LanguageSelector.prototype.setDefaultLanguage = function (data, selectorForUrl, typeAction) {
        if (data.defaultLanguage) {
            this.setLanguage(data.isoCode || data.name, data.name, selectorForUrl, typeAction);
        }
    };
    LanguageSelector.prototype.cleanLanguageElements = function () {
        this.$languageSelectOptionsContainer.innerHTML = '';
    };
    LanguageSelector.prototype.inputEvent = function () {
        var inputValue = this.$countryField.value.toLowerCase();
        this.filterCountries(inputValue);
        if (inputValue.length < 1) {
            this.disableGoButton();
            this.cleanLanguageElements();
        }
    };
    LanguageSelector.prototype.findLink = function (country, language) {
        var countryData = this.countryDataList.find(function (countryData) { return country.includes(countryData.name); });
        var languageData = countryData.languages.find(function (languageData) { return language.includes(languageData.name); });
        return languageData.url.toLowerCase();
    };
    LanguageSelector.prototype.makeRandomString = function (length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };
    LanguageSelector.prototype.prepareCountryField = function () {
        var _this = this;
        this.$countryField.addEventListener('focus', function () {
            if (_this.$countryField.hasAttribute('readonly')) {
                _this.$countryField.removeAttribute('readonly');
                _this.$countryField.select();
            }
        });
        window.addEventListener('load', function () { return _this.$countryField.disabled = false; });
    };
    LanguageSelector.prototype.init = function () {
        var _this = this;
        this.prepareCountryField();
        this.setDisabledInput();
        this.$countryField.addEventListener('input', function () { return _this.inputEvent(); });
        this.$countryField.addEventListener('keypress', function (event) {
            if (event.code === 'Enter') {
                event.preventDefault();
            }
        });
        this.$dropdownCountry.addEventListener('click', function (event) {
            if (_this.$countryFieldContainer === event.target || _this.$iconSearch === event.target) {
                _this.$countryField.focus();
            }
        });
        this.$countryField.addEventListener('focus', function () {
            _this.openCountryDropdown();
            _this.filterCountries(_this.$countryField.value.toLowerCase());
        });
        document.addEventListener('click', function (event) {
            // checking if dropdown is opened at all before the next operations -> saves memory
            if (_this.$countryList.classList.contains('open')) {
                var isDropdown = _this.$dropdownCountry === app.default.getSnippets().closest(event.target, '.m-countrySelector__dropdowns__country');
                var isActiveRangeSelection = false;
                var selection = window.getSelection();
                if (selection) {
                    // It's a fix for an issue with selecting text and closing dropdown. Selection.type does not work on IE (it’s undefined there), but this issue does not occur there so in this case we should not worry about it.
                    isActiveRangeSelection = selection.type === 'Range' && selection.anchorNode === _this.$countryFieldContainer;
                }
                if (!isDropdown && !isActiveRangeSelection) {
                    _this.closeCountryDropdown();
                }
            }
        });
    };
    return LanguageSelector;
}());
export { LanguageSelector };
