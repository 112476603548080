import * as app from "../../main";
var SearchFilterButton = /** @class */ (function () {
    function SearchFilterButton(btn) {
        this.$filterButton = btn;
        this.$filterPanel = document.querySelector('.m-filterPanel');
        this.init();
    }
    SearchFilterButton.prototype.activateFilters = function (ev) {
        ev.stopPropagation();
        app.default.getMainMenu().closeMenu();
        app.default.getSearch().clearSearch();
        app.default.getFilter().openFilters();
    };
    SearchFilterButton.prototype.init = function () {
        var _this = this;
        this.$filterButton.classList.remove('a-darkBtn--disabled');
        this.$filterButton.addEventListener('click', function (event) { return _this.activateFilters(event); });
    };
    return SearchFilterButton;
}());
export { SearchFilterButton };
