import { Alert } from "./alert";
var alertElement = document.querySelector('.o-global-alerts');
var alertObject;
var GlobalAlerts = /** @class */ (function () {
    function GlobalAlerts(alertContainer) {
        Array.prototype.slice.call(alertContainer.querySelectorAll('.m-alert'))
            .forEach(function (element) {
            new Alert(element);
        });
        this.$alertContainer = alertContainer;
    }
    GlobalAlerts.prototype.showAlert = function (alert, container) {
        if (container === void 0) { container = this.$alertContainer; }
        container.appendChild(alert);
        new Alert(alert);
    };
    GlobalAlerts.prototype.isAlertVisible = function () {
        return this.$alertContainer.querySelectorAll('.m-alert').length;
    };
    return GlobalAlerts;
}());
if (alertElement) {
    alertObject = new GlobalAlerts(alertElement);
    Object.seal(alertObject);
}
export default alertObject;
