import * as app from "../../main";
var FilterValue = /** @class */ (function () {
    function FilterValue(filterValue) {
        this.$filterValue = filterValue;
        this.value = filterValue.getAttribute('data-filter-value');
        this.selected = this.isSelected();
        this.disabled = this.isDisabled();
        this.init();
    }
    FilterValue.prototype.getValue = function () {
        return this.value;
    };
    FilterValue.prototype.isSelected = function () {
        return this.$filterValue.classList.contains('m-filter__container__option--selected');
    };
    FilterValue.prototype.isDisabled = function () {
        return this.$filterValue.classList.contains('m-filter__container__option--disabled');
    };
    FilterValue.prototype.toggleSelection = function () {
        this.$filterValue.classList.toggle('m-filter__container__option--selected');
        var filter = app.default.getFilter();
        filter.setUrl();
    };
    FilterValue.prototype.disable = function () {
        this.$filterValue.classList.remove('m-filter__container__option--selected');
        this.$filterValue.classList.add('m-filter__container__option--disabled');
    };
    FilterValue.prototype.enable = function () {
        if (this.isDisabled()) {
            this.$filterValue.classList.remove('m-filter__container__option--selected');
            this.$filterValue.classList.remove('m-filter__container__option--disabled');
        }
    };
    FilterValue.prototype.init = function () {
        var _this = this;
        this.$filterValue.addEventListener('click', function (_event) {
            _event.preventDefault();
            _this.toggleSelection();
        });
    };
    return FilterValue;
}());
export { FilterValue };
