import * as app from '../../main';
import alertObject from '../notification/global-alerts';
import { AlertFactory } from '../notification/alert-factory';
import { AddMultipleFavoritesRequest, AddToFavoritesProduct } from './data/add-multiple-favorites-request';
import axios from 'axios';
var serialize = require('form-serialize');
var AddToFavoritesModal = /** @class */ (function () {
    function AddToFavoritesModal() {
        this.$modalContent = document.querySelector('.m-favoritesModal');
        this.$form = this.$modalContent.querySelector('form');
        this.$favoriteListSelect = this.$modalContent.querySelector('.m-favoritesModal__content__favSelect .a-customSelect');
        this.$favoriteListSelectField = this.$favoriteListSelect.querySelector('.a-customSelect__field');
        this.$favoriteListSelectInput = this.$favoriteListSelect.querySelector('.a-customSelect__field__input--hidden');
        this.$favoriteListSelectInputVisible = this.$favoriteListSelect.querySelector('.a-input__field__input__text');
        this.$favoriteListSelectOptionsContainer = this.$favoriteListSelect.querySelector('.simplebar-content');
        this.$favoriteModalContent = this.$modalContent.querySelector('.m-favoritesModal__content');
        this.$favoriteNewListInput = this.$modalContent.querySelector('input[name=listName]');
        this.$favoriteNewListInputContainer = this.$modalContent.querySelector('.m-favoritesModal__content__favName');
        this.$productCodeInput = this.$modalContent.querySelector('input[name=productCode]');
        this.$modal = document.querySelector('#addToFavorites');
        this.$submitBtn = this.$modalContent.querySelector('button[type=submit]');
        this.$closeBtn = this.$modalContent.querySelector('#favoritesModalCloseBtn');
        this.mode = '';
        this.alertFactoryObject = new AlertFactory();
        this.init();
    }
    AddToFavoritesModal.prototype.checkSelectState = function () {
        if (this.$favoriteListSelectOptionsContainer.children.length === 0) {
            this.$favoriteListSelectField.classList.add('a-customSelect__field--disabled');
            this.$favoriteListSelectInput.disabled = true;
            this.setMode('create');
        }
        else {
            this.$favoriteListSelectField.classList.remove('a-customSelect__field--disabled');
            this.$favoriteListSelectInput.disabled = false;
            var option = this.$favoriteListSelectOptionsContainer.children[0].querySelector('input');
            this.$favoriteListSelectInput.value = option.value;
            this.$favoriteListSelectInputVisible.innerText = option.getAttribute('data-text') || option.value;
            this.setMode('add');
        }
    };
    AddToFavoritesModal.prototype.setMode = function (mode) {
        switch (mode) {
            case 'create':
                this.mode = 'create';
                this.$favoriteModalContent.classList.add('m-favoritesModal__content--create');
                break;
            case 'add':
                this.mode = 'add';
                this.$favoriteModalContent.classList.remove('m-favoritesModal__content--create');
                break;
        }
    };
    AddToFavoritesModal.prototype.toggleSubmitBtn = function (disable) {
        if (disable === void 0) { disable = false; }
        disable ? this.$submitBtn.classList.add('a-darkBtn--disabled') : this.$submitBtn.classList.remove('a-darkBtn--disabled');
    };
    AddToFavoritesModal.prototype.submitForm = function () {
        var _this = this;
        if (this.mode === 'create' && !this.$form.checkValidity()) {
            this.$form.reportValidity();
            return;
        }
        this.toggleSubmitBtn(true);
        var url, data, matrixData;
        var contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
        if (document.querySelector('.o-variant-matrix')) {
            var matrices = Array.prototype.slice.call(document.querySelectorAll('.m-variantMatrixGrid__cell'));
            matrixData = this.createMultipleAdditionRequest(matrices);
            matrixData.products = matrixData.products.filter(function (item) { return +item.quantity > 0; });
        }
        if (matrixData && matrixData.products.length) {
            data = JSON.stringify(matrixData);
            url = '/favorites/save-multiple';
            contentType = 'application/json';
        }
        else {
            if (matrixData) {
                var productId = document.querySelector('.productForm .m-favouriteHeart--modal').getAttribute('data-variant');
                this.$form.querySelector('[name=productCode]').value = productId;
            }
            data = serialize(this.$form);
            url = this.mode === 'create' ? '/favorites/add' : '/favorites/save';
        }
        axios.post(url, data, { 'headers': { 'content-type': contentType } })
            .then(function (res) {
            var response = res.data;
            var message = response.message;
            var element = response.status == true ? _this.alertFactoryObject.createAlert(message, window.ACC.status.success, 'success', { 'animated': true, 'closable': true, 'disposable': true })
                : _this.alertFactoryObject.createAlert(message, window.ACC.status.error, 'error', { 'animated': true, 'closable': true });
            alertObject.showAlert(element);
            _this.checkSelectState();
            _this.$closeBtn.click();
        })
            .catch(function (error) {
            if (error.response && error.response.data) {
                var err = error.response.data;
                var element = _this.alertFactoryObject.createAlert(err.message, window.ACC.status.error, 'error', { 'animated': true, 'closable': true });
                alertObject.showAlert(element);
            }
            else {
                console.log(error);
            }
            _this.$closeBtn.click();
        });
    };
    AddToFavoritesModal.prototype.createMultipleAdditionRequest = function (matrices) {
        var request = new AddMultipleFavoritesRequest();
        if (this.mode === 'create') {
            request.listName = this.$favoriteNewListInput.value;
        }
        else {
            request.listId = this.$favoriteListSelectInput.value;
        }
        var products = new Array();
        matrices.forEach(function (cell) {
            var $quantity = cell.querySelector('input[name=quantity]');
            var $productCode = cell.querySelector('input[name=product]');
            if ($quantity != null && $quantity.value != '') {
                var product = new AddToFavoritesProduct();
                product.code = $productCode.value;
                product.quantity = parseInt($quantity.value);
                products.push(product);
            }
        });
        request.products = products;
        return request;
    };
    AddToFavoritesModal.prototype.fetchFavoritesLists = function () {
        var _this = this;
        this.$favoriteNewListInput.value = '';
        var requestUrl = '/favorites';
        axios.get(requestUrl)
            .then(function (res) {
            var response = res.data;
            var productCode = _this.$productCodeInput.value;
            var optionsHtml = response.lists.reduce(function (html, list, index) {
                var optionId = "list_".concat(productCode, "_").concat(index);
                return html + "<div class=\"a-customSelect__dropdown__option\">\n                        <input class=\"a-customSelect__dropdown__option__input\" value=\"".concat(list.listID, "\" data-text=\"").concat(list.name, "\" id=\"").concat(optionId, "\" type=\"radio\" \n                            name=\"").concat(productCode, "_listOption\" ").concat(!index ? 'checked="checked"' : '', "/>\n                        <label class=\"a-customSelect__dropdown__option__label\" for=\"").concat(optionId, "\">").concat(list.name, "</label>\n                    </div>");
            }, '');
            _this.$favoriteListSelectOptionsContainer.innerHTML = optionsHtml;
            _this.checkSelectState();
            _this.toggleSubmitBtn();
        })
            .catch(function (error) {
            console.log(error);
            _this.$favoriteListSelectOptionsContainer.innerHTML = '';
            _this.checkSelectState();
            _this.toggleSubmitBtn();
        });
    };
    AddToFavoritesModal.prototype.checkModalTrigger = function () {
        app.default.sessionStorageData().useSessionValues('triggerFavourite', function (sessionData) {
            var productCode = sessionData.data.code;
            var $heartIcon = document.querySelector(".m-favouriteHeart[data-variant=\"".concat(productCode, "\"]")) || document.querySelector(".m-favouriteHeart[data-code=\"".concat(productCode, "\"]"));
            $heartIcon.click();
        });
    };
    AddToFavoritesModal.prototype.init = function () {
        var _this = this;
        this.$favoriteNewListInput.addEventListener('keydown', function () { return _this.setMode('create'); });
        this.$favoriteNewListInput.addEventListener('click', function () { return _this.setMode('create'); });
        this.$favoriteListSelect.addEventListener('click', function () { return _this.setMode('add'); });
        this.$submitBtn.addEventListener('click', function (event) {
            event.preventDefault();
            _this.submitForm();
        });
        this.$modal.addEventListener('show', function () { return _this.fetchFavoritesLists(); });
        this.checkModalTrigger();
    };
    return AddToFavoritesModal;
}());
export { AddToFavoritesModal };
