import { ScrollPageHandler } from "../scroll-page";
var DownloadsHandler = /** @class */ (function () {
    function DownloadsHandler() {
        this.$downloadBtn = document.querySelector('.m-pdpAdditionalData__moreInfo__downloads');
        this.init();
    }
    DownloadsHandler.prototype.init = function () {
        this.$downloadBtn.addEventListener('click', function () {
            var scrollPageObject = new ScrollPageHandler();
            scrollPageObject.scrollPage('.m-pdpDownloadSection');
        });
    };
    return DownloadsHandler;
}());
export { DownloadsHandler };
