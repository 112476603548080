var RemoveFromCart = /** @class */ (function () {
    function RemoveFromCart(element) {
        this.$removeBtn = element;
        this.entryNumber = element.getAttribute('data-entry');
        this.$form = document.querySelector('#entry_' + this.entryNumber);
        this.$quantity = this.$form.querySelector('input[name="quantity"]');
        this.directSale = Boolean(this.$form.querySelector('input[name="directSale"]').value);
        this.isPunchOut = Boolean(this.$form.querySelector('input[name="isPunchOut"]').value);
        this.productCode = this.$form.querySelector('input[name="productCode"]').value;
        this.productName = (this.$form.querySelector('input[name="productName"]').value);
        this.productPrice = (this.$form.querySelector('input[name="productPrice"]').value);
        this.initialQuantity = (this.$form.querySelector('input[name="initialQuantity"]').value);
        this.init();
    }
    RemoveFromCart.prototype.removeProduct = function (event) {
        event.preventDefault();
        this.updateDataLayer();
        this.removeItem();
    };
    RemoveFromCart.prototype.updateDataLayer = function () {
        var dataLayer = window.dataLayer;
        if (this.directSale && typeof dataLayer.push === "function" && this.isPunchOut) {
            dataLayer.push({
                'event': 'removeFromCart',
                'eventAction': this.productCode + ' - ' + this.productName,
                'ecommerce': {
                    'remove': {
                        'products': [{
                                'name': this.productName,
                                'id': this.productCode,
                                'price': this.productPrice,
                                'quantity': this.initialQuantity
                            }]
                    }
                }
            });
        }
    };
    RemoveFromCart.prototype.removeItem = function () {
        this.$quantity.value = '0';
        this.$form.submit();
    };
    RemoveFromCart.prototype.init = function () {
        var _this = this;
        this.$removeBtn.addEventListener('click', function (event) { return _this.removeProduct(event); });
    };
    return RemoveFromCart;
}());
export { RemoveFromCart };
