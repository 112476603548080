import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper';
var ProductsCarouselHandler = /** @class */ (function () {
    function ProductsCarouselHandler(carousel) {
        this.$bulletClass = 'm-productsCarouselSection__carousel__panel__indicators__list';
        this.$bulletClassActive = this.$bulletClass + '--active';
        this.$arrowDisabled = 'm-carouselArrows--disabled';
        this.$carousel = carousel;
        this.$prevArrow = this.$carousel.querySelector('.m-carouselArrows__left');
        this.$nextArrow = this.$carousel.querySelector('.m-carouselArrows__right');
        this.$swiperContent = this.$carousel.querySelector('.swiper-container');
        this.$indicatorPanel = this.$carousel.querySelector('.m-productsCarouselSection__carousel__panel__indicators');
        this.createCarousel();
    }
    ProductsCarouselHandler.prototype.createCarousel = function () {
        SwiperCore.use([Navigation, Pagination]);
        var isBestsellerCarousel = this.$carousel.parentElement.classList.contains('m-sectionGrid--bestsellers');
        var isRecentProductsCarousel = this.$carousel.parentElement.classList.contains('m-sectionGrid--recent');
        if (isRecentProductsCarousel) {
            this.setRecentlyViewedProductsCarousel();
        }
        new Swiper(this.$swiperContent, {
            loop: false,
            slidesPerView: 'auto',
            pagination: {
                el: this.$indicatorPanel,
                bulletClass: this.$bulletClass,
                bulletActiveClass: this.$bulletClassActive,
                clickable: true
            },
            navigation: {
                nextEl: this.$nextArrow,
                prevEl: this.$prevArrow,
                disabledClass: this.$arrowDisabled
            },
            breakpoints: {
                320: {
                    slidesPerGroup: 1,
                    slidesPerView: 'auto',
                    spaceBetween: 15,
                    allowTouchMove: true
                },
                768: {
                    slidesPerGroup: 2,
                    slidesPerView: 'auto',
                    spaceBetween: 15,
                    allowTouchMove: true
                },
                1152: {
                    slidesPerGroup: isRecentProductsCarousel ? 2 : 3,
                    slidesPerView: 'auto',
                    spaceBetween: isBestsellerCarousel ? 25 : 35,
                    allowTouchMove: false,
                    preventClicks: false,
                    preventClicksPropagation: false
                },
                1600: {
                    slidesPerGroup: isRecentProductsCarousel ? 2 : 3,
                    slidesPerView: 'auto',
                    spaceBetween: isBestsellerCarousel ? 33 : 35,
                    allowTouchMove: false,
                    preventClicks: false,
                    preventClicksPropagation: false
                }
            }
        });
    };
    ProductsCarouselHandler.prototype.setRecentlyViewedProductsCarousel = function () {
        var cartContentSlot = document.querySelector('.o-cartPage__contentSection').querySelector('.yCmsContentSlot');
        var marginRequired = (window.innerWidth - (cartContentSlot.getBoundingClientRect().left + cartContentSlot.clientWidth)) - 20;
        var recentCarousel = document.querySelector('.m-sectionGrid--recent');
        recentCarousel.setAttribute('style', "margin-right:-".concat(marginRequired, "px"));
    };
    return ProductsCarouselHandler;
}());
export { ProductsCarouselHandler };
