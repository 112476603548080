var ScrollPageHandler = /** @class */ (function () {
    function ScrollPageHandler() {
    }
    ScrollPageHandler.prototype.scrollPage = function (elementName) {
        var destinationElement = document.querySelector(elementName);
        var headerElement = document.querySelector('.o-header__menu');
        var headerElementHeight = headerElement.offsetHeight;
        var destinationElementRect = destinationElement.getBoundingClientRect();
        var destinationPosition = destinationElementRect.top + window.pageYOffset - headerElementHeight;
        window.scroll(0, destinationPosition);
    };
    return ScrollPageHandler;
}());
export { ScrollPageHandler };
